<div *ngIf="hasFilter" class="form-row data-tool-head filter-bar">
    <div class="col" style="flex-grow: 0;">
        <div class="icon">
            <i class="las la-filter" placement="bottom" triggers="hover" role="button" ngbTooltip="Advanced Filter"></i>
        </div>
    </div>
    <div *ngFor="let entity of filterOrder" class="col" style="flex-grow: 0; min-width: min(25%, 354px);">
        <!-- (filterEmitter)="setFilterValues($event)" -->
        <app-product-filter style="width: 100%;" [property]="entity" [defaultValue]="defaultValues[entity]" [filterValue]="filterValues[entity]" (filterValueChange)="setFilterValue(entity, $event)" [filterFilter]="filterFilters"></app-product-filter>
        <button class="icon red" (click)="removeFilter(entity)" placement="left" triggers="hover focus click:blur" role="button" ngbTooltip="Entfernen" [openDelay]="500"><i class="la la-minus-circle"></i></button>
    </div>
<div>
