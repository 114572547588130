import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BiClient } from 'src/interfaces/bi-widget';
import { Article } from 'src/interfaces/calculator/Article';
import { Calculator } from 'src/interfaces/calculator/Calculator';
import { Product } from 'src/interfaces/calculator/Product';
import { DataFilter, IdentityFilter } from 'src/interfaces/calculator/QueryLib';
import { ArticleDataService } from './articledata.service';
import { PriceCheckerService } from './priceChecker.service';

/**
 * This class is a facade. Exposes calculator. Handles loading, filtering, searching and sort of articles. May unload or reload data from server.
 * Filter refers to load filtered articles. Search refers to "filter" locally.
 * May transmit calculations to the server if necessary and feasable.
 * IMPORTANT: by using Injectable, this is a singleton. So only one article set is loaded and only one set of calculator flags is active.
 * IMPORTANT: Article objects are always passed around as references. Make sure to change them only through calculator.
 */
@Injectable()
export class CalculatorService extends Calculator {

    protected loadArticles$: Subject<{ client: BiClient, filter: DataFilter<Product>, showAll?: boolean }> = new Subject();
    public loadedArticles$: BehaviorSubject<Article[]> = new BehaviorSubject([]);


    protected currentResponseTimestamp: Date;

    //NOTE: price checker is injected here to setup freshly loaded articles. Claculator component controls execution of checks, though.
    constructor(private articleData: ArticleDataService, private priceChecker: PriceCheckerService) {
        super();
        // tslint:disable-next-line: no-string-literal
        this.logs = window['calcLog']; //@TODO: quick impl for debugging. Implement properly or remove.
        this.loadedArticles$ = new BehaviorSubject([]);
        this.loadedArticles$.subscribe(articles => {

            if (this.articles.size === 0) {
                this.saveOriginalArticles(articles);
                console.time('setting up price checks');
                this.priceChecker.setupCheckersForArticles(articles);
                //NOTE: the first check is done here too. This allows filtering of freshly loaded articles and will not repeat it if loaded articles is triggered again by local loading
                //@TODO: this whole subject thing should be refactored.
                this.priceChecker.checkAll();
                console.timeEnd('setting up price checks');
            }
            this.setArticles(articles);
            this.createIndexMap('articleNumber');
            this.createIndexMap('debitorNumber');
        });
        this.loadArticles$.pipe(switchMap(({ client, filter, showAll }) => {
            return this.articleData.getArticles(client, filter, showAll, this.currentResponseTimestamp);
        })).subscribe(this.loadedArticles$);
    }

    //NOTE: filter by "product", but gets "article". It is distinguished to save data transmission. See transformer in calculator service.
    public loadRemoteArticles(client: BiClient, filter: DataFilter<Product> = {}, showAll = false) {
        this.articles = new Map();
        this.articlesByKey = new Map();
        this.loadArticles$.next({ client, filter, showAll });
    }

    public loadLocalArticles(filter: DataFilter<Product> = {}) {
        // @TODO: implement client side filtering as necessary
        let articles = this.loadedArticles$.value; // get current value from BehaviourSubject
        let numberRegex: RegExp;
        const numberLike = (filter.number as IdentityFilter<string>)?.like;
        if (numberLike) {
            numberRegex = new RegExp(numberLike.replace(/%/ig, '.*'), 'ig'); //@TODO: escape literal dots
        }
        articles = articles.filter(a => {
            let positive: boolean = true;
            if (numberRegex) {
                positive = positive && !!a.articleNumber.match(numberRegex);
            }
            return positive;
        });
        this.loadedArticles$.next(articles);
    }

}
