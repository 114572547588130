// login.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, } from '@angular/router';
import { ReportsComponent } from 'src/app/features/reports/reports.component';
import { Role } from 'src/interfaces/user';
import { LoginService } from '../service/login.service';

@Injectable()
export class ReportsGuard implements CanActivate, CanActivateChild, CanDeactivate<ReportsComponent> {
    constructor(
        private authService: LoginService
    ) { }

    public async canActivate(): Promise<boolean> {
        //@TODO: need to do something on false? There is no "offical" UI way to reach this of role is not present?
        return this.authService.getUserRoles().indexOf(Role.REPORT_USER) > -1;
    }

    public canActivateChild(): Promise<boolean> {
        return this.canActivate();
    }

    public canDeactivate(dist?: ReportsComponent) {
        // @TODO: adapt logic and message
        // if (dist?.planSource || dist?.activePlans.length > 0) {
        //     return window.confirm('Achtung: Beim Verlassen des Umlagerungstools gehen alle aktuellen Eingaben verloren. Fertiggestellte, aktuelle Pläne bleiben als bearbeitete Pläne erhalten, wirken sich dann aber nicht mehr auf die Zahlen in den Tabellen aus. Wirklich verlassen?');
        // }
        return true;
    }

}
