import { Component } from '@angular/core';

@Component({
    selector: 'loader',
    template: '<div class="lds-ripple"><div></div><div></div></div>',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    //
}
