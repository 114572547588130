import { ABCValue, WarehouseNumeric } from '../forecast/Article';
import { FormatNo, ReportConsumtionType } from '../im-export';
import { PriceState } from '../price-matrix/Price-matrix';
import { NumbersForEnum, TextsForEnum } from '../types';

export enum ItemStatus {
    NORMAL = 'NORMAL',
    PRODUKTION = 'PRODUKTION',
    EIGENE = 'EIGENE',
    AUSLAUF = 'AUSLAUF'
}

export enum ReplenishmentSystem {
    EINKAUF = 'EINKAUF',
    FERTIGUNG  = 'FERTIGUNG',
    MONTAGE = 'MONTAGE'
}

export type LocationForFormat = 'HIG' | 'NOHRA' | 'LFD';

export enum LocationForFormatToCastlyMapping {
    'NOHRA' = 'Lager 1',
    'LFD' = 'Lager 2',
    'HIG' = 'Lager 3'
}

export enum DebitorType {
    B2C = 1,
    B2B = 2,
    MARKETPLACE = 3,
    LBB2C = 4,
    LBB2B = 5,
    OUT = 6,
    INTERN = 11
}

export const LabelForDebitorType: TextsForEnum<DebitorType> = {
    '1': 'B2C',
    '2': 'B2B',
    '3': 'Marketplace',
    '4': 'LBB2C',
    '5': 'LBB2B',
    '6': 'Out',
    '11': 'Intern'
};

export const CalculationFactorForDebitorType: NumbersForEnum<DebitorType> = {
    '1': 6,
    '2': 2.5,
    '3': 6,
    '4': 2.5,
    '5': 2.5,
    '6': 2.5,
    '11': 2.5,
};

export interface SimpleProduct {
    number: string;
    ean?: string;
    key: string;
    legacyKey: string;
    description: string;
    description2: string;
    countryOrigin: string;
    salesUnitOfMeasure: string;
    purchaseUnitOfMeasure: string;
    manufacturerCode: string;
    category: string;
    productGroup: string;
    productGroupName: string;
    specialEquipment: string;
    baseproduct: string;
    colour: string;
    colourPim: string;
    type: string;
    mounting: string;
    material: string;
    brand: string;
    productionCountry: string;
    itemStatus: ItemStatus;
    replenishmentSystem: ReplenishmentSystem | null;
    parcelTypeAlt: string;
    parcelTypePim: string;
    creditorNo: string;
    creditorName: string;
    creditorPaymentTerms: string;
    creditorVatGroup: string;
    creditorCountry: string;
    creditorLocation: string;
    length: number;
    width: number;
    height: number;
    lengthPim: string;
    widthPim: string;
    unitsPerParcel: number;
    lotSize: number;
    leadTime: number;
    inactive: boolean;
    location?: WarehouseNumeric;
    abcTotal?: ABCValue;
    abcGroup?: ABCValue;
}

export interface SimpleProductWithPurchaseAndUvpPrices extends SimpleProduct {
    purchasePrice: number | null;
    salesPriceUvp: number | null;
}

export interface SimpleProductWithStockData extends SimpleProduct {
    invNohra: number;
    invThalwenden: number;
    invLFD: number;
    invHannover: number;
    invHIG: number;
    invZW: number;
    pending: number;
}

export interface SimpleProductWithStockHistory extends SimpleProductWithStockData {
    stockDateHistory: Date[];
    invNohraHistory: number[];
    invNohraExpDate?: Date;
    invLFDHistory: number[];
    invLFDExpDate?: Date;
    invLFDSmooth?: number[];
    invThalwendenHistory: number[];
    invThalwendenExpDate?: Date;
    invHIGHistory: number[];
    invHIGExpDate?: Date;
    invHannoverHistory: number[];
    invHannoverExpDate?: Date;
    invHannoverSmooth?: number[];
    invZWHistory: number[];
    invZWExpDate?: Date;
}

export interface ProductWithDebitor extends SimpleProduct {
    debitorNo: string;
    debitorName: string;
    debitorType: DebitorType;
    debitorPriceGroup: string;
    debitorPaymentTerms: string;
    debitorVatGroup: string;
    debitorCountry: string;
    debitorLocation: string;
    debitorShippingAgent: string;
    debitorProductNumber?: string;
    debitorOrdersLastMonth?: number;
    debitorReturnsLastMonth?: number;
    formatNo?: FormatNo; // included here for filter components type compatibilty. only set in ProductWithDebitorAndReport
    debitors?: string[]; // included here for filter components type compatibilty. only set in ProductWithDebitorAndReport
    consumption?: 0 | 1 | 2; // included here for filter components type compatibilty. only set in ProductWithDebitorAndReport set in DataService
}

export interface ProductWithDebitorAndReport extends ProductWithDebitor {
    formatNo: FormatNo;
    quota: number;
    lockedAmount: number;
    debitors: string[];
    locationForFormat: LocationForFormat;
    inventoryTotal: number;
    inventoryAtLocation: number;
    lastReportDate: Date;
    lastReportAmount: number;
    lastReportComment: string;
    currentReportAmount: number;
    calculatedAmount?: number;
    currentReportComment: string;
    blocked?: boolean;
    restriction?: 0 | 1 | 2 | 3; // FE only
}

export interface ReportProductAmountRestriction {
    number: string;
    formatNo: FormatNo;
    inventoryTotal: number;
    currentReportAmount: number;
    totalReportAmount: number;
    restriction: 0 | 1 | 2;
}

export type LessConsumptionAmount = Pick<ReportConsumtionType, 'formatNo' | 'productNo' | 'amount'> & {currentAmount: number};

export interface ReportProductAmountRestrictions {
    restriction85: ReportProductAmountRestriction[];
    restriction100: ReportProductAmountRestriction[];
    lessConsumptionAmount: LessConsumptionAmount[];
}

export type ProductLimmited = Pick<Product, 'debitorNo' | 'number' | 'key' | 'salesPriceDebitor' | 'salesPriceDebitorGroup' |  'priceState' | 'stateComment' | 'lastCommentDate' | 'debitorType'> & {
    isAvailableInCrawler: boolean | null;
    isAvailiableInReport: boolean | null;
    rating: number | null;
};


export interface Product extends ProductWithDebitor {
    blocked: boolean;
    itemType: boolean;
    creditorPartnerType: boolean;
    debitorPartnerType: boolean;
    debitorHasShippingCost: boolean;
    lastModified: Date;
    lastCostCalculation: Date;
    purchasePriceStarting: Date;
    purchasePriceEnding: Date;
    salesPriceDebitorStarting: Date;
    salesPriceDebitorEnding: Date;
    salesPriceGroupStarting: Date;
    salesPriceGroupEnding: Date;
    salesPriceUvpStarting: Date;
    salesPriceUvpEnding: Date;
    creditorLastModified: Date;
    debitorLastModified: Date;
    parcelType: number;
    weightGross: number;
    weightNet: number;
    materialCost: number;
    capacityCost: number;
    roundingPrecision: number;
    lotSize: number;
    purchasePriceMinQuantity: number;
    purchasePrice: number;
    creditorTermsDueDate: number;
    creditorTermsDiscountDate: number;
    creditorTermsDiscount: number;
    salesPriceDebitor?: number;
    salesPriceDebitorGroup?: number;
    salesPriceUvp?: number;
    debitorTermsDueDate: number;
    debitorTermsDiscountDate: number;
    debitorTermsDiscount: number;
    debitorDiscount: number;
    debitorBonus1: number;
    debitorBonus2: number;
    debitorBonus3: number;
    debitorBonus4: number;
    debitorBonus5: number;
    debitorReturnFlat: number;
    debitorReturnDiscount: number;
    debitorReturnRate: number;
    debitorInternalCost: number;
    debitorPackageCost: number;
    debitorLongPackageCost: number;
    debitorShippingItemCost: number;
    debitorShippingLongItemCost: number;
    debitorOrdersLast3Months: number;
    debitorOrdersLast6Months: number;
    debitorOrdersLast12Months: number;
    debitorReturnsLast3Months: number;
    debitorReturnsLast6Months: number;
    debitorReturnsLast12Months: number;
    priceState?: PriceState;
    stateComment?: string;
    lastCommentDate?: Date;
}

// export interface ProductForMatrix extends Product {
//     isAvailableInCrawler: boolean | null;
//     isAvailiableInReport: boolean;
// }

//@TODO: refactor with to product interface
export interface PreaggregatedOrders {
    number: string;
    debitorNumber: string;
    debitorOrdersCurrentMonth: number;
    debitorOrdersLast3Months: number;
    debitorOrdersLast6Months: number;
    debitorOrdersLast12Months: number;
    debitorReturnsCurrentMonth: number;
    debitorReturnsLast3Months: number;
    debitorReturnsLast6Months: number;
    debitorReturnsLast12Months: number;
}

//@TODO: reconsider this interface. seems a bit clumsy.
export interface OrdersPerArticle {
    articleNumber: string;
    orders: number;
}

export interface ProductReportForDebitor {
    productNo: string;
    amount: number;
    blocked?: boolean;
    comment?: string | null;
    delisted?: boolean;
}

export interface ProductReport extends ProductReportForDebitor {
    formatNo: FormatNo;
    date?: Date | null;
}

export interface ProductReportExtended {
    debitorNo?: string;
    formatNo: FormatNo;
    productNo: string;
    amount: number;
}

export interface FormatToDebitor {
    formatNo: FormatNo;
    debitorNo: string;
    webLink: string;
}

export type WarehousesReportStocks = {
    invLFD: number | null;
    importDateLFD: Date | null;
    invHannover: number | null;
    importDateHannover: Date | null;
};

export interface WarehouseStockDataInconsistencies {
    warehousesReportStocks: WarehousesReportStocks;
    simpleProductWithStockData: Pick<SimpleProductWithStockData, 'number' | 'location' | 'itemStatus' | 'invLFD' | 'invHannover'>;
}

export interface ProductAbcData {
    type: 'all' | 'group';
    productNo: string;
    value: ABCValue;
    rank: number;
    maxRank: number;
    percentage: number;
    consumPerc: number;
}

export type Stock = {
    Product_No: string,
    Inv_Nohra: number,
    Inv_Thalwenden: number,
    Inv_LDF: number,
    Inv_Hannover: number,
    Inv_HIG: number,
    Inv_HH: number,
    Inv_Meerane: number,
    Inv_ZW: number,
    Pending: number,
    Import_Date: Date,
    Import_Time: Date,
};

export type OOSPeriod = {startDate: Date, endDate: Date};

export type OOSResponse = {
    oosValue: number,
    oosPeriods: OOSPeriod[],
};
