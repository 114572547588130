import { Injectable } from '@angular/core';
import { BiEntity } from 'src/interfaces/bi-widget';
import { ApiService } from './api.service';
import { DataFilter } from 'src/interfaces/calculator/QueryLib';
import { Product } from 'src/interfaces/calculator/Product';
import { state } from 'src/state/state';

export interface EntityValues {
    id: string;
    name: string;
    visible?: boolean;
}

@Injectable()
export class EntityService {

    constructor(private apiService: ApiService) { }

    public async getEntity(entity: keyof typeof BiEntity, includeOnlyVisible = true, withCrawlerDataOnly = false): Promise<EntityValues[]> {
        return this.requestEntity(entity, {}, includeOnlyVisible, withCrawlerDataOnly);
    }

    //@TODO: always use observables
    public async requestEntity(entity: keyof typeof BiEntity, filter: DataFilter<Product> = {}, includeOnlyVisible = true, withCrawlerDataOnly = false): Promise<EntityValues[]> {
        return new Promise((resolve, reject) => {
            this.apiService.post<DataFilter<Product>, EntityValues[]>(filter, 'entity', state.cube, entity, withCrawlerDataOnly ? 'with-crawler-data' : includeOnlyVisible ? 'visible' : undefined).subscribe( res => {
                console.log('getEntity', entity, res);
                if (res && res.data) {
                    resolve(res.data.map(r => {
                        if (!r.name) {
                            r.name = r.id && r.id != '\u0000' ? r.id : '[Keine Angabe]';
                        }
                        return r;
                    }));
                }
                resolve([]);
            }, (err) => { reject(err); });
        });
    }

}
