<div id="bi-header">
    <div [routerLink]="['/bi', client]" class="logo"><img src="assets/castly-logo-small.png"/>
    </div>
    <div *ngIf="canSelectClient()" id="bi-client">
        <select class="form-control" (change)="setClient($event)">
            <option [selected]="client == 'WohnGuide'" value="WohnGuide">SMA-Dev</option>
        </select>
    </div>
    <div class="d-flex">
        <a *ngIf="canGoBack()" [routerLink]="['/bi', client]"><i class="square las la-caret-square-left"></i></a>
        <a *ngIf="!canGoBack()" [routerLink]="['/details']"><span class="cond">BI-Widget hinzufügen</span><i class="square las la-plus-square"></i></a>
        <a *ngIf="!canGoBack()" [routerLink]="['/notificationDetails']"><span class="cond">Notification-Widget hinzufügen</span><i class="square las la-plus-square"></i></a>
        <a *ngIf="loginService.isCalculatorUser()" [routerLink]="['/calculator'+this.getClient()]" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Preiskalkulator"><i class="square las la-calculator"></i></a>
        <a *ngIf="loginService.isForecastUser()" [routerLink]="['/forecast' +this.getClient()]" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Forecast"><i class="square las la-binoculars"></i></a>
        <a *ngIf="loginService.isStocksUser()" [routerLink]="['/stocks' +this.getClient()]" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Lagerbestände"><i class="square las la-warehouse"></i></a>
        <a *ngIf="loginService.isDistributionUser()" [routerLink]="['/distribution' +this.getClient()]" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Umlagerung"><i class="square las la-truck"></i></a>
        <a *ngIf="loginService.isReportsUser()" [routerLink]="['/reports' +this.getClient()]" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Bestandsmeldung"><i class="square las la-file-alt"></i></a>
        <a *ngIf="loginService.isCrawlerUser()" [routerLink]="['/crawler' +this.getClient()]" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Bestandsabgleich"> <i class="square las la-search-dollar"></i></a> <!-- ???-->
        <a *ngIf="loginService.isMatrixUser()" [routerLink]="['/price-martix' +this.getClient()]" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Preismatrix"> <i class="square las la-money-check-alt"></i></a> <!-- ???-->
        <a *ngIf="loginService.isAdminUser()" [routerLink]="['/export' +this.getClient()]" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Exporte"><i class="square las la-file-download"></i></a>
        <a [routerLink]="['/user']" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Nutzerverwaltung"><i class="square las la-user-circle"></i></a>
        <a [routerLink]="['/manual']" routerLinkActive="active" placement="bottom" triggers="hover focus click:blur" [openDelay]="500" role="button" ngbTooltip="Dokumentation"><i class="square las la-info-circle"></i></a>
        <a (click)="logout()" placement="bottom" triggers="hover focus click:blur" role="button" ngbTooltip="Logout"><i class="square las la-power-off"></i></a>
    </div>
</div>

<div id="bi-body">
    <router-outlet #appOutlet="outlet"></router-outlet>
</div>
