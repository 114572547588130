import moment from 'moment';
import { getCurrentMoment } from 'src/app/core/service/date.service';
import { BiClient } from 'src/interfaces/bi-widget';
import { CalculatorConstant, CalculatorDirection } from 'src/interfaces/calculator/Calculator';
import { DebitorType, ItemStatus } from 'src/interfaces/calculator/Product';
import { AppState, BiState, CalculatorState, CrawlerState, DistributionState, ForecastState, PriceMatrixState, ReportsState, StocksState } from './type';

const now = getCurrentMoment();
const initialDate = {
    year: now.get('year'),
    month: now.get('month') + 1,
    day: now.get('date'),
};

function createStateForClients<T>(state: T) {
    const stringyState = JSON.stringify(state);
    return {
        [BiClient.lichtblick]: JSON.parse(stringyState),
        [BiClient.roomy]: JSON.parse(stringyState),
        [BiClient.wohnguide]: JSON.parse(stringyState),
    };
}

export const state: AppState = {
    cube: BiClient.wohnguide,
    login: false,
    token: null,
    loggedInUserName: '',
    loggedInUserId: null,
    loggedInUserRole: null,
    loggedInUserSupplementalRoles: [],
    biState: createStateForClients<BiState>({
        activeTab: null,
    }),
    calculatorState: createStateForClients<CalculatorState>({
        articleFilter: '',
        defaultFilterValues: {
            debitorNo: { null: false },
            itemStatus: [ItemStatus.EIGENE, ItemStatus.NORMAL, ItemStatus.PRODUKTION],
            debitorType: [DebitorType.B2B, DebitorType.B2C, DebitorType.LBB2B, DebitorType.LBB2C, DebitorType.MARKETPLACE],
        },
        filterValues: {},
        filterOrder: [],
        sortColumn: 'articleNumber',
        sortDirection: { articleNumber: false },
        filterCheck: null,
        filterPriceCheck: null,
        filterNew: null,
        filterRevenueCheck: null,
        grouping: null,
        hiddenColumns: [],
        pageSize: 50,
        pageMax: 20,
        pageSizeGrouping: 10,
        selectedDirection: CalculatorDirection.BACKWARD,
        selectedConstant: CalculatorConstant.NONE,
        debitorGlobal: true,
        showAll: false,
        returnQuota: -1,
    }),
    forecastState: createStateForClients<ForecastState>({
        startDate: { ...initialDate },
        orderDate: { ...initialDate },
        maxPeriods: 9,
        pageSize: 100,
        pageMax: 20,
        articleFilter: '',
        defaultFilterValues: {
            creditorNo: ['70001'],
            itemStatus: [ItemStatus.EIGENE, ItemStatus.NORMAL, ItemStatus.PRODUKTION],
        },
        filterValues: {},
        filterOrder: [],
        sortColumn: 'lostOpportunity',
        sortDirection: { lostOpportunity: true },
        filterCheck: null,
        filterNew: null,
        filterOrderAmount: null,
    }),
    stocksState: createStateForClients<StocksState>({
        pageSize: 100,
        pageMax: 20,
        articleFilter: '',
        defaultFilterValues: {
            creditorNo: ['70001'],
            itemStatus: [ItemStatus.EIGENE, ItemStatus.NORMAL, ItemStatus.PRODUKTION],
        },
        filterValues: {},
        filterOrder: [],
        sortColumn: ['articleNumber'],
        sortDirection: { articleNumber: false },
        filterCheck: null,
        filterNew: null,
        filterOutOfVision: null,
        historyDepth: 120,
        filterWarehouse: {
            leinefelde: false,
            heiligenstadt: false,
            hannover: false,
            zwischen: false,
        },
        unitIsVE: false,
    }),
    distributionState: createStateForClients<DistributionState>({
        pageSize: 100,
        pageMax: 10,
        articleFilter: '',
        defaultFilterValues: {
            // creditorNo: ['70001'],
            itemStatus: [ItemStatus.EIGENE, ItemStatus.NORMAL, ItemStatus.PRODUKTION],
        },
        filterValues: {},
        filterOrder: [],
        sortColumn: ['score'],
        sortColumnPlan: ['score'],
        sortDirection: { score: true },
        sortDirectionPlan: { score: true },
        filterCheck: null,
        historyDepth: 120,
        filterWarehouse: {
            hannover: true,
            leinefelde: true,
        },
        filterSourceWarehouse: {
            hannover: true,
            leinefelde: true,
            heiligenstadt: true,
        },
        filterRecommendation: null,
        filterAffected: null,
        unitIsVE: false,
        filterByOos: true,
        filterStockDiff: null,
        allActive: false,
    }),
    reportsState: createStateForClients<ReportsState>({
        pageSize: 100,
        pageMax: 10,
        articleFilter: '',
        defaultFilterValues: {},
        filterValues: {},
        filterOrder: [],
        sortColumn: 'number',
        sortDirection: { number: false },
        filterBlocked: null,
        filterComment: null,
        filterDelta: null,
        filterRestriction: 3,
        filterConsumption: null,
    }),
    crawlerState: createStateForClients<CrawlerState>({
        articleFilter: '',
        defaultFilterValues: {},
        pageSize: 100,
        pageMax: 20,
        filterValues: {},
        filterOrder: [],
        sortColumn: 'itemNo',
        sortDirection: { itemNo: false },
        filterNew: null, //
        showAll: false, //
        filterBlocked: null,
        filterComment: null,
        filterDelta: null,
        filterByNotFound: null,
        filterByPriceDiff: null,
        hiddenColumns: ['amazon'],
    }),

    priceMatrixState: createStateForClients<PriceMatrixState>({
        articleFilter: '',
        defaultFilterValues: {
            debitorNo: ['10001', '10005', '10008', '10028', '10029', '10032', '10092', '10093'],
            // itemStatus: [ItemStatus.EIGENE, ItemStatus.NORMAL, ItemStatus.PRODUKTION], // BRAUCHEN WIR DIESE FILTER ?
            debitorType: [DebitorType.B2B, DebitorType.B2C, DebitorType.LBB2B, DebitorType.LBB2C, DebitorType.MARKETPLACE],
        },
        pageSize: 50,
        pageMax: 20,
        filterValues: {},
        filterOrder: [],
        sortColumn: 'articleNumber',
        sortDirection: { articleNumber: false },
        filterNew: null, //
        showAll: false, //
        filterBlocked: null,
        filterComment: null,
        filterDelta: null,
        selectedDebitor: null,
        showOnlyWithPrices: false,

        // hiddenColumns: [],
    }),
};

state.calculatorState.WohnGuide.defaultFilterValues.key = ['STU']; // but for this one there is no "Eigene" und "Produktion" in Status so default Filter so 2 Filters does not cooperate with each other
state.priceMatrixState.WohnGuide.defaultFilterValues.key = ['STU'];

export function getBiState() {
    console.log('getBiState', state.biState[state.cube]);
    return state.biState[state.cube];
}

export function getCalculatorState() {
    console.log('getCalculatorState', state.calculatorState[state.cube]);
    return state.calculatorState[state.cube];
}

export function getForecastState() {
    console.log('getForecastState', state.forecastState[state.cube]);
    return state.forecastState[state.cube];
}

export function getStocksState() {
    console.log('getStocksState', state.stocksState[state.cube]);
    return state.stocksState[state.cube];
}

export function getDistributionState() {
    console.log('getDistributionState', state.distributionState[state.cube]);
    return state.distributionState[state.cube];
}

export function getReportsState() {
    console.log('getReportsState', state.reportsState[state.cube]);
    return state.reportsState[state.cube];
}

export function getCrawlerState() {
    console.log('getCrawlerState', state.crawlerState[state.cube]);
    return state.crawlerState[state.cube];
}

export function getPriceMatrixState() {
    console.log('getPriceMatrixState', state.priceMatrixState[state.cube]);
    return state.priceMatrixState[state.cube];
}



