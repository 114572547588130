import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BiClient } from 'src/interfaces/bi-widget';
import { Calculator } from 'src/interfaces/calculator/Calculator';
import { Product } from 'src/interfaces/calculator/Product';
import { DataFilter, IdentityFilter } from 'src/interfaces/calculator/QueryLib';
import { ArticleDataForPriceMatrixShort, DataForPriceStatus } from 'src/interfaces/price-matrix/Price-matrix';
import { ApiService } from './api.service';
import { ArticleDataService } from './articledata.service';

@Injectable()
export class MatrixService extends Calculator {

    protected loadArticles$: Subject<{ client: BiClient, filter: DataFilter<Product>, limit: number, showAll?: boolean, }> = new Subject();
 //   public loadedArticles$: BehaviorSubject<ArticleDataForPriceMatrix[]> = new BehaviorSubject([]);
// ArticleDataForPriceMatrixShort
    public loadedArticles$: BehaviorSubject<ArticleDataForPriceMatrixShort[]> = new BehaviorSubject([]);


    protected currentResponseTimestamp: Date;

    //NOTE: price checker is injected here to setup freshly loaded articles. Claculator component controls execution of checks, though.
    constructor(private articleData: ArticleDataService, private apiService: ApiService) {
        super();
        // tslint:disable-next-line: no-string-literal
        this.logs = window['calcLog']; //@TODO: quick impl for debugging. Implement properly or remove.
        this.loadedArticles$ = new BehaviorSubject([]);
        this.loadArticles$.pipe(switchMap(({ client, filter, showAll }) => {
                return this.articleData.getArticlesPrice(client, filter, showAll, this.currentResponseTimestamp);
        })).subscribe(this.loadedArticles$);
    }

    //NOTE: filter by "product", but gets "article". It is distinguished to save data transmission. See transformer in calculator service.
    public loadRemoteArticles(client: BiClient, filter: DataFilter<Product> = {}, showAll = false, limit?: number) {
        this.articles = new Map();
        this.articlesByKey = new Map();
        this.loadArticles$.next({ client, filter, showAll, limit });
    }

    public loadLocalArticles(filter: DataFilter<Product> = {}) {
        // @TODO: implement client side filtering as necessary
        let articles = this.loadedArticles$.value; // get current value from BehaviourSubject
        let numberRegex: RegExp;
        const numberLike = (filter.number as IdentityFilter<string>)?.like;
        if (numberLike) {
            numberRegex = new RegExp(numberLike.replace(/%/ig, '.*'), 'ig'); //@TODO: escape literal dots
        }
        articles = articles.filter(a => {
            let positive: boolean = true;
            if (numberRegex) {
                positive = positive && !!a.articleNumber.match(numberRegex);
            }
            return positive;
        });
        this.loadedArticles$.next(articles);
    }

    public async saveState(state: DataForPriceStatus) {
        let res = await this.apiService.post<DataForPriceStatus>(state, `priceState/save`).toPromise();
        return res;
    }

    public async saveBatchState(batch: DataForPriceStatus[]) {
        let res = await this.apiService.post<DataForPriceStatus[]>(batch, `priceState/save/batch`).toPromise();
        return res;
    }

}
