import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BiClient } from 'src/interfaces/bi-widget';
import { Article } from 'src/interfaces/calculator/Article';
import { Product, SimpleProductWithPurchaseAndUvpPrices } from 'src/interfaces/calculator/Product';
import { DataFilter } from 'src/interfaces/calculator/QueryLib';
import { ArticleDataForPriceMatrixShort } from 'src/interfaces/price-matrix/Price-matrix';
import { ApiService } from './api.service';

@Injectable()
export class ArticleDataService {

    constructor(private apiService: ApiService) { }

    //NOTE: filter by "product", but gets "article". It is distinguished to save data transmission. See transformer in calculator service.
    public getArticles(client: BiClient, filter: DataFilter<Product> = {}, showAll = false, timestamp?: Date, limit = 30000): Observable<Article[]> {
        // @TODO: sanity limit. adapt if necessary
        return this.apiService.post<DataFilter<Product>, Article[]>(filter, `articles/${client}?limit=30000${showAll ? '&all' : ''}`).pipe(map(res => res.data));
    }

    public getArticlesPrice(client: BiClient, filter: DataFilter<Product> = {}, showAll = false, timestamp?: Date): Observable<ArticleDataForPriceMatrixShort[]> {
        // @TODO: sanity limit. adapt if necessary
        return this.apiService.post<DataFilter<Product>, ArticleDataForPriceMatrixShort[]>(filter, `articlesPrice/${client}${showAll ? '&all' : ''}`).pipe(map(res => res.data));
    }

    public getSimpleProducts(client: BiClient, filter: DataFilter<SimpleProductWithPurchaseAndUvpPrices> = {}, showAll = false): Observable<SimpleProductWithPurchaseAndUvpPrices[]> {
        // @TODO: sanity limit. adapt if necessary
        return this.apiService.post<DataFilter<SimpleProductWithPurchaseAndUvpPrices>, SimpleProductWithPurchaseAndUvpPrices[]>(filter, `simple-products/${client}?limit=30000${showAll ? '&all' : ''}`).pipe(map(res => res.data));
    }
}
