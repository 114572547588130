import { Cube, CubeDimensionType, createReferences, createReference, CubeRelationship, CubeGranularity, createSumMeasure, createCountDistinctMeasure, createStringDimension, createTimeDimension, createMeasure, CubeMeasureType } from './cube';
import { Pim, PimCountMeasures, PimStringDimensions } from './pim-cube';

export enum BiCountMeasures {
    orderCount = '[BI].orderCount',
    customerOrderCount = '[BI].customerOrderCount',
    orderCountPrel = '[BI].orderCountPrel',
    customerOrderCountPrel = '[BI].customerOrderCountPrel',
    cancelCount = '[BI].cancelCount',
    customerCancelCount = '[BI].customerCancelCount',
    returnCount = '[BI].returnCount',
    customerReturnCount = '[BI].customerReturnCount',
}

export enum BiSumMeasures {
    orderItemQuantity = '[BI].orderItemQuantity',
    customerOrderItemQuantity = '[BI].customerOrderItemQuantity',
    orderItemQuantityPrel = '[BI].orderItemQuantityPrel',
    customerOrderItemQuantityPrel = '[BI].customerOrderItemQuantityPrel',
    cancelItemQuantity = '[BI].cancelItemQuantity',
    customerCancelItemQuantity = '[BI].customerCancelItemQuantity',
    returnItemQuantity = '[BI].returnItemQuantity',
    customerReturnItemQuantity = '[BI].customerReturnItemQuantity',
    scrapItemQuantity = '[BI].scrapItemQuantity',
    customerScrapItemQuantity = '[BI].customerScrapItemQuantity',
    orderItemSum = '[BI].orderItemSum',
    customerOrderItemSum = '[BI].customerOrderItemSum',
    orderItemSumPrel = '[BI].orderItemSumPrel',
    customerOrderItemSumPrel = '[BI].customerOrderItemSumPrel',
    cancelItemSum = '[BI].cancelItemSum',
    customerCancelItemSum = '[BI].customerCancelItemSum',
    returnItemSum = '[BI].returnItemSum',
    customerReturnItemSum = '[BI].customerReturnItemSum',
    scrapItemSum = '[BI].scrapItemSum',
    customerScrapItemSum = '[BI].customerScrapItemSum',
    ledgerSum = '[BI].ledgerSum',
    orderItemDiscount = '[BI].orderItemDiscount',
    itemsPerParcel = '[BI].itemsPerParcel',
    orderCostSum = '[BI].orderCostSum',
}

export enum BiStringDimensions {
    id = '[BI].id',
    recipientCountry = '[BI].recipientCountry',
    orderItemNo = '[BI].orderItemNo',
    orderItemNo1 = '[BI].orderItemNo1',
    orderItemKey = '[BI].orderItemKey',
    orderItemLocation = '[BI].orderItemLocation',
    orderItemParcelType = '[BI].orderItemParcelType',
    orderShippingAgent = '[BI].orderShippingAgent',
    debitorNo = '[BI].debitorNo',
    orderDayOfWeek = '[BI].orderDayOfWeek',
    orderWeekOfYear = '[BI].orderWeekOfYear',
    orderMonthOfYear = '[BI].orderMonthOfYear',
    recipientZipRange = '[BI].recipientZipRange',
    recipientGender = '[BI].recipientGender',
    orderYear = '[BI].orderYear',
}

export enum BiTimeDimensions {
    orderDate = '[BI].orderDate',
    orderShipmentDate = '[BI].orderShipmentDate',
    prelOrderDate = '[BI].prelOrderDate',
}

export type BiTimeDimensionsWithGranularity = `${BiTimeDimensions}.${CubeGranularity}`;

const seriesFilterReferences = createReferences<keyof typeof BiStringDimensions | PimStringDimensions>('debitorNo', 'orderItemLocation', 'orderShippingAgent', 'orderItemNo1', 'orderDayOfWeek', 'orderWeekOfYear', 'orderMonthOfYear',
PimStringDimensions.mounting, PimStringDimensions.material, PimStringDimensions.brand, PimStringDimensions.color, PimStringDimensions.width, PimStringDimensions.length, PimStringDimensions.producedIn);
const seriesMeasures = createReferences<keyof typeof BiCountMeasures | keyof typeof BiSumMeasures | PimCountMeasures>('orderCount', 'cancelCount', 'returnCount',
                                        'orderItemSum', 'customerOrderItemSum', 'cancelItemSum', 'returnItemSum', 'scrapItemSum', 'ledgerSum',
                                        'orderItemQuantity', 'cancelItemQuantity', 'returnItemQuantity', 'scrapItemQuantity');
export const WohnGuide: Cube<
    typeof BiCountMeasures | typeof BiSumMeasures,
    typeof BiStringDimensions,
    typeof BiTimeDimensions, {
        Pim: typeof Pim;
    }
> = {
    sql: () => `SELECT * FROM castly.wohnguide`,
    measures: {
        orderItemQuantity: createSumMeasure('Recipient_Order_Item_Quantity', [
            `Recipient_Order_Type = 'order'`,
        ]),
        customerOrderItemQuantity: createSumMeasure('Recipient_Order_Item_Quantity', [
            `Recipient_Order_Type = 'order'`,
            `Recipient_Is_Customer = 1`,
        ]),
        orderItemQuantityPrel: createSumMeasure('Recipient_Order_Item_Quantity_Ordered', [
            `Recipient_Order_Type IN ('order', 'other')`,
        ]),
        customerOrderItemQuantityPrel: createSumMeasure('Recipient_Order_Item_Quantity_Ordered', [
            `Recipient_Order_Type IN ('order', 'other')`,
            `Recipient_Is_Customer = 1`,
        ]),
        cancelItemQuantity: createSumMeasure('Recipient_Order_Item_Quantity', [
            `Recipient_Order_Type = 'cancel'`,
        ]),
        customerCancelItemQuantity: createSumMeasure('Recipient_Order_Item_Quantity', [
            `Recipient_Order_Type = 'cancel'`,
            `Recipient_Is_Customer = 1`,
        ]),
        returnItemQuantity: createSumMeasure('Receipt_Item_Quantity_Returned', [
            `Recipient_Order_Type = 'return'`, // we can filter for order type return lines here as they are a superset of lines with a non-null Receipt_No
        ]),
        customerReturnItemQuantity: createSumMeasure('Receipt_Item_Quantity_Returned', [
            `Recipient_Order_Type = 'return'`, // we can filter for order type return lines here as they are a superset of lines with a non-null Receipt_No
            `Recipient_Is_Customer = 1`,
        ]),
        scrapItemQuantity: createSumMeasure('Receipt_Item_Quantity_Scrapped', [
            `Recipient_Order_Type = 'return'`, // we can filter for order type return lines here as they are a superset of lines with a non-null Receipt_No
        ]),
        customerScrapItemQuantity: createSumMeasure('Receipt_Item_Quantity_Scrapped', [
            `Recipient_Order_Type = 'return'`, // we can filter for order type return lines here as they are a superset of lines with a non-null Receipt_No
            `Recipient_Is_Customer = 1`,
        ]),
        orderCount: createCountDistinctMeasure('Recipient_Order_No', [
            `Recipient_Order_Type = 'order'`,
        ]),
        customerOrderCount: createCountDistinctMeasure('Recipient_Order_No', [
            `Recipient_Order_Type = 'order'`,
            `Recipient_Is_Customer = 1`,
        ]),
        orderCountPrel: createCountDistinctMeasure('Recipient_Order_No', [
            `Recipient_Order_Type IN ('order', 'other')`,
        ]),
        customerOrderCountPrel: createCountDistinctMeasure('Recipient_Order_No', [
            `Recipient_Order_Type IN ('order', 'other')`,
            `Recipient_Is_Customer = 1`,
        ]),
        cancelCount: createCountDistinctMeasure('Recipient_Order_No', [
            `Recipient_Order_Type = 'cancel'`,
        ]),
        customerCancelCount: createCountDistinctMeasure('Recipient_Order_No', [
            `Recipient_Order_Type = 'cancel'`,
            `Recipient_Is_Customer = 1`,
        ]),
        returnCount: createCountDistinctMeasure('Recipient_Order_No', [
            `Recipient_Order_Type = 'return'`, // we can filter for order type return lines here as they are a superset of lines with a non-null Receipt_No
        ]),
        customerReturnCount: createCountDistinctMeasure('Recipient_Order_No', [
            `Recipient_Order_Type = 'return'`, // we can filter for order type return lines here as they are a superset of lines with a non-null Receipt_No
            `Recipient_Is_Customer = 1`,
        ]),
        orderItemSum: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Recipient_Order_Item_Quantity`, [
            `Recipient_Order_Type = 'order'`,
        ]),
        customerOrderItemSum: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Recipient_Order_Item_Quantity`, [
            `Recipient_Order_Type = 'order'`,
            `Recipient_Is_Customer = 1`,
        ]),
        orderItemSumPrel: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Recipient_Order_Item_Quantity_Ordered`, [
            `Recipient_Order_Type IN ('order', 'other')`,
        ]),
        customerOrderItemSumPrel: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Recipient_Order_Item_Quantity_Ordered`, [
            `Recipient_Order_Type IN ('order', 'other')`,
            `Recipient_Is_Customer = 1`,
        ]),
        cancelItemSum: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Recipient_Order_Item_Quantity`, [
            `Recipient_Order_Type = 'cancel'`,
        ]),
        customerCancelItemSum: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Recipient_Order_Item_Quantity`, [
            `Recipient_Order_Type = 'cancel'`,
            `Recipient_Is_Customer = 1`,
        ]),
        returnItemSum: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Receipt_Item_Quantity_Returned`, [
            `Memo_No IS NOT NULL`, // only account for those order type return lines carrying a memo number
        ]),
        customerReturnItemSum: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Receipt_Item_Quantity_Returned`, [
            `Memo_No IS NOT NULL`, // only account for those order type return lines carrying a memo number
            `Recipient_Is_Customer = 1`,
        ]),
        scrapItemSum: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Receipt_Item_Quantity_Scrapped`, [
            `Memo_No IS NOT NULL`, // only account for those order type return lines carrying a memo number
        ]),
        customerScrapItemSum: createSumMeasure((CUBE) => `${CUBE}.Debitor_Order_Item_Price * ${CUBE}.Receipt_Item_Quantity_Scrapped`, [
            `Memo_No IS NOT NULL`, // only account for those order type return lines carrying a memo number
            `Recipient_Is_Customer = 1`,
        ]),
        ledgerSum: createSumMeasure('Debitor_Order_Item_Price', [
            `Recipient_Order_Type IN ('credit', 'invoice')`
        ]),
        orderItemDiscount: createSumMeasure('Recipient_Order_Item_Discount', [
            `Recipient_Order_Type = 'order'`
        ]),
        itemsPerParcel: createMeasure(CubeMeasureType.number, (CUBE) => `ROUND(${CUBE.orderItemQuantity} / ${CUBE.orderCount}, 2)`),
        // this measure relies on joined cube depending on client and therefore has to be replaced in schema.js for clients other than wohnguide
        orderCostSum: createSumMeasure((CUBE, Product_WohnGuide) => `${Product_WohnGuide}.Unit_Cost * ${CUBE}.Recipient_Order_Item_Quantity`, [
            `Recipient_Order_Type = 'order'`,
        ]),
    },
    dimensions: {
        id: createStringDimension('incid', true, false),
        orderItemNo: createStringDimension('Recipient_Order_Item_No'),
        orderItemNo1: createStringDimension('Recipient_Order_Item_No_1'),
        orderItemKey: createStringDimension('Recipient_Order_Item_Key'),
        orderItemLocation: createStringDimension('Recipient_Order_Item_Location'),
        orderItemParcelType: createStringDimension('Customer_Order_Item_Parcel_Type'),
        orderShippingAgent: createStringDimension('Customer_Order_Shipping_Agent'),
        debitorNo: createStringDimension('Debitor_No'),
        orderDate: createTimeDimension('Recipient_Date'),
        orderShipmentDate: createTimeDimension('Recipient_Order_Shipment_Date'),
        prelOrderDate: createTimeDimension('Customer_Order_Date'), //@TODO: do we need a Recipient Date selector for this? Check sql scripts and data.
        orderDayOfWeek: createStringDimension((CUBE) => `(WEEKDAY(${CUBE}.Recipient_Date) + 1)`),
        orderWeekOfYear: createStringDimension((CUBE) => `WEEK(${CUBE}.Recipient_Date, 3)`),
        orderMonthOfYear: createStringDimension((CUBE) => `MONTH(${CUBE}.Recipient_Date)`),
        orderYear: createStringDimension((CUBE) => `YEAR(${CUBE}.Recipient_Date)`),
        recipientCountry: createStringDimension('Recipient_Country'),
        recipientZipRange: {
            type: CubeDimensionType.string,
            case: {
                when: [...Array(100).keys()].map(i => ({
                    sql: (CUBE) => `${CUBE}.Recipient_Zip LIKE '${i < 10 ? '0' : ''}${i}___'`,
                    label: `${i < 10 ? '0' : ''}${i}`,
                })),
                else: { label: '00' }
            }
        },
        recipientGender: {
            type: CubeDimensionType.string,
            case: {
                when: [{
                    sql: (CUBE) => `${CUBE}.Customer_Salutation REGEXP '^(Frau|Ms)'`,
                    label: `weiblich`,
                }, {
                    sql: (CUBE) => `${CUBE}.Customer_Salutation REGEXP '^(Herr|Mr)'`,
                    label: `männlich`
                }],
                else: { label: '[Keine Angabe]' }
            }
        },
    },

    preAggregations: { //@TODO: write functions to avoid code duplication
        monthlyOrderItemQuantity: {
            type: 'rollup',
            measureReferences: createReferences('orderItemQuantity'),
            dimensionReferences: createReferences('orderItemNo'),
            timeDimensionReference: createReference('orderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.month,
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
              }
        },
        prel: { //focus preliminary
            type: 'rollup',
            measureReferences: createReferences('orderCountPrel', 'orderItemQuantityPrel', 'orderItemSumPrel'), //@TODO: consider the approx. maybe degraded performance is ok for those numbers
            dimensionReferences: createReferences('prelOrderDate'),
            timeDimensionReference: createReference('prelOrderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.day, //needs to be day to filter until today
            /*@TODO: check if any more config applies to other aggs
            indexes: {
                main: {
                  columns: createReferences('debitorNo')
                }
            } @TODO: check indicies later if performance still not enough*/
            //partitionGranularity: `month` @TODO: check if we should use this. Makes sense because old month wont be affected by new data but it will slow down queries. Updates are always during nighttime anyways.
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
                /*incremental: true,
                updateWindow: `14 day` //@TODO: incremental could make sense, but sometimes old data may change*/
              }
        },
        prelOrderCount: { //focus preliminary
            type: 'rollup',
            measureReferences: createReferences('orderCountPrel'), //@TODO: consider the approx. maybe degraded performance is ok for those numbers
            dimensionReferences: createReferences('prelOrderDate'),
            timeDimensionReference: createReference('prelOrderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.day, //needs to be day to filter until today
            /*@TODO: check if any more config applies to other aggs
            indexes: {
                main: {
                  columns: createReferences('debitorNo')
                }
            } @TODO: check indicies later if performance still not enough*/
            //partitionGranularity: `month` @TODO: check if we should use this. Makes sense because old month wont be affected by new data but it will slow down queries. Updates are always during nighttime anyways.
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
                /*incremental: true,
                updateWindow: `14 day` //@TODO: incremental could make sense, but sometimes old data may change*/
              }
        },
        year: { //focus counts for a year @TODO: not working for nondecomposable
            type: 'rollup',
            measureReferences: createReferences('orderCount', 'cancelCount', 'orderItemSum', 'returnCount'), //@TODO: consider the approx. maybe degraded performance is ok for those numbers
            dimensionReferences: createReferences('orderDate'),
            timeDimensionReference: createReference('orderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.day, //needs to be day to filter until today
            /*@TODO: check if any more config applies to other aggs
            indexes: {
                main: {
                  columns: createReferences('debitorNo')
                }
            } @TODO: check indicies later if performance still not enough*/
            //partitionGranularity: `month` @TODO: check if we should use this. Makes sense because old month wont be affected by new data but it will slow down queries. Updates are always during nighttime anyways.
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
                /*incremental: true,
                updateWindow: `14 day` //@TODO: incremental could make sense, but sometimes old data may change*/
              }
        },
        countDebW: { //extra for nondecomposable counts per debitor weekly
            type: 'rollup',
            measureReferences: createReferences('orderCount', 'cancelCount', 'returnCount'),
            dimensionReferences: createReferences('debitorNo'),
            timeDimensionReference: createReference('orderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.week,
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
              }
        },
        countLocW: { //extra for nondecomposable counts per location weekly
            type: 'rollup',
            measureReferences: createReferences('orderCount', 'cancelCount', 'returnCount'),
            dimensionReferences: createReferences('orderItemLocation'),
            timeDimensionReference: createReference('orderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.week,
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
              }
        },
        countDeb: { //extra for nondecomposable counts per debitor
            type: 'rollup',
            measureReferences: createReferences('orderCount', 'cancelCount', 'returnCount'),
            dimensionReferences: createReferences('debitorNo'),
            timeDimensionReference: createReference('orderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.day,
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
              }
        },
        countLoc: { //extra for nondecomposable counts per location
            type: 'rollup',
            measureReferences: createReferences('orderCount', 'cancelCount', 'returnCount'),
            dimensionReferences: createReferences('orderItemLocation'),
            timeDimensionReference: createReference('orderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.day,
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
              }
        },
        seriesM: { //preagg for time series like widgets monthly. Need to sort granularity like this because first matching pre agg is used.
            type: 'rollup',
            measureReferences: seriesMeasures,
            dimensionReferences: seriesFilterReferences,
            timeDimensionReference: createReference('orderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.month,
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
              }
        },
        seriesW: { //preagg for time series like widgets weekly
            type: 'rollup',
            measureReferences: seriesMeasures,
            dimensionReferences: seriesFilterReferences,
            timeDimensionReference: createReference('orderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.week,
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
              }
        },
        seriesD: { //preagg for time series like widgets
            type: 'rollup',
            measureReferences: seriesMeasures,
            dimensionReferences: seriesFilterReferences,
            timeDimensionReference: createReference('orderDate'),
            scheduledRefresh: true,
            granularity: CubeGranularity.day,
            refreshKey: {
                sql: () => `SELECT COUNT(*) FROM castly.wohnguide`,
              }
        }
    },
    joins: {
        Pim: {
            relationship: CubeRelationship.belongsTo,
            // tslint:disable-next-line:no-shadowed-variable
            sql: (CUBE, Pim) => `${CUBE}.Recipient_Order_Item_No = ${Pim}.id`,
        }
    }
};
