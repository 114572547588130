import { Injectable } from '@angular/core';
import { BiClient } from 'src/interfaces/bi-widget';
import { Product, SimpleProduct, SimpleProductWithStockData } from 'src/interfaces/calculator/Product';
import { DataFilter, IdentityFilter } from 'src/interfaces/calculator/QueryLib';
import { ArticleForecast, ArticleForecastWithStockData } from 'src/interfaces/forecast/Article';
import { Forecaster } from 'src/interfaces/forecast/Forecaster';
import { BiStringDimensions, BiSumMeasures, BiTimeDimensions } from 'src/interfaces/wohnguide-cube';
import { ForecastDataService } from './forecastdata.service';
import { BiQuery, prepareCubeQuery } from '../../core/misc/cube-helpers';
import moment from 'moment';
import { CubeOperatorBinary } from 'src/interfaces/cube';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { getCurrentMoment } from './date.service';

/**
 * This class is a facade. Exposes forecaster. Handles loading, filtering, searching and sort of articles. May unload or reload data from server.
 * Filter refers to load filtered articles. Search refers to "filter" locally.
 * May transmit calculations to the server if necessary and feasable.
 * IMPORTANT: by using Injectable, this is a singleton. So only on article set is loaded and only on set of calculator flags is active.
 * IMPORTANT: Forecast objects are always passed around as references. Make sure to change them only through calculator.
 */
@Injectable()
export class ForecastService extends Forecaster {

    public loadedForecasts$: BehaviorSubject<ArticleForecast[] | ArticleForecastWithStockData[]>;
    public allLoadedForecasts: ArticleForecast[] | ArticleForecastWithStockData[];
    protected loadForecasts$: Subject<{ client: BiClient, filter: DataFilter<SimpleProductWithStockData> , maxPeriods: number, model: string, depth?: number }> = new Subject();
    protected filterLocal: DataFilter<SimpleProductWithStockData> | undefined;

    constructor(private forecastData: ForecastDataService) {
        super(getCurrentMoment);
        this.loadedForecasts$ = new BehaviorSubject([]);
        this.loadedForecasts$.subscribe(forecasts => {
             // NOTE: since our super service works synchronously, the consuming component needs to ensure that its methods are called only after loadedForecasts$ has been emitted
            this.setForecasts(forecasts);
        });
        this.loadForecasts$.pipe(switchMap(({ client, filter, maxPeriods, model, depth }) => {
            if (depth != null) { // if we have a depth parameter, we want forecasts with stock data
                return this.forecastData.getForecastsWithStockData(client, filter, depth, maxPeriods);
            } else {
                return this.forecastData.getForecasts(client, filter as DataFilter<SimpleProduct>, maxPeriods, model);
            }
        })).subscribe((res) => {
            this.allLoadedForecasts = res;
            if (this.filterLocal) {
                this.loadLocalForecasts(this.filterLocal);
            } else {
                this.loadedForecasts$.next(res);
            }
        });
     }

    //NOTE: filter by "product", but gets "article". It is distinguished to save data transmission. See transformer in calculator service.
    public loadRemoteForecasts(client: BiClient, filter: DataFilter<SimpleProduct> = {}, filterLocal: DataFilter<SimpleProduct> = {}, maxPeriods: number = 6, model: string = 'forecast'): void {
        this.filterLocal = filterLocal;
        this.loadForecasts$.next({ client, filter, maxPeriods, model });
    }

    public loadRemoteForecastsWithStockData(client: BiClient, filter: DataFilter<SimpleProductWithStockData> = {}, filterLocal: DataFilter<SimpleProductWithStockData> = {}, depth = 60, maxPeriods: number = 6, model: string = 'forecast', ): void {
        this.filterLocal = filterLocal;
        this.loadForecasts$.next({ client, filter, maxPeriods, model, depth });
    }

    public loadLocalForecasts(filter: DataFilter<SimpleProductWithStockData> = {}) {
        // @TODO: implement client side filtering as necessary
        if (filter.and) {
            for (let f of filter.and) {
                for (let fk of Object.keys(f)) {
                    filter[fk] = f[fk];
                }
            }
        }
        let forecasts = this.allLoadedForecasts; // get current value from BehaviourSubject
        let numberRegex: RegExp;
        const numberLike = (filter.number as IdentityFilter<string>)?.like;
        if (numberLike) {
            numberRegex = new RegExp(numberLike.replace(/%/ig, '.*'), 'ig'); //@TODO: escape literal dots
        }
        forecasts = forecasts.filter( a => {
            let positive: boolean = true;
            if (numberRegex) {
                positive = positive && !!a.articleNumber.match(numberRegex);
            }
            if (filter.key && (filter.key as string[]).length) {//@TODO: why do we have to cast?
                positive = positive && (filter.key as string[]).includes(a.articleGroup);
            }
            return positive;
        });
        // console.log('loadLocalForecasts', filter, forecasts, this.allLoadedForecasts);
        this.loadedForecasts$.next(forecasts);
    }

    /**
     * @param articleNumbers filter by them
     * @returns monthly consumption per article for dateRange spanning all interesting months
     */
    public getPreviousConsumptionQuery(client: BiClient, articleNumbers?: string[]): BiQuery {
        let previousConsumptionQuery: BiQuery = {
            measures: [BiSumMeasures.orderItemQuantity],
            dimensions: [BiStringDimensions.orderItemNo],
            timeDimensions: [{
                dateRange: [moment(this.startingPeriod).utc().subtract(2, 'year').startOf('year').toISOString(), moment(this.startingPeriod).utc().endOf('month').toISOString()],
                dimension: BiTimeDimensions.orderDate,
                granularity: 'month'
            }]
        };
        if (articleNumbers) {
            previousConsumptionQuery.filters = [{
                operator: CubeOperatorBinary.equals,
                member: BiStringDimensions.orderItemNo,
                values: articleNumbers
            }];
        }
        return prepareCubeQuery(previousConsumptionQuery, undefined, client);
    }

}
