import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { BiEntity } from 'src/interfaces/bi-widget';
import { TitleForBiEntity } from 'src/app/core/service/widget.service';
import { ProductWithDebitor } from 'src/interfaces/calculator/Product';
import { DefaultProductFilterEntites, ProductFilter, ProductFilterComponent } from './product-filter.component';

@Component({
    selector: 'app-product-filter-bar',
    templateUrl: './product-filter-bar.component.html',
})
export class ProductFilterBarComponent implements OnInit {

    @ViewChildren(ProductFilterComponent)
    public filterComponents: QueryList<ProductFilterComponent<keyof ProductWithDebitor>>;
    @Output()
    public filterEmitter: EventEmitter<ProductFilter> = new EventEmitter();
    @Input()
    public filterValues: ProductFilter = {};
    @Output()
    public filterValuesChange: EventEmitter<ProductFilter> = new EventEmitter();
    @Input()
    public defaultValues: ProductFilter = {};
    protected _filterOrder: Array<keyof ProductWithDebitor>;
    protected initialFilters: Array<keyof ProductWithDebitor>;
    protected resetter: ProductFilter = {
        debitorNo: undefined,
        creditorNo: undefined,
        debitorType: undefined,
        itemStatus: undefined,
        replenishmentSystem: undefined,
        mounting: undefined,
        material: undefined,
        brand: undefined,
        colourPim: undefined,
        width: undefined,
        length: undefined,
        productionCountry: undefined
    };

    @Output()
    public filterOrderChange: EventEmitter<Array<keyof ProductWithDebitor>> = new EventEmitter();

    @Output()
    public availableFiltersChange: EventEmitter<Array<keyof ProductWithDebitor>> = new EventEmitter();

    @Input()
    public set filterOrder(value: Array<keyof ProductWithDebitor>) {
        this._filterOrder = value;
        if (this.initialFilters) {
            this.availableFiltersChange.emit(this.availableFilters);
        }
    }
    public get filterOrder(): Array<keyof ProductWithDebitor> {
        return this._filterOrder;
    }

    @Input()
    public set availableFilters(filters: Array<keyof ProductWithDebitor>) {
        if (!this.initialFilters) {
            this.initialFilters = filters;
        }
    }
    public get availableFilters(): Array<keyof ProductWithDebitor> {
        return this.initialFilters.filter(e => this.filterOrder.indexOf(e) < 0);
    }

    public async ngOnInit() {
        if (!this.initialFilters) {
            this.initialFilters = DefaultProductFilterEntites;
        }
        this.availableFiltersChange.emit(this.availableFilters);
        this.filterValues = {
            ...this.defaultValues,
            ...this.filterValues
        };
    }

    public addFilter(filter: keyof ProductWithDebitor) {
        this.filterOrder.push(filter);
        this.filterOrderChange.emit(this.filterOrder);
        this.availableFiltersChange.emit(this.availableFilters);
        console.log('addFilter', filter);
    }

    public removeFilter<T extends keyof ProductWithDebitor>(key: T) {
        console.log('removeFilter', key);
        if (JSON.stringify(this.filterValues[key]) !== JSON.stringify(this.defaultValues[key])) {
            this.filterValues[key] = this.defaultValues[key];
            this.setFilterValues();
        }
        this.filterOrder.splice(this.filterOrder.indexOf(key), 1);
        this.filterOrderChange.emit(this.filterOrder);
        this.availableFiltersChange.emit(this.availableFilters);
        setTimeout(() => {
            if (key === 'key' || key === 'legacyKey') {
                for (const fc of this.filterComponents) {
                    if (fc.property !== key) {
                        fc.resetFilter();
                    }
                }
            }
        });
    }

    public resetFilter() {
        this.filterOrder = [];
        this.filterOrderChange.emit(this.filterOrder);
        this.availableFiltersChange.emit(this.availableFilters);
        this.setFilterValues({ ...this.defaultValues });
    }

    public getTitleForEntity(entity: BiEntity | keyof typeof BiEntity) {
        return TitleForBiEntity[entity];
    }

    public setFilterValue<T extends keyof ProductWithDebitor>(entity: T, filter: ProductFilter[T]) {
        if (filter) {
            this.setFilterValues({ [entity]: filter });
        }
    }

    public setFilterValues(filter: ProductFilter = {}) {
        // special handling for key filters, as they reset all other filters
        if (filter.legacyKey || filter.key) {
            this.filterOrder = [];
            if (filter.legacyKey && this.initialFilters.indexOf('legacyKey') >= 0) {
                this.filterOrder.push('legacyKey');
            }
            if (filter.key && this.initialFilters.indexOf('key') >= 0) {
                this.filterOrder.push('key');
            }
            this.filterOrderChange.emit(this.filterOrder);
            this.availableFiltersChange.emit(this.availableFilters);
            //@TODO: to mimic "resetFilter" we use the resetter. Othwerwise, the calculator wont remove the filter that where not explicitly set to undefined...
            this.filterValues = {
                ...this.resetter,
                ...this.defaultValues,
                ...filter,
            };
        } else {
            this.filterValues = {
                ...this.filterValues,
                ...filter,
            };
        }
        console.log('setFilterValues', filter, this.filterValues, this.filterFilters, this.filterOrder, this.initialFilters, this.availableFilters);
        this.filterEmitter.emit(this.filterValues);
        this.filterValuesChange.emit(this.filterValues);
        this.availableFiltersChange.emit(this.availableFilters);
    }

    public get hasFilter() {
        return this.filterOrder.length > 0;
    }

    public get filterFilters(): ProductFilter {
        let filter: ProductFilter = {};
        if (this.filterValues.legacyKey) {
            filter.legacyKey = this.filterValues.legacyKey;
        }
        if (this.filterValues.key) {
            filter.key = this.filterValues.key;
        }
        return filter;
    }

}
