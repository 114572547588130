import { Directive, HostListener, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormControlDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { LoaderComponent } from './components/loader.component';
import { PreventScrollingDirective } from './directives/prevent-scrolling.directive';
import { ValidationErrorsDirective } from './directives/validation-error.directive';
import { ProductFilterComponent } from './components/product-filter.component';
import { ProductFilterBarComponent } from './components/product-filter-bar.component';
import { NgChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FormControlTS } from '@common_node_modules/angular-typesafe-forms';
import { ArticleNumberAnonymizer } from './pipes/article_number_anonymizer';
import { FormatAnonymizer } from './pipes/article_number_anonymizer copy';



@Directive({
    selector: 'input[tsMasked]',
})
export class TSMaskedInputDirective implements OnInit {

    private mask!: (v: unknown) => string | number;
    private unmask!: (v: string | number) => unknown;

    constructor(
        private control: FormControlDirective,
    ) { }

    public ngOnInit() {
        if (this.control && this.control.valueAccessor) {
            this.mask = (this.control.form as FormControlTS<unknown>).maskFn!;
            this.unmask = (this.control.form as FormControlTS<unknown>).unmaskFn!;
            if (this.mask) {
                const originalWriteVal = this.control.valueAccessor.writeValue.bind(this.control.valueAccessor);
                this.control.valueAccessor.writeValue = (val: unknown) => originalWriteVal(this.mask(val));
            }
            if (this.unmask) {
                const originalChange = (this.control.valueAccessor as ControlValueAccessor & { onChange: Function }).onChange.bind(this.control.valueAccessor);
                this.control.valueAccessor.registerOnChange((val: string | number) => originalChange(this.unmask(val)));
            }
            if (this.control.control) {
                this.control.control.setValue(this.control.value, { emitEvent: false });
            }
        }
    }

}

@Directive({
    selector: 'input[onlyNumbers]'
})
export class OnlyNumbersMask {

    private prefixes: string[] = ['-'];

    private decimals: string[] = [',', '.'];

    private numbers: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    constructor(
        private control: FormControlDirective,

    ) { }
    @HostListener('keypress', ['$event']) public onInput(event: KeyboardEvent): void {
        // First allow copy and mark all on osx and other platforms,
        // afterwards let only numbers and special chars through
        // HINT: patterns only work on validity
        if ((event.metaKey && event.key === 'a') || (event.metaKey && event.key === 'c') || (event.metaKey && event.key === 'v') ||
            (event.ctrlKey && event.key === 'a') || (event.ctrlKey && event.key === 'c') || (event.ctrlKey && event.key === 'v')) {
            return;
        } else {
            if (this.control) {
                if (this.control.value != null) {
                    const value = (this.control.form as FormControlTS<unknown>).maskFn ? (this.control.form as FormControlTS<unknown>).maskFn(this.control.value) : this.control.value;
                    if (value.toString().indexOf('.') >= 0) {
                        if (!this.numbers.includes(event.key)) {
                            event.preventDefault();
                        }
                    } else {
                        if (!this.numbers.includes(event.key) && !this.decimals.includes(event.key)) {
                            event.preventDefault();
                        }
                    }
                } else {
                    if (!this.numbers.includes(event.key) && !this.decimals.includes(event.key) && !this.prefixes.includes(event.key)) {
                        event.preventDefault();
                    }
                }
            }
        }
    }
}

@NgModule({
    declarations: [
        LoaderComponent,
        ProductFilterComponent,
        ProductFilterBarComponent,
        ValidationErrorsDirective,
        PreventScrollingDirective,
        ClickOutsideDirective,
        TSMaskedInputDirective,
        ArticleNumberAnonymizer,
        FormatAnonymizer,
        OnlyNumbersMask
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        NgChartsModule,
        NgbModule,
        NgxPaginationModule,
    ],
    providers: [],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        NgChartsModule,
        NgbModule,
        NgxPaginationModule,
        LoaderComponent,
        ProductFilterComponent,
        ProductFilterBarComponent,
        PreventScrollingDirective,
        ClickOutsideDirective,
        TSMaskedInputDirective,
        OnlyNumbersMask,
        ClickOutsideDirective,
        ValidationErrorsDirective,
        ArticleNumberAnonymizer,
        FormatAnonymizer,
    ]
})
export class SharedModule {
    constructor() {
        //
    }
}
