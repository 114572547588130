import { Article, ArticleUpdateables, CurrentArticleUpdateables } from './Article';

export enum CalculatorColumns {
    toggle = 'toggle',
    artikel = 'artikel',
    einkauf = 'einkauf',
    debitor = 'debitor',
    gewinn = 'gewinn',
    checks = 'checks',
}

export enum PriceMatrixColumns {
    artikel = 'artikel',

}
export enum CalculatorDirection {
    FORWARD = 'forward',
    BACKWARD = 'backward'
}

export enum CalculatorConstant {
    NONE = 'none',
    REVENUE = 'revenue'
}
export interface CalculatorFlags {
    direction: CalculatorDirection;
    constant: CalculatorConstant;
}

export interface ArticleWithOriginal extends Article {
    original: Article;
}

export const ArticleLimits: { [K in keyof ArticleUpdateables]?: [number | null, number | null] | [number] } = {
    ekPrice: [0.01],
    internalCostAbs: [0.01],
    revenue: [0.05, 0.25],
    grossMargin: [0.01],
    uvp: [0.01],
    debPriceNet: [0.01],
    debPriceGross: [0.01],
    calculationFactor: [2.3],
    uvpFactor: [1.7],
};

export type ArticleValidationError = {
    type: 'belowLimit' | 'aboveLimit';
    limit: number;
    value: number;
};

/**
 * A calculator should support only one mode of propagation.
 * If no new value ist supplied, the function should simply update all dependent values.
 */
export interface ArticleCalculator {
    getCurrentUpdateables(): CurrentArticleUpdateables;
    getReturnType(article: Article): 'flat' | 'discount';
    updateEkPrice(article: Article, newEkPrice?: number): void;
    updateCreditorSkonto(article: Article, newCreditorSkonto?: number): void;
    updateInternalCost(article: Article, newInternalCost?: number): void;
    updateRevenue(article: Article, newRevenue?: number): void;
    updateUVP(article: Article, newUVP?: number): void;
    updateDebitorSkonto(article: Article, newDebitorSkonto?: number): void;
    updateReturnFlatRate(article: Article, newReturnFlatRate?: number): void;
    updateReturnDiscount(article: Article, newReturnDiscount?: number): void;
    updateReturnRate(article: Article, newReturnRate?: number): void;
    updateDebRevenue(article: Article, newDebRevenue?: number): void;
    updateDebPriceNet(article: Article, newDebRevenue?: number): void;
    updateDebPriceGross(article: Article, newDebPriceGross?: number): void;
}

/**
 * "hacky" way to remove the floating point error. Should not be a problem at our scale.
 */
export function removeFloatingPointError(fp: number): number {
    return parseFloat((fp)?.toPrecision(4));
}

export function computeReturnRate(orders: number | undefined, returns: number | undefined): number {
    if ((typeof orders !== 'number') || Number.isNaN(orders) || (orders < 1)) {
        return 0;
    }
    if ((typeof returns !== 'number') || Number.isNaN(returns) || (returns < 1)) {
        return 0;
    }
    return returns / orders;
}

//@TODO: add returns, shipping, skonto, etc to all calculators

/**
 * some function handy for all calculators
 */
export class AbstractCalculator {

    public logs: boolean = false; //@TODO: quick impl for debugging. Implement properly or remove.

    protected info(...args) {
        if (this.logs) {
            console.info(...args);
        }
    }

    protected warn(...args) {
        console.warn(...args);
    }

    protected getReturnFactor(article: Article): number {
        if (article.returnRate) {
            return (1 - article.returnDiscount) * article.returnRate;
        } else {
            return article.returnFlatRate;
        }
    }

    public getReturnType(article: Article): 'flat' | 'discount' {
        if (article.returnRate) {
            return 'discount';
        } else {
            return 'flat';
        }
    }

    protected updateGrossMargin(article: Article): void {
        article.grossMargin = article.grossProfit - article.selfCost;
    }

    protected updateInternalCostAbs(article: Article): void {
        article.internalCostAbs = article.selfCost - article.ekPrice;
    }

    protected updateCalculationFactor(article: Article): void {
        article.calculationFactor = article.debPriceNet / article.ekPrice;
    }

    protected updateUVPFactor(article: Article): void {
        article.uvpFactor = article.uvp / article.debPriceNet;
    }

    public updateUVP(article: Article, newUVP: number): void {
        article.uvp = newUVP;
        this.updateUVPFactor(article);
    }

}

/**
 * propagates from ek price to debitor price.
 * NOTE: Is doing looped calculation to reflect connection between selfcost, revenue and debitor net.
 * It's the most complicated one...
 */
export class ForwardArticleCalculatorWithConstantRevenue extends AbstractCalculator implements ArticleCalculator {

    protected selfCostChanges: number = 0;
    protected maxSelfCostChanges: number = 100;

    protected toFixedNumber(val: number, frac = 2): number {
        return parseFloat(val.toFixed(frac));
    }

    public getCurrentUpdateables(): CurrentArticleUpdateables {
        return {
            ekPrice: true,
            creditorSkonto: true,
            internalCost: true,
            shippingCost: true,
            selfCost: false,
            revenue: true,
            grossProfit: false,
            uvp: false,
            debitorSkonto: true,
            returnFlatRate: true,
            returnDiscount: true,
            returnRate: true,
            debRevenue: true,
            debPriceNet: false,
            debPriceGross: false,
            calculationFactor: false,
            uvpFactor: false
        };
    }

    public updateEkPrice(article: Article, newEkPrice?: number): void {
        if (typeof newEkPrice === 'number') {
            article.ekPrice = newEkPrice;
        }
        this.updateCalculationFactor(article);
        this.updateSelfCost(article);
    }

    public updateCreditorSkonto(article: Article, newCreditorSkonto?: number): void {
        if (typeof newCreditorSkonto === 'number') {
            article.creditorSkonto = newCreditorSkonto;
        }
        this.updateSelfCost(article);
    }

    public updateInternalCost(article: Article, newInternalCost?: number): void {
        if (typeof newInternalCost === 'number') {
            article.internalCost = newInternalCost;
        }
        this.updateSelfCost(article);
    }

    /**
     * Self Cost is not changed directly
     * NOTE: Because debitor net and selfcost are connected, changig one of them causes a loop.
     * We stop updating selfCost, if the change is negligible.
     * As a last measure, we stop after a maximum ammount of interations
     * Because the class works "synced", we can use the class member like this.
     */
    protected updateSelfCost(article: Article): void {
        let newSelfCost = ((article.ekPrice * (1 - article.creditorSkonto)) + (article.debPriceNet * article.internalCost));
        let changed = this.toFixedNumber(newSelfCost) != this.toFixedNumber(article.selfCost);
        if (changed) {
            if (this.selfCostChanges < this.maxSelfCostChanges) {
                this.selfCostChanges++;
                article.selfCost = ((article.ekPrice * (1 - article.creditorSkonto)) + (article.debPriceNet * article.internalCost));
                this.updateInternalCostAbs(article);
                this.updateGrossMargin(article);
                this.afterSelfCostChange(article);
            } else {
                this.warn(`self cost loop was aborted after ${this.selfCostChanges} iterations, trying to update to ${newSelfCost}.`, article);
                this.selfCostChanges = 0;
                return;
            }
        } else {
            this.info(`self cost change to ${newSelfCost} is negligible after ${this.selfCostChanges} iterations.`, article);
            this.selfCostChanges = 0;
        }
    }

    /**
     * hook for different revenue handling
     */
    protected afterSelfCostChange(article: Article): void {
        this.updateGrossProfit(article); //revenue is constant if not changed directly. Jump to grossProfit.
    }

    public updateRevenue(article: Article, newRevenue?: number): void {
        if (typeof newRevenue === 'number') {
            article.revenue = newRevenue;
        }
        this.updateGrossProfit(article);
    }

    /**
     * grossProfit is not changed directly
     */
    protected updateGrossProfit(article: Article): void {
        // revenue is "grossRevenue" to debitor net
        //@TODO: check if this correctly integrates in the feedback loop. I guess that the numbers wont sum up after the last iteration
        article.grossProfit = (article.revenue * article.debPriceNet) + article.selfCost;
        this.updateGrossMargin(article);
        this.updateDebPriceNet(article); //revenue is constant
    }

    public updateReturnFlatRate(article: Article, newReturnFlatRate?: number): void {
        if (typeof newReturnFlatRate === 'number') {
            article.returnFlatRate = newReturnFlatRate;
        }
        this.updateDebPriceNet(article);
    }

    public updateReturnDiscount(article: Article, newReturnDiscount?: number): void {
        if (typeof newReturnDiscount === 'number') {
            article.returnDiscount = newReturnDiscount;
        }
        this.updateDebPriceNet(article);
    }

    public updateReturnRate(article: Article, newReturnRate?: number): void {
        if (typeof newReturnRate === 'number') {
            article.returnRate = newReturnRate;
        }
        this.updateDebPriceNet(article);
    }

    public updateDebitorSkonto(article: Article, newDebitorSkonto?: number): void {
        if (typeof newDebitorSkonto === 'number') {
            article.debitorSkonto = newDebitorSkonto;
        }
        this.updateDebPriceNet(article);
    }

    public updateDebRevenue(article: Article, newDebRevenue?: number): void {
        if (typeof newDebRevenue === 'number') {
            article.debRevenue = newDebRevenue;
        }
        this.updateDebPriceNet(article);
    }

    public updateDebPriceNet(article: Article, newDebPriceNet?: number): void {
        if (typeof newDebPriceNet === 'number') {
            console.warn('debPriceNet cannot be updated directly in forward mode.');
        }
        //@TODO: insert other debitor specific factors here
        //NOTE: gross profit is not the base value here. We consider it as the fraction of debitor netto using all relevant percents
        article.debPriceNet = article.grossProfit / (1 - article.debitorSkonto - article.debRevenue - this.getReturnFactor(article));
        this.updateCalculationFactor(article);
        this.updateUVPFactor(article);
        this.updateDebPriceGross(article);
        this.updateSelfCost(article); //as self cost is determined by net, we need to update it again
    }

    public updateDebPriceNetForMatrix(article: Article, newDebPriceNet?: number): void {
        if (typeof newDebPriceNet === 'number') {
            console.warn('debPriceNet cannot be updated directly in forward mode.');
        }
        //@TODO: insert other debitor specific factors here
        //NOTE: gross profit is not the base value here. We consider it as the fraction of debitor netto using all relevant percents
        article.debPriceNet = article.grossProfit / (1 - article.debitorSkonto - article.debRevenue - this.getReturnFactor(article));
    }

    public updateDebPriceGross(article: Article, newDebPriceGross?: number): void {
        if (typeof newDebPriceGross === 'number') {
            console.warn('debPriceGross cannot be updated directly in forward mode.');
        }
        article.debPriceGross = article.debPriceNet * 1.19;
    }

}

/**
 * propagates from ek price to revenue and stops there. If right of revenue is changed, behaves like ForwardArticleCalculatorWithConstantRevenue.
 */
export class ForwardArticleCalculatorWithRevenueChange extends ForwardArticleCalculatorWithConstantRevenue implements ArticleCalculator {

    /**
     * hook for different revenue handling
     */
    protected afterSelfCostChange(article: Article): void {
        this.updateRevenue(article);
    }

    public updateRevenue(article: Article, newRevenue?: number): void {
        if (typeof newRevenue === 'number') {
            //if changed directly, propagate change to deb prices through gross profit change like the normal forward calculator
            super.updateRevenue(article, newRevenue);
        } else {
            //self cost was updated but gross profit is constant. calculate new revenue percents
            // revenue is "grossRevenue" to debitor net
            //@TODO: are the values valid after one iteration?
            article.revenue = (article.grossProfit - article.selfCost) / article.debPriceNet;
            //stop here because selfcost change is consumed by revenue
            this.selfCostChanges = 0; // reset the loop counter as the debitor net won't change again
        }
    }
}

/**
 * a.k.a: Deckungsbeitragsrechnung
 * propagates from debitor price to revenue and stops there.
 */
export class BackwardArticleCalculatorWithRevenueChange extends AbstractCalculator implements ArticleCalculator {

    public getCurrentUpdateables(): CurrentArticleUpdateables {
        return {
            ekPrice: false,
            creditorSkonto: false,
            internalCost: false,
            shippingCost: false,
            selfCost: false,
            revenue: false,
            grossProfit: false,
            uvp: false,
            debitorSkonto: true,
            returnFlatRate: true,
            returnDiscount: true,
            returnRate: true,
            debRevenue: true,
            debPriceNet: true,
            debPriceGross: true,
            calculationFactor: false,
            uvpFactor: false
        };
    }

    public updateEkPrice(article: Article, newEkPrice?: number): void {
        if (typeof newEkPrice === 'number') {
            console.warn('ekPrice cannot be updated in backward mode.');
        }
    }

    public updateCreditorSkonto(article: Article, newCreditorSkonto?: number): void {
        if (typeof newCreditorSkonto === 'number') {
            console.warn('creditorSkonto cannot be updated in backward mode.');
        }
    }

    public updateInternalCost(article: Article, newInternalCost?: number): void {
        if (typeof newInternalCost === 'number') {
            console.warn('internalCost cannot be updated in backward mode.');
        }
    }

    /**
     * Self Cost is not changed directly
     */
    protected updateSelfCost(article: Article): void {
        article.selfCost = ((article.ekPrice * (1 - article.creditorSkonto)) + (article.debPriceNet * article.internalCost));
        this.updateInternalCostAbs(article);
        this.updateGrossMargin(article);
    }

    public updateRevenue(article: Article, newRevenue?: number): void {
        if (typeof newRevenue === 'number') {
            console.warn('revenue cannot be updated directly in backward mode.');
        }
        this.updateSelfCost(article); //update self cost to reflect change of debitor net. Recalculate revenue afterwards. No need to loop.
        article.revenue = (article.grossProfit - article.selfCost) / article.debPriceNet;
    }

    /**
     * gross profit is not changed directly
     */
    protected updateGrossProfit(article: Article): void {
        //NOTE: gross profit is a fraction of debitor netto price
        article.grossProfit = article.debPriceNet * (1 - article.debRevenue - article.debitorSkonto - this.getReturnFactor(article));
        this.updateGrossMargin(article);
        this.updateRevenue(article);
    }

    public updateReturnFlatRate(article: Article, newReturnFlatRate?: number): void {
        if (typeof newReturnFlatRate === 'number') {
            article.returnFlatRate = newReturnFlatRate;
        }
        this.updateGrossProfit(article);
    }

    public updateReturnDiscount(article: Article, newReturnDiscount?: number): void {
        if (typeof newReturnDiscount === 'number') {
            article.returnDiscount = newReturnDiscount;
        }
        this.updateGrossProfit(article);
    }

    public updateReturnRate(article: Article, newReturnRate?: number): void {
        if (typeof newReturnRate === 'number') {
            article.returnRate = newReturnRate;
        }
        this.updateGrossProfit(article);
    }

    public updateDebitorSkonto(article: Article, newDebitorSkonto?: number): void {
        if (typeof newDebitorSkonto === 'number') {
            article.debitorSkonto = newDebitorSkonto;
        }
        this.updateGrossProfit(article);
    }

    public updateDebRevenue(article: Article, newDebRevenue?: number): void {
        if (typeof newDebRevenue === 'number') {
            article.debRevenue = newDebRevenue;
        } else {
            //this is kind of special. For now we assume that the debitor revenue is constant and the gross profit is changed accordingly
        }
        this.updateGrossProfit(article);
    }

    public updateDebPriceNet(article: Article, newDebPriceNet?: number): void {
        if (typeof newDebPriceNet === 'number') {
            article.debPriceNet = newDebPriceNet;
            this.updateDebPriceGross(article);
            this.updateDebRevenue(article);
        } else {
            article.debPriceNet = article.debPriceGross / 1.19;
        }
        this.updateCalculationFactor(article);
        this.updateUVPFactor(article);
    }


    public updateDebPriceGross(article: Article, newDebPriceGross?: number): void {
        if (typeof newDebPriceGross === 'number') {
            article.debPriceGross = newDebPriceGross;
            this.updateDebPriceNet(article);
            this.updateDebRevenue(article);
        } else {
            article.debPriceGross = article.debPriceNet * 1.19;
        }
    }

}

function getStrategyForFlags(flags: CalculatorFlags): ArticleCalculator {
    switch (flags.direction) {
        case CalculatorDirection.FORWARD:
            return getForwardCalculatorFor(flags);
        case CalculatorDirection.BACKWARD:
            return getBackwardCalculatorFor(flags);
        default:
            throw new Error('No compatible calculator');
    }
}

function getForwardCalculatorFor(flags: CalculatorFlags): ArticleCalculator {
    switch (flags.constant) {
        case CalculatorConstant.NONE:
            return new ForwardArticleCalculatorWithRevenueChange();
        case CalculatorConstant.REVENUE:
            return new ForwardArticleCalculatorWithConstantRevenue();
        default:
            throw new Error('No compatible calculator');
    }
}

function getBackwardCalculatorFor(flags: CalculatorFlags): ArticleCalculator {
    switch (flags.constant) {
        case CalculatorConstant.NONE:
            return new BackwardArticleCalculatorWithRevenueChange();
        default:
            throw new Error('No compatible calculator');
    }
}

//@TODO: rethink data source and map
export class Calculator {
    protected articles: Map<string, ArticleWithOriginal>;
    protected articlesByKey: Map<keyof Article, Map<string | number, ArticleWithOriginal[]>>;
    protected calculationStrategy: ArticleCalculator;

    public logs: boolean = false; //@TODO: quick impl for debugging. Implement properly or remove.

    public static createId(articleNumber: string, debitorNumber: string): string {
        return articleNumber + '-' + debitorNumber;
    }

    constructor(initialFlags?: CalculatorFlags) {
        this.articles = new Map();
        this.articlesByKey = new Map();
        if (initialFlags) {
            this.setCalculationStrategyFlags(initialFlags);
        } else {
            this.setCalculationStrategyFlags({ direction: CalculatorDirection.BACKWARD, constant: CalculatorConstant.NONE });
        }
    }

    public getCurrentUpdateables(): CurrentArticleUpdateables {
        return this.calculationStrategy.getCurrentUpdateables();
    }

    public setCalculationStrategyFlags(flags: CalculatorFlags): void {
        this.calculationStrategy = getStrategyForFlags(flags);
        // tslint:disable-next-line: no-any
        (this.calculationStrategy as any).logs = this.logs;
    }

    protected saveOriginalArticles(articles: Article[]): void {
        for (let a of articles) {
            //IMPORTANT: if Article gets nested props in the future, make sure to clone them here too.
            let workingCopy = Object.assign({}, a);
            this.calculationStrategy.updateReturnRate(workingCopy, workingCopy.returnRateOrig);
            let orgConst = Object.assign({}, workingCopy);
            orgConst = Object.freeze(orgConst);
            this.calculationStrategy.updateReturnRate(workingCopy, computeReturnRate(workingCopy.debOrdersLast3Months, workingCopy.debReturnsLast3Months));
            let org3 = Object.assign({}, workingCopy);
            org3 = Object.freeze(org3);
            this.calculationStrategy.updateReturnRate(workingCopy, computeReturnRate(workingCopy.debOrdersLast6Months, workingCopy.debReturnsLast6Months));
            let org6 = Object.assign({}, workingCopy);
            org6 = Object.freeze(org6);
            //@TODO: is there a "typier" way to do this?
            // tslint:disable-next-line: no-string-literal
            a['original'] = org6;
            // tslint:disable-next-line: no-string-literal
            a['originalConst'] = orgConst;
            // tslint:disable-next-line: no-string-literal
            a['original3'] = org3;
            // tslint:disable-next-line: no-string-literal
            a['original6'] = org6;
            // console.log('article', a);
        }
    }

    public setArticles(articles: Article[], validate = true): void {
        this.articles = new Map();
        for (let a of articles) {
            if (validate) {
                this.checkForValidationErrors(a);
            }
            this.articles.set(a.id, a as ArticleWithOriginal);
        }
    }

    public createIndexMap(key: keyof Article) {
        const index = new Map();
        for (const [_, article] of this.articles) {
            const val = index.get(article[key]);
            if (val) {
                val.push(article);
            } else {
                index.set(article[key], [article]);
            }
        }
        this.articlesByKey.set(key, index);
        // console.info('index created for key:', key, index);
    }

    public getIndexMap(key: keyof Article, createIfNotExists = true) {
        if (createIfNotExists && !this.articlesByKey.has(key)) {
            this.createIndexMap(key);
        }
        return this.articlesByKey.get(key);
    }

    //NOTE: the following functions are not used to filter the UI. It looks up the data that should be changed in the currently loaded article set.
    public getAllArticles(): ArticleWithOriginal[] {
        return Array.from(this.articles.values());
    }

    public getArticleById(articleId: string): ArticleWithOriginal | undefined {
        return this.articles.get(articleId);
    }

    public getArticleByNumberAndDebitor(articleNumber: string, debitorNumber: string): ArticleWithOriginal | undefined {
        return this.articles.get(Calculator.createId(articleNumber, debitorNumber));
    }

    public getArticlesByNumber(articleNumber: string): ArticleWithOriginal[] {
        //deconstruct map values to iterator
        const index = this.getIndexMap('articleNumber');
        if (index) {
            return index.get(articleNumber) ?? [];
        }
        return [...this.articles.values()].filter(v => v.articleNumber == articleNumber);
    }

    public getArticlesByDebitor(debitorNumber: string): ArticleWithOriginal[] {
        //deconstruct map values to iterator
        const index = this.getIndexMap('debitorNumber');
        if (index) {
            return index.get(debitorNumber) ?? [];
        }
        return [...this.articles.values()].filter(v => v.debitorNumber == debitorNumber);
    }

    public getNullArticle(id: string, articleNumber: string, debitorName: string): Article {
        return {
            id: id,
            articleNumber: articleNumber,
            articleGroup: articleNumber.split('.')[0],
            ekPrice: 0,
            creditorSkonto: 0,
            internalCost: 0,
            internalCostAbs: 0,
            shippingCost: 0,
            selfCost: 0,
            revenue: 0,
            uvp: 0,
            grossProfit: 0,
            grossMargin: 0,
            debitorNumber: debitorName,
            debitorName: debitorName,
            debitorSkonto: 0,
            returnFlatRate: 0,
            returnDiscount: 0,
            returnRate: 0,
            debitorReturnType: 'flat',
            debRevenue: 0,
            debPriceNet: 0,
            debPriceGross: 0,
            calculationFactor: 0,
            uvpFactor: 0,
            articleProperties: {
                description: '-',
                colour: '-',
                height: 0,
                length: 0,
                width: 0,
            },
            debPriceByGroup: false,
            hasPrice: false,
            debPriceDebitor: 0,
            debPriceGroup: 0,
        };
    }

    public checkForValidationErrors(article: Article): false | ArticleValidationError[];
    public checkForValidationErrors(article: Article, property: keyof ArticleUpdateables): false | ArticleValidationError;
    public checkForValidationErrors(article: Article, property?: keyof ArticleUpdateables): false | ArticleValidationError | ArticleValidationError[] {
        if (property) {
            const limits = ArticleLimits[property];
            const value = article[property] as number;
            if (Array.isArray(limits)) {
                if (limits[0] != null && limits[0] > value) {
                    if (property != 'revenue') {
                        article.isValid = false;
                    } else if (property == 'revenue') {
                        article.isValidRevenue = false;
                    }
                    return {
                        type: 'belowLimit',
                        limit: limits[0],
                        value,
                    };
                }
                if (limits[1] != null && limits[1] < value) {
                    if (property != 'revenue') {
                        article.isValid = false;
                    } else if (property == 'revenue') {
                        article.isValidRevenue = false;
                    }
                    return {
                        type: 'aboveLimit',
                        limit: limits[1],
                        value,
                    };
                }
            }
        } else {
            const errors: ArticleValidationError[] = [];
            const errorsRevenue: ArticleValidationError[] = [];

            for (const p of Object.keys(ArticleLimits)) {
                if (p != 'revenue') {
                    const error = this.checkForValidationErrors(article, p as keyof ArticleUpdateables);
                    if (error) {
                        errors.push(error);
                    }
                } else if (p == 'revenue') {
                    const errorRevenue = this.checkForValidationErrors(article, p as keyof ArticleUpdateables);
                    if (errorRevenue) {
                        errorsRevenue.push(errorRevenue);
                    }
                }
            }
            if (errors.length > 0 && errorsRevenue.length > 0) {
                article.isValid = false;
                article.isValidRevenue = false;
                return errors.concat(errorsRevenue);
            } else if (errors.length <= 0 && errorsRevenue.length > 0) {
                article.isValidRevenue = false;
                return errorsRevenue;
            } else if (errors.length > 0 && errorsRevenue.length <= 0) {
                article.isValid = false;
                return errors;
            }
        }
        article.isValid = true;
        article.isValidRevenue = true;
        return false;
    }

    public updateEkPrice(articleNumber: string, newEkPrice: number): void {
        for (let article of this.getArticlesByNumber(articleNumber)) {
            this.calculationStrategy.updateEkPrice(article, Number(newEkPrice));
        }
    }


    public updateCreditorSkonto(articleNumber: string, newCreditorSkonto: number): void {
        for (let article of this.getArticlesByNumber(articleNumber)) {
            this.calculationStrategy.updateCreditorSkonto(article, newCreditorSkonto);
        }
    }

    public updateInternalCost(articleId: string, newInternalCost: number): void {
        let article = this.getArticleById(articleId);
        if (article) {
            this.calculationStrategy.updateInternalCost(article, newInternalCost);
        }
    }

    public updateInternalCostGlobal(debitorNumber: string, newReturnFlatRate: number): void {
        for (let article of this.getArticlesByDebitor(debitorNumber)) {
            this.calculationStrategy.updateInternalCost(article, newReturnFlatRate);
        }
    }

    public updateRevenue(articleId: string, newRevenue: number): void {
        let article = this.getArticleById(articleId);
        if (article) {
            this.calculationStrategy.updateRevenue(article, newRevenue);
        }
    }

    public updateReturnFlatRateGlobal(debitorNumber: string, newReturnFlatRate: number): void {
        for (let article of this.getArticlesByDebitor(debitorNumber)) {
            this.calculationStrategy.updateReturnFlatRate(article, newReturnFlatRate);
        }
    }

    public updateReturnFlatRate(articleId: string, newReturnFlatRate: number): void {
        let article = this.getArticleById(articleId);
        if (article) {
            this.calculationStrategy.updateReturnFlatRate(article, newReturnFlatRate);
        }
    }

    public updateReturnDiscountGlobal(debitorNumber: string, newReturnDiscount: number): void {
        for (let article of this.getArticlesByDebitor(debitorNumber)) {
            this.calculationStrategy.updateReturnDiscount(article, newReturnDiscount);
        }
    }

    public updateReturnDiscount(articleId: string, newReturnDiscount: number): void {
        let article = this.getArticleById(articleId);
        if (article) {
            this.calculationStrategy.updateReturnDiscount(article, newReturnDiscount);
        }
    }

    public updateReturnRateGlobal(debitorNumber: string, newReturnRate: number): void {
        for (let article of this.getArticlesByDebitor(debitorNumber)) {
            this.calculationStrategy.updateReturnRate(article, newReturnRate);
        }
    }

    public updateReturnRate(articleId: string, newReturnRate: number): void {
        let article = this.getArticleById(articleId);
        if (article) {
            this.calculationStrategy.updateReturnRate(article, newReturnRate);
        }
    }

    /*
    public updateEkPrice(article: Article, newEkPrice?: number): void {
        if (typeof newEkPrice === 'number') {
            article.ekPrice = newEkPrice;
        }
        this.updateCalculationFactor(article);
        this.updateSelfCost(article);
    }
    */

    public updateDebitorSkonto(articleId: string, newDebitorSkonto: number): void {
        let article = this.getArticleById(articleId);
        if (article) {
            this.calculationStrategy.updateDebitorSkonto(article, newDebitorSkonto);
        }
    }

    public updateDebitorSkontoGlobal(debitorNumber: string, newDebitorSkonto: number): void {
        for (let article of this.getArticlesByDebitor(debitorNumber)) {
            this.calculationStrategy.updateDebitorSkonto(article, newDebitorSkonto);
        }
    }

    public updateDebRevenue(articleId: string, newDebRevenue: number): void {
        let article = this.getArticleById(articleId);
        if (article) {
            this.calculationStrategy.updateDebRevenue(article, newDebRevenue);
        }
    }

    public updateDebitorRevenueGlobal(debitorNumber: string, newDebitorRevenue: number): void {
        for (let article of this.getArticlesByDebitor(debitorNumber)) {
            this.calculationStrategy.updateDebRevenue(article, newDebitorRevenue);
        }
    }

    public updateDebPriceNet(articleId: string, newDebPriceGross: number): void {
        let article = this.getArticleById(articleId);
        if (article) {
            this.calculationStrategy.updateDebPriceNet(article, newDebPriceGross);
        }
    }

    public updateDebPriceGross(articleId: string, newDebPriceGross: number): void {
        let article = this.getArticleById(articleId);
        if (article) {
            this.calculationStrategy.updateDebPriceGross(article, newDebPriceGross);
        }
    }
}
