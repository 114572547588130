import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/interfaces/api';
import { BiClient } from 'src/interfaces/bi-widget';
import { SimpleProduct } from 'src/interfaces/calculator/Product';
import { DataFilter } from 'src/interfaces/calculator/QueryLib';
import { ForecastListeArticle } from 'src/interfaces/forecast/Article';
import { ApiService } from './api.service';

@Injectable()
export class ForecastListService {

    constructor(private apiService: ApiService) { }

    public getForecast(client: BiClient, filter: DataFilter<SimpleProduct> = {}, startDate: Date, orderDate: Date, maxPeriods = 18): Observable<ApiResponse<ForecastListeArticle[]>> {
        return this.apiService.postWithParams<DataFilter<SimpleProduct>, ForecastListeArticle[]>(filter, {startDate: startDate.toISOString(), orderDate: orderDate.toISOString(), maxPeriods: maxPeriods.toString()}, `forecasts/list/${client}`);
    }
}
