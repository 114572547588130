/**
 * IMPORTANT: Roles are hierarchical according to their defined order in the enum declaration,
 * hence a Role may inherit all Roles below itself.
 */
export enum Role {
    ROOT,
    ADMIN,
    CALC_USER,
    FORECAST_USER,
    STOCKS_USER,
    DIST_USER,
    REPORT_USER,
    USER
}

export interface User {
    id?: number;
    username: string;
    firstname: string;
    lastname: string;
    mailaddress: string;
    active: boolean;
    role: Role;
    supplementalRoles?: Role[];
}

export enum FeaturePdf {
    'all' = 'all',
    'bi' = 'bi',
    'priceCalc' = 'priceCalc',
    'forecast' = 'forecast',
    'stock' = 'stock',
    'distribution' = 'distribution',
    'report' = 'report',
    'crawler' = 'crawler',
    'matrix' = 'matrix',
    'export' = 'export',
}
