import { Cube, createMeasure, createDimension, CubeDimensionType, CubeMeasureType } from './cube';

export enum PimCountMeasures {
    productCount = 'Pim.productCount'
}

export enum PimStringDimensions {
    id = 'Pim.id',
    name = 'Pim.name',
    type = 'Pim.type',
    mounting = 'Pim.mounting',
    material = 'Pim.material',
    brand = 'Pim.brand',
    color = 'Pim.color',
    width = 'Pim.width',
    length = 'Pim.length',
    shippingType = 'Pim.shippingType',
    producedIn = 'Pim.producedIn'
}

export const Pim: Cube<
    typeof PimCountMeasures,
    typeof PimStringDimensions
    // , {'test': 'test'}
    // , 'test'
>  = {
    sql: () => `SELECT * FROM pim`,
    measures: {
        productCount: createMeasure(CubeMeasureType.count, '*')
    },
    dimensions: {
        // test: createDimension(CubeDimensionType.time, 'test', true),
        id: createDimension(CubeDimensionType.string, 'id', true),
        name: createDimension(CubeDimensionType.string, 'name'),
        type: createDimension(CubeDimensionType.string, 'type'),
        mounting: createDimension(CubeDimensionType.string, 'mounting'),
        material: createDimension(CubeDimensionType.string, 'material'),
        brand: createDimension(CubeDimensionType.string, 'brand'),
        color: createDimension(CubeDimensionType.string, 'color'),
        width: createDimension(CubeDimensionType.string, 'width'),
        length: createDimension(CubeDimensionType.string, 'length'),
        shippingType: createDimension(CubeDimensionType.string, 'shippingType'),
        producedIn: createDimension(CubeDimensionType.string, 'producedIn')
    },
};
