import { removeFloatingPointError } from '../Calculator';
import { Check } from './Check';
import { CheckableArticleFixed, CheckableArticleTargets, CheckableArticleWithRef, PriceChecker, RegroupedCheckables } from './PriceChecker';

export class EqualCheck extends Check {

    protected regrouped: RegroupedCheckables;

    constructor(protected articles: CheckableArticleWithRef[], protected fixedFields: (keyof CheckableArticleFixed)[], protected groupField: keyof CheckableArticleFixed, protected targetFields: (keyof CheckableArticleTargets)[]) {
        super();
        this.regrouped = PriceChecker.regroup(articles, ...fixedFields);
    }

    public check(): void {
        //make sure that targetFields were reloaded via PriceChecker before checking
        for (let targetField of this.targetFields) {
            this.compareAll(targetField);
        }
    }

    protected compareAll(targetField: keyof CheckableArticleTargets) {
        PriceChecker.applyToGroups(this.regrouped, a => {
            this.compare(a, targetField);
        });
    }

    protected compare(ceckables: CheckableArticleWithRef[], targetField: keyof CheckableArticleTargets) {
        const foundTargetValues: Map<number | string, CheckableArticleWithRef[]> = new Map();
        //put all checkables in groups by their target values
        for (let c of ceckables) {
            let val = removeFloatingPointError(c[targetField]);
            if (!foundTargetValues.has(val)) {
                foundTargetValues.set(val, []);
            }
            foundTargetValues.get(val)!.push(c);
        }
        //the first encountered article in the bigges group is the reference article
        const groups = Array.from(foundTargetValues.values());
        groups.sort((a, b) => { return a.length - b.length; });
        const refArticle = groups.pop()!.shift()!;
        //remaining articles in different groups differ from ref articles and are problematic
        for (let group of groups) {
            for (let c of group) {
                PriceChecker.addCheckResult(c, 'notEqual', this.fixedFields, this.groupField, targetField, refArticle);
            }
        }
    }
}
