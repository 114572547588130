import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './../core/service/login.service';
import { BiClient } from 'src/interfaces/bi-widget';
import { state } from 'src/state/state';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'tool-layout',
    templateUrl: './tool-layout.component.html',
    styleUrls: []
})
export class ToolLayoutComponent {

    constructor(
        private router: Router,
        public loginService: LoginService,
        @Inject(DOCUMENT) private document: Document
    ) {
        const mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
        if (!mac) {
            this.document.body.classList.add('extrapadding');
        }
        this.document.body.classList.add('lichtblick');
    }

    get currentRoute() {
        return this.router.routerState?.snapshot?.url.split('/').slice(1) ?? [''];
    }

    get client() {
        return state.cube;
    }

    set client(client: BiClient) {
        //@TODO: this could be cleaner
        this.router.navigate([this.currentRoute[0], client], { skipLocationChange: false });
    }

    public setClient($event) {
        this.client = $event.target.value;
    }

    public getClient() {
        // return '/' + this.client;
        return '';
    }

    public canGoBack(): boolean {
        return this.currentRoute[0] !== 'bi' || ['dashboard', 'WohnGuide', 'Roomy', 'Lichtblick'].indexOf(this.currentRoute[1]) < 0;
    }

    public canSelectClient(): boolean {
        return this.currentRoute[0] !== 'user' && (this.currentRoute[0] !== 'bi' || this.currentRoute[1] !== 'details') && (this.currentRoute[0] !== 'bi' || this.currentRoute[1] !== 'notificationDetails') ;
    }

    public logout() {
        this.loginService.logout();
    }

}
