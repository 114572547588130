import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'src/interfaces/api';
import { BiClient } from 'src/interfaces/bi-widget';
import { OOSPeriod, OOSResponse, SimpleProduct, SimpleProductWithStockData } from 'src/interfaces/calculator/Product';
import { DataFilter } from 'src/interfaces/calculator/QueryLib';
import { AdditionalConsumptionItem, ArticleForecast, ArticleForecastWithStockData} from 'src/interfaces/forecast/Article';
import { ApiService } from './api.service';

@Injectable()
export class ForecastDataService {

    constructor(private apiService: ApiService) { }

    //NOTE: filter by "product", but gets "article". It is distinguished to save data transmission. See transform functions in forecast service.
    public getForecasts(client: BiClient, filter: DataFilter<SimpleProduct> = {}, maxPeriods: number = 6, model: string = 'forecast'): Observable<ArticleForecast[]> {
        return this.apiService.postWithParams<DataFilter<SimpleProduct>, ArticleForecast[]>(filter, { maxPeriods: maxPeriods.toString(10), model: model }, 'forecasts/' + client).pipe(map(r => r.data ?? []));
    }

    public getForecastsWithStockData(client: BiClient, filter: DataFilter<SimpleProductWithStockData> = {}, depth = 60, maxPeriods: number = 6, model: string = 'forecast'): Observable<ArticleForecastWithStockData[]> {
        return this.apiService.postWithParams<DataFilter<SimpleProductWithStockData>, ArticleForecastWithStockData[]>(filter, { maxPeriods: maxPeriods.toString(10), model: model, depth: depth.toString(10) }, 'stocks/' + client).pipe(map(r => r.data ?? []));
    }

    public async saveConsumptions(client: BiClient, data: AdditionalConsumptionItem[]) {
        try {
            const res = await this.apiService.postWithParams<AdditionalConsumptionItem[], ApiResponse<boolean>>(data, undefined, 'consumptions/save/' + client).toPromise();
            console.info('saveConsumptions', data, res);
            if (res && res.data) {
                return res.data;
            }
            return undefined;
        } catch (error) {
            console.error(error);
        }
    }

    public async deleteConsumptions(client: BiClient, data: AdditionalConsumptionItem[]) {
        try {
            const res = await this.apiService.postWithParams<AdditionalConsumptionItem[], ApiResponse<boolean>>(data, undefined, 'consumptions/delete/' + client).toPromise();
            console.info('saveConsumptions', data, res);
            if (res && res.data) {
                return res.data;
            }
            return undefined;
        } catch (error) {
            console.error(error);
        }
    }

    public getOOSPeriotes(client: BiClient, articleNr: string): Observable<OOSResponse> {
        return this.apiService.post<{articleNr: string}, OOSResponse>({articleNr}, 'forecasts/oos/' + client).pipe(map(r => r.data ?? {oosValue: undefined, oosPeriods: []}));
    }
}
