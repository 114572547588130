import { Injectable } from '@angular/core';
import { LBPriceChecker } from 'src/interfaces/calculator/pricechecker/LBPriceChecker';

//wrap the shared price checker class to be a singleton
@Injectable({
    providedIn: 'root',
})
export class PriceCheckerService extends LBPriceChecker {
    //
}
