import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, MySqlResultSetHeader } from 'src/interfaces/api';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {

    protected apiUrl = environment.apiUrl;
    protected opts: Parameters<HttpClient['get']>[1] = {
        withCredentials: true
    };

    constructor(private http: HttpClient) {
        console.log('API', this.apiUrl);
    }

    // @TODO: refactor to native Observable in order to enable request cancellation
    public get<T>(...segments: Array<string | number | boolean>) {
        return this.http.get<ApiResponse<T>>(this.apiUrl + segments.join('/'), this.opts).toPromise();
    }
    // @TODO: refactor to native Observable in order to enable request cancellation
    public getWithParams<T>( params?: { [param: string]: string }, ...segments: Array<string | number | boolean>) {
        let opts = Object.assign({ params: params}, this.opts);
        return this.http.get<ApiResponse<T>>(this.apiUrl + segments.join('/'), opts).toPromise();
    }

    public post<T, R = MySqlResultSetHeader>(body: T, ...segments: Array<string | number | boolean>) {
        return this.http.post<ApiResponse<R>>(this.apiUrl + segments.join('/'), body, this.opts);
    }

    //@TODO: recosinder
    public postWithParams<T, R = MySqlResultSetHeader>(body: T, params?: { [param: string]: string }, ...segments: Array<string | number | boolean>) {
        let opts = Object.assign({ params: params}, this.opts);
        console.log('URL', this.apiUrl + segments.join('/'));
        return this.http.post<ApiResponse<R>>(this.apiUrl + segments.join('/'), body, opts);
    }
}
