import { BiMeasures, BiDimensions, BiMembers, BiClient } from 'src/interfaces/bi-widget';
import { BiTimeDimensions } from 'src/interfaces/wohnguide-cube';
import { state } from 'src/state/state';
import { ChartPivotRow as _ChartPivotRow, SeriesNamesColumn as _SeriesNamesColumn, PivotConfig, ResultSet, LoadResponseResult as _LoadResponseResult, LoadResponse as _LoadResponse, PivotQuery, Annotation, TimeDimensionBase, TimeDimensionComparisonFields, TimeDimensionRangedFields, Query } from '@cubejs-client/core';
import { CubeGranularity, CubeOperatorBinary, CubeOperatorUnary } from 'src/interfaces/cube';

export function cubizeBiMember<T extends BiMembers>(member: T, reverse = false, cube: BiClient = state.cube): T {
    const regex = new RegExp(`${cube}`);
    if (reverse) {
        return member.replace(/\[BI\]/, cube) as T;
    } else {
        return member.replace(regex, '[BI]') as T;
    }
}

export function isCubized<T extends BiMembers>(member: T, reverse = false, cube: BiClient = state.cube): boolean {
    if (reverse) {
        return member.indexOf('[BI]') >= 0;
    } else {
        return member.indexOf(cube) >= 0;
    }
}

export function cubizeResultSet(result: ResultSet, cube: BiClient = state.cube): BiResultSet {
    let bi = result as unknown as BiResultSet;
    if (bi.loadResponse && bi.loadResponse.results[0]) {
        let res = bi.loadResponse.results[0];
        if (bi.loadResponse.pivotQuery) {
            bi.loadResponse.pivotQuery = prepareCubeQuery(bi.loadResponse.pivotQuery, false);
        }
        // console.log('##### cubizeResultSet', bi);
        if (res.query) {
            res.query = prepareCubeQuery(res.query, false);
        }
        if (res.annotation) {
            for (const anno of Object.values(res.annotation) as Record<BiMembers, Annotation>[]) {
                for (const d of Object.keys(anno) as BiDimensions[]) {
                    if (isCubized(d, undefined, cube)) {
                        anno[cubizeBiMember(d, undefined, cube)] = anno[d];
                        delete anno[d];
                    }
                }
            }
        }
        if (Array.isArray(res.data)) {
            for (const r of res.data) {
                for (const k of Object.keys(r) as BiMembers[]) {
                    if (isCubized(k, undefined, cube)) {
                        // @TODO: how to avoid typecasting gere
                        r[cubizeBiMember(k, undefined, cube) as string] = r[k];
                        delete r[k];
                    }
                }
            }
        }
    }
    return bi as BiResultSet;
}

export function prepareCubeQuery<T extends BiQuery | BiQuery & PivotQuery>(query: T, reverse = true, cube: BiClient = state.cube) {
    const prepared: T = { ...query };
    if (Array.isArray(query.measures)) {
        prepared.measures = query.measures.map(m => cubizeBiMember(m, reverse, cube));
    }
    if (Array.isArray(query.dimensions)) {
        prepared.dimensions = query.dimensions.map(d => cubizeBiMember(d, reverse, cube));
    }
    if (Array.isArray(query.timeDimensions)) {
        prepared.timeDimensions = query.timeDimensions.map(k => ({ ...k, dimension: cubizeBiMember(k.dimension, reverse, cube) }));
    }
    if (Array.isArray(query.segments)) {
        prepared.segments = query.segments.map(d => cubizeBiMember(d as BiDimensions, reverse, cube));
    }
    if (Array.isArray(query.filters)) {
        prepared.filters = query.filters.map(k => ({ ...k, member: cubizeBiMember(k.member, reverse, cube) }));
    }
    if (query.order) {
        if (Array.isArray(query.order)) {
            // IMPORTANT: internal order interface seems to be in complete disalignment with public interface, so we have to handle it here
            prepared.order = (query.order as unknown as Array<{ id: BiMembers, desc: boolean }>).map(o => ({ id: cubizeBiMember(o.id, reverse, cube), desc: o.desc })) as unknown;
        } else {
            prepared.order = {};
            for (const k of Object.keys(query.order) as BiMembers[]) {
                // console.log('k', k, isCubized(k, reverse, cube), isCubized(k, undefined, cube));
                if (isCubized(k, reverse, cube)) {
                    prepared.order[cubizeBiMember(k, reverse, cube)] = query.order[k];
                }
            }
        }
        // console.log('OOOORDER', query.order, prepared.order);
    }
    return prepared;
}

export type BiQuery = CubeQuery<BiMeasures, BiDimensions, BiTimeDimensions>;
export type PairBiQuery = {
    first: BiQuery,
    second: BiQuery
};

export type BiQueryTimeDimension = QueryTimeDimension<BiTimeDimensions>;
export type BiQueryFilter = QueryBinaryFilter<BiDimensions> | QueryUnaryFilter<BiDimensions>;
export type BiResultSet = CubeResultSet<BiMeasures, BiDimensions, BiTimeDimensions>;
export type BiLoadResponseResult = LoadResponseResult<BiMeasures, BiDimensions, BiTimeDimensions>;
export type BiLoadResponse = LoadResponse<BiMeasures, BiDimensions, BiTimeDimensions>;

export type BiTablePivotRow = TablePivotRow<BiMeasures, BiDimensions>;
export type BiChartPivotRow = ChartPivotRow<BiMeasures, BiDimensions>;
export type BiAggregatedTablePivotRow = AggregatedTablePivotRow<BiMeasures, BiDimensions>;
export type BiSeriesNamesColumn = SeriesNamesColumn<BiMeasures, BiDimensions>;


// the following generic types are used instead of the non-generic ones that come with the cube.js client in order to preserve our bi types

export interface CubeResultSet<
    M extends string = string,
    D extends string = string,
    T extends string = D,
    S extends string = string> extends ResultSet {
    loadResponse: LoadResponse<M, D, T, S>;
    chartPivot(config?: PivotConfig): ChartPivotRow<M, D>[];
    tablePivot(config?: PivotConfig): TablePivotRow<M, D>[];
    seriesNames(config?: PivotConfig): SeriesNamesColumn<M, D>[];
}


export interface LoadResponse<
    M extends string = string,
    D extends string = string,
    T extends string = D,
    S extends string = string> extends _LoadResponse<TablePivotRow<M, D>> {
    pivotQuery: CubeQuery<M, D, T, S> & PivotQuery;
    results: LoadResponseResult<M, D, T, S>[];
}

export interface LoadResponseResult<
    M extends string = string,
    D extends string = string,
    T extends string = D,
    S extends string = string> extends _LoadResponseResult<TablePivotRow<M, D>> {
    annotation: {
        measures: Record<M, Annotation>,
        dimensions: Record<D, Annotation>,
        timeDimensions: Record<T, Annotation>,
    };
    data: TablePivotRow<M, D | T>[];
    query: CubeQuery<M, D, T, S>;
}

export type TablePivotRow<M extends string, D extends string> = { [K in M]?: number } & { [K in D]?: string };

export type AggregatedTablePivotRow<M extends string, D extends string> = TablePivotRow<M, D> & {
    nestedRows: TablePivotRow<M, D>[];
};

export type ChartPivotRow<M extends string, D extends string> = _ChartPivotRow & TablePivotRow<M, D> & {
    x: string,
};

export interface SeriesNamesColumn<M extends string, D extends string> extends _SeriesNamesColumn {
    key: M  & D;
}
export interface CubeQuery<
    M extends string = string,
    D extends string = string,
    T extends string = D,
    S extends string = string> extends Query {
    measures: M[];
    dimensions?: (D | T)[];
    timeDimensions?: QueryTimeDimension<T>[];
    segments?: S[];
    filters?: QueryFilter<M | D | T>[];
    order?: QueryOrder<M | D | T>;
}

export interface QueryTimeDimensionBase<T extends string> extends TimeDimensionBase {
    dimension: T;
    granularity?: keyof typeof CubeGranularity | null;
}

export type QueryTimeDimensionComparison<T extends string> = QueryTimeDimensionBase<T> & TimeDimensionComparisonFields;

export type QueryTimeDimensionRanged<T extends string> = QueryTimeDimensionBase<T> & TimeDimensionRangedFields;

export type QueryTimeDimension<T extends string> = QueryTimeDimensionComparison<T> | QueryTimeDimensionRanged<T>;

// @TODO: implement handling of logical and/or filters if needed
export type QueryFilter<T> = QueryBinaryFilter<T> | QueryUnaryFilter<T>; // | QueryLogicalOrFilter<T> | QueryLogicalAndFilter<T>;
export type QueryLogicalAndFilter<T> = {
    and: (QueryBinaryFilter<T> | QueryUnaryFilter<T> | QueryLogicalOrFilter<T>)[]
};

export type QueryLogicalOrFilter<T> = {
    or: (QueryBinaryFilter<T> | QueryUnaryFilter<T> | QueryLogicalAndFilter<T>)[]
};

export type QueryBinaryFilter<T> = {
    dimension?: T;
    member?: T;
    operator: CubeOperatorBinary;
    values: string[];
};

export type QueryUnaryFilter<T> = {
    dimension?: T;
    member?: T;
    operator: CubeOperatorUnary;
    values?: never;
};

export type QueryOrder<T extends string> = {
    [K in T]?: 'asc' | 'desc'
};


