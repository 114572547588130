import { Injectable } from '@angular/core';
import { BiClient } from 'src/interfaces/bi-widget';
import { Product } from 'src/interfaces/calculator/Product';
import { ProductPurchase, PurchaseInconsistency } from 'src/interfaces/forecast/ProductPurchase';
import { ApiService } from './api.service';

@Injectable()
export class ProductPurchaseService {

    constructor(private apiService: ApiService) { }

    public async getOpenPurchases(client: BiClient): Promise<(ProductPurchase & Partial<Product>)[]> {
        try {
            const res = await this.apiService.get<(ProductPurchase & Partial<Product>)[]>(`open-purchases/${client}`);
            if (res && res.data) {
                return res.data;
            }
            return undefined;
        } catch (error) {
            console.error(error);
        }
    }

    public async getInconsistencies(client: BiClient): Promise<PurchaseInconsistency[]> {
        try {
            const res = await this.apiService.get<PurchaseInconsistency[]>(`inconsistencies/${client}`);
            if (res && res.data) {
                return res.data;
            }
            return undefined;
        } catch (error) {
            console.error(error);
        }
    }
}
