import moment, { Moment } from 'moment';
import { environment } from 'src/environments/environment';

export function getCurrentDate(): Date {
    return getCurrentMoment().toDate();
}

export function getCurrentMoment(): Moment {
    if (environment.useDefinedDate === true && environment.definedDate !== undefined) {
        return moment.utc(environment.definedDate + '.12', 'DD.MM.YYYY.HH');
    }
    return moment.utc();
}
