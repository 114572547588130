import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { state } from 'src/state/state';
import { ApiService } from './api.service';
import { getCurrentMoment } from './date.service';

type GitlabPipelineStatus = {
    id: number;
    created_at: Date | string;
    started_at: Date | string;
    updated_at: Date | string;
    status: string;
    duration: number;
}

@Injectable()
export class StockImportService {
    private triggering: boolean = false;
    private importStatus: GitlabPipelineStatus | null;
    private fetchStatusTimeout: NodeJS.Timeout;
    private readonly fetchStatusInterval = 2; // in seconds
    private readonly activeStatus = ['created', 'pending', 'running'];
    private readonly triggerToken = 'glptt-48ef542bccb75a39d5bf2a943c12f8249d882ae9';
    private readonly statusToken = 'glpat-zrzaPWpfwCyvgCcfxare';
    private readonly triggerUrl = 'https://code.sma-dev.de/api/v4/projects/869/trigger/pipeline';
    private readonly statusUrl = 'https://code.sma-dev.de/api/v4/projects/869/pipelines/';

    private today: Date;

    public lastLastImportDate: Date | null = null;
    public lastImportDate: Date | null = null;

    public importFinished: Subject<{ success: boolean }> = new Subject();

    constructor(private httpClient: HttpClient,
        private apiService: ApiService) {
        this.today = getCurrentMoment().startOf('day').toDate();
        this.fetchLastImportDate();
    }

    private isValidPipelineStatus(obj: object): obj is GitlabPipelineStatus {
        return !!(obj as GitlabPipelineStatus).id && !!(obj as GitlabPipelineStatus).status;
    }

    public get importIsSuccessfull() {
        return this.importStatus?.status === 'success';
    }

    public get importIsActive() {
        return this.triggering || (this.importStatus?.status && this.activeStatus.indexOf(this.importStatus.status) > -1);
    }

    public async triggerStockImport() {
        this.triggering = true;
        console.log('triggerStockImport');
        const params = {
            token: this.triggerToken,
            ref: environment.production ? 'master' : 'develop',
            'variables[SCHEDULE]': 'import-stock',
            'variables[ENVIRONMENT]': environment.env,
        };
        const res = await this.httpClient.post(this.triggerUrl, {}, { params }).toPromise();
        if (this.isValidPipelineStatus(res)) {
            this.importStatus = res;
        }
        this.triggering = false;
        console.log('pipeline trigger response', res);
        if (this.importIsActive) {
            this.fetchStockImportStatus(this.fetchStatusInterval);
        }
    }

    public async fetchStockImportStatus(interval?: number) {
        if (this.fetchStatusTimeout) {
            clearTimeout(this.fetchStatusTimeout);
        }
        if (this.importStatus) {
            console.log('fetchStockImportStatus');
            const headers = {
                'PRIVATE-TOKEN': this.statusToken,
            };
            const res = await this.httpClient.get(this.statusUrl + this.importStatus.id, { headers }).toPromise();
            console.log('pipeline status response', res);
            if (this.isValidPipelineStatus(res)) {
                this.importStatus = res;
            }
            if (this.importIsActive) {
                if (interval != null) {
                    this.fetchStatusTimeout = setTimeout(() => {
                        this.fetchStockImportStatus(interval);
                    }, interval * 1000);
                }
            } else {
                this.fetchLastImportDate();
                this.importFinished.next({ success: this.importIsSuccessfull });
            }
        }
    }

    public get lastImportIsFromToday() {
        return this.lastImportDate >= this.today;
    }

    private async fetchLastImportDate() {
        const res = await this.apiService.get('last-stock-import/' + state.cube);
        if (this.lastImportDate) {
            this.lastLastImportDate = this.lastImportDate;
        }
        this.lastImportDate = moment.utc(res.data).toDate();
    }
}
