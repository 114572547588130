<div class="login grid grid__col__2">
    <div></div>
    <div class="center__center">
        <div class="login__mask">
            <div class="login__header">
                <img src="assets/castly-logo.png"/>
            </div>
            <div class="login__body">
                <h2>Login</h2>
                <form *ngIf="form" [formGroup]="form">
                    <div class="form-row">
                        <div class="col">
                            <label>Benutzername: *</label>
                            <input [formControl]="form.controls.username" type="text" class="form-control">
                            <div class="invalid-feedback" controlName="username" appValidationErrors></div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <label>Passwort: *</label>
                            <input [formControl]="form.controls.password" type="password" class="form-control">
                            <div class="invalid-feedback" controlName="password" appValidationErrors></div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <label>Mandant:</label>
                            <select [formControl]="form.controls.client" class="form-control" appValidationErrors>
                                <option value="WohnGuide">SMA-Dev</option>
                            </select>
                            <div class="invalid-feedback" controlName="client" appValidationErrors></div>
                        </div>
                    </div>
                    <div class="t__right f__xs">* Pflichtfelder</div>
                    <div *ngIf="error" class="form-row">
                        <div class="col form__error">
                            {{ error }}
                        </div>
                    </div>
                    <div class="form-row" style="margin-top: 20px;">
                        <div class="col">
                            <button [disabled]="!form.valid" (click)="login()">
                                <span *ngIf="authenticating; else loginButtonText" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="margin-bottom: 3px;"></span>
                                <ng-template #loginButtonText>Einloggen</ng-template>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
