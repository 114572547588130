import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAno'
})

//| formatAno
export class FormatAnonymizer implements PipeTransform {

  public transform(value: string): string {
    if (!value) {
       return value;
    }
    if (value.toLocaleLowerCase() == 'lichtblick') {
      return 'MEINSHOP';
    }
    return value;
  }
}
