import { Article } from '../Article';
import { EqualCheck } from './EqualCheck';
import { LinearCheck } from './LinearCheck';
import { PriceChecker } from './PriceChecker';

//@TODO: make stuff configurable if need arises
export class LBPriceChecker extends PriceChecker {

    constructor() {
        super();
    }

    /**
     * Call this if loaded articles changed or fixed fields may have changes.
     */
    public setupCheckersForArticles(articles: Article[]): void {
        this.setArticles(articles);
        this.checks = [];
        this.checks.push(
            new LinearCheck(this.allCheckables, ['articleGroup', 'debitor', 'width', 'color'], 'heigth', ['ekPrice', 'uvp', 'debPriceNet']),
            new LinearCheck(this.allCheckables, ['articleGroup', 'debitor', 'heigth', 'color'], 'width', ['ekPrice', 'uvp', 'debPriceNet']),
            new EqualCheck (this.allCheckables, ['articleGroup', 'debitor', 'width', 'heigth'], 'color', ['ekPrice', 'uvp', 'debPriceNet']),
        );
    }

}
