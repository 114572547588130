import moment from 'moment';
import { NotificationCRNF, NotificationConfig, NotificationType, NotificationTypeLink, NotificationTotalOOS, NotificationWHOOS, Notifications, NotificationIOI, NotificationPC, NotificationCR, NotificationPM, NotificationSP, NotificationLBSPC, NotificationFOA } from './notification';
import { WarehouseNames } from '../forecast/Article';

export type DisplayNotification = {
    text: string;
    linkQueryParams: {};
    notificationsArray: Notifications
};

export type NotificationTextGenerator = NotificationTextGeneratorTotalOOS | NotificationTextGeneratorWHOOS | NotificationTextGeneratorCRNF;

export type NotificationTextGeneratorsPick<T extends NotificationType | undefined = undefined> =
    T extends NotificationType.TotalOOS ? NotificationTextGeneratorTotalOOS :
    T extends NotificationType.WHOOS ? NotificationTextGeneratorWHOOS :
    T extends NotificationType.CRNF ? NotificationTextGeneratorCRNF : NotificationTextGenerator;

export class NotificationTextGeneratorMap extends Map {
    public get<K extends NotificationType>(value: K): NotificationTextGeneratorsPick<K> | undefined {
        return super.get(value) as unknown as NotificationTextGeneratorsPick<K> | undefined;
    }
}

export class NotificationTextGeneratorTotalOOS {
    public notificationType = NotificationType.TotalOOS;

    constructor() {
        //
    }

    public getRoute(notification: NotificationTotalOOS) {
        return NotificationTypeLink[this.notificationType];
    }
    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationTotalOOS) {
        return { articleNr: notification.articleNr };
    }

    public getText(notiConf: NotificationConfig, notification: NotificationTotalOOS) {
        return `Totales OOS: Artikel ${notification.articleNr} geht laut Forecast am ${moment(notification.totalOOS).format('DD.MM.YYYY')} OOS`;
    }

    public getTexts(notiConf: NotificationConfig, notification: NotificationTotalOOS[]) {
        let resultArray: DisplayNotification[] = [];
        for (let noti of notification) {
            let linkQueryParams = { articleNr: noti.articleNr };
            let text = `Artikel ${noti.articleNr} OOS am ${moment(noti.totalOOS).format('DD.MM.YYYY')}`;
            resultArray.push({ linkQueryParams, text, notificationsArray: noti });
        }
        return resultArray;
    }
}

export class NotificationTextGeneratorWHOOS {
    public notificationType = NotificationType.WHOOS;

    constructor() {
        //
    }

    public getRoute(notification: NotificationWHOOS) {
        return NotificationTypeLink[this.notificationType];
    }

    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationWHOOS) {
        return { articleNr: notification.articleNr, warehouse: notification.warehouse };
    }

    public getText(notiConf: NotificationConfig, notification: NotificationWHOOS) {
        return `Lager OOS: Artikel ${notification.articleNr} im Lager ${WarehouseNames[notification.warehouse]} droht zum ${moment(notification.wHOOS).format('DD.MM.YYYY')} OOS zu gehen`;
    }

    public getTexts(notiConf: NotificationConfig, notification: NotificationWHOOS[]) {
        let resultArray: DisplayNotification[] = [];
        for (let noti of notification) {
            let linkQueryParams = { articleNr: noti.articleNr };
            let text = `Artikel ${noti.articleNr} OOS am ${moment(noti.warehouse).format('DD.MM.YYYY')}`;
            resultArray.push({ linkQueryParams, text, notificationsArray: noti });
        }
        return resultArray;
    }
}

export class NotificationTextGeneratorCRNF {
    public notificationType = NotificationType.CRNF;

    constructor() {
        //
    }

    public getRoute(notification: NotificationCRNF) {
        if (notification.gotReported === true && notification.gotCrawled === false) {
            // Was besseres ausdenken
            return 'reports';
            // return NotificationTypeLink[this.notificationType + '2'];
        }
        if (notification.gotReported === true && notification.gotCrawled === true && notification.foundArticleOnWebsite === false) {
            return NotificationTypeLink[this.notificationType];
        }
        return NotificationTypeLink[this.notificationType];
    }

    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationCRNF) {
        if (notification.gotReported === true && notification.gotCrawled === false) {
            return { articleNr: notification.articleNr };
        }
        if (notification.gotReported === true && notification.gotCrawled === true && notification.foundArticleOnWebsite === false) {
            return { articleNr: notification.articleNr, debitor: notification.debitor };
        }
        return { articleNr: notification.articleNr };
    }

    public getText(notiConf: NotificationConfig, notification: NotificationCRNF) {
        if (notification.gotReported === true && notification.gotCrawled === false) {
            return `Artikel nicht gecrawlt: ${notification.articleNr} ist in der Bestandsmeldung für ${notification.debitor} am ${moment(notification.reportedDate).format('DD.MM.YYYY')} aber konnte nicht gecrawled werden.`;
        }
        if (notification.gotReported === true && notification.gotCrawled === true && notification.foundArticleOnWebsite === false) {
            return `Artikel nicht verfügbar: ${notification.articleNr} wurde gefunden aber er ist nicht verfügbar.`;
        }
    }

    // public getTexts(notiConf: NotificationConfig, notification: NotificationCRNF[]) {
    //     let resultArray: DisplayNotification[] = [];
    //     for (let noti of notification) {
    //         let linkQueryParams = { articleNr: noti.articleNr };
    //         let text = `Artikel ${noti.articleNr} OOS am ${moment(noti.warehouse).format('DD.MM.YYYY')}`;
    //         resultArray.push({ linkQueryParams, text, notificationsArray: noti });
    //     }
    //     return resultArray;
    // }
}

export class NotificationTextGeneratorIOI {
    public notificationType = NotificationType.IOI;

    constructor() {
        //
    }

    public getRoute(notification: NotificationIOI) {
        return NotificationTypeLink[this.notificationType];
    }

    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationIOI) {
        return { articleNr: notification.articleNr };
    }

    public getText(notiConf: NotificationConfig, notification: NotificationIOI) {
        return `Offene Bestellung: In Bestellung ${notification.orderNr} von ${notification.kreditor} sind noch ${notification.quantityOrdered} von insgesamt ${notification.quantityOutstanding} offen.`;
    }
}
export class NotificationTextGeneratorCR {
    public notificationType = NotificationType.CR;

    constructor() {
        //
    }

    public getRoute(notification: NotificationCR) {
        return NotificationTypeLink[this.notificationType];
    }

    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationCR) {
        return { articleNr: notification.articleNr, debitor: notification.debitor };
    }

}
export class NotificationTextGeneratorPC {
    public notificationType = NotificationType.PC;

    constructor() {
        //
    }

    public getRoute(notification: NotificationPC) {
        return NotificationTypeLink[this.notificationType];
    }

    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationPC) {
        return { articleNr: notification.articleNr, debitor: notification.debitor };
    }

}

export class NotificationTextGeneratorPM {
    public notificationType = NotificationType.PM;

    constructor() {
        //
    }

    public getRoute(notification: NotificationPM) {
        return NotificationTypeLink[this.notificationType];
    }

    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationPM) {
        return { articleNr: notification.articleNr, debitor: notification.debitor };
    }

}

export class NotificationTextGeneratorSP {
    public notificationType = NotificationType.SP;

    constructor() {
        //
    }

    public getRoute(notification: NotificationSP) {
        return '';
        // return NotificationTypeLink[this.notificationType];
    }

    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationSP) {
        return {};
    }

}

export class NotificationTextGeneratorLBSPC {
    public notificationType = NotificationType.LBSPC;

    constructor() {
        //
    }

    public getRoute(notification: NotificationLBSPC) {
        return NotificationTypeLink[this.notificationType];
    }

    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationIOI) {
        return { articleNr: notification.articleNr };
    }

}

export class NotificationTextGeneratorFOA {
    public notificationType = NotificationType.FOA;

    constructor() {
        //
    }

    public getRoute(notification: NotificationFOA) {
        return NotificationTypeLink[this.notificationType];
    }

    public getLinkQueryParams(notiConf: NotificationConfig, notification: NotificationIOI) {
        return { articleNr: notification.articleNr };
    }

}

export function setupTextGenerators() {
    let notificationTextGeneratorMap: NotificationTextGeneratorMap = new Map();
    notificationTextGeneratorMap.set(NotificationType.TotalOOS, new NotificationTextGeneratorTotalOOS());
    notificationTextGeneratorMap.set(NotificationType.WHOOS, new NotificationTextGeneratorWHOOS());
    notificationTextGeneratorMap.set(NotificationType.CRNF, new NotificationTextGeneratorCRNF());
    notificationTextGeneratorMap.set(NotificationType.IOI, new NotificationTextGeneratorIOI());
    notificationTextGeneratorMap.set(NotificationType.CR, new NotificationTextGeneratorCR());
    notificationTextGeneratorMap.set(NotificationType.PC, new NotificationTextGeneratorPC());
    notificationTextGeneratorMap.set(NotificationType.PM, new NotificationTextGeneratorPM());
    notificationTextGeneratorMap.set(NotificationType.SP, new NotificationTextGeneratorSP());
    notificationTextGeneratorMap.set(NotificationType.LBSPC, new NotificationTextGeneratorLBSPC());
    notificationTextGeneratorMap.set(NotificationType.FOA, new NotificationTextGeneratorFOA());
    return notificationTextGeneratorMap;
}
