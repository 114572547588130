
    <ng-multiselect-dropdown *ngIf="filterList; else showLoading" [(ngModel)]="filterValue" [class]="property" style="width: 100%;"
        [placeholder]="title" [settings]="dropdownSettings" [data]="filterList" [disabled]="disabled"
        (onSelect)="onSelect()" (onDeSelect)="onSelect()" (onSelectAll)="onSelect()" (onDeSelectAll)="onSelect()">
    </ng-multiselect-dropdown>
    <ng-template #showLoading>
        <div class="multiselect-dropdown" style="width: 100%;">
            <div class="dropdown-btn"><span>{{ title }}</span><div class="spinner-grow spinner-grow-sm" style="position: absolute; color: #a62c2d80; right: 22px; top: 16px;" role="status" aria-hidden="true"></div></div>
        </div>
    </ng-template>
