// @angular imports
import { Directive, ElementRef, Input, OnInit, DoCheck } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective, UntypedFormArray, FormGroupName, FormArrayName, AbstractControl, ControlContainer } from '@angular/forms';

@Directive({ selector: '[appValidationErrors]' })
export class ValidationErrorsDirective implements OnInit, DoCheck {
    @Input() public controlName: string;
    private form: UntypedFormGroup | UntypedFormArray;
    public control: AbstractControl;

    private messageMap = {
        required: '* Dies ist ein Pflichtfeld!',
        email: 'Die eingegebene E-Mail-Adresse ist nicht valide.',
    };

    constructor(
        private el: ElementRef,
        private parent: ControlContainer ) {
    }

    public ngOnInit() {
        if (this.parent.constructor === FormGroupDirective) {
            this.form = (<FormGroupDirective>this.parent).form;
        } else if (this.parent.constructor === FormGroupName) {
            this.form = (<FormGroupName>this.parent).control;
        } else if (this.parent.constructor === FormArrayName) {
            this.form = (<FormArrayName>this.parent).control;
        } else {
            throw new Error('validationErrorsDirective can be nested only in a FormGroup or FormGroupName component');
        }
        if (this.controlName) {
            if (typeof (this.form.controls[this.controlName]) !== 'undefined') {
                this.control = this.form.controls[this.controlName];
            } else {
                throw new Error('no control with name ' + this.controlName);
            }
        }
    }

    public ngDoCheck() {
        if (this.showErrors()) {
            this.setErrorMsg(this.getErrors().join('; '));
        }
    }

    private setErrorMsg(msg: string) {
        if (this.el.nativeElement) {
            this.el.nativeElement.innerHTML = msg;
        } else {
            if (msg) {
                this.el.nativeElement.setAttribute('data-error', msg);
            } else {
                this.el.nativeElement.removeAttribute('data-error');
            }
        }
    }

    private showErrors(): boolean {
        return (
            // tslint:disable-next-line: no-any
            this.control && ((<any>this.form).submitted || this.control.touched || this.control instanceof UntypedFormGroup)
            && (this.control.errors !== null)
        ) || (
            // tslint:disable-next-line: no-any
            !this.control && ((<any>this.form).errors !== null)
        );
    }

    private getErrors(): string[] {
        // tslint:disable-next-line: no-any
        const o: any = this.control ? this.control : this.form;
        const errors: string[] = [];
        if ('required' in o.errors) {
            errors.push(this.messageMap.required);
        } else {
            for (const key in o.errors) {
                if (o.errors.hasOwnProperty(key)) {
                    const error = o.errors[key];
                    if (error.errorMessage) {
                        errors.push(error.errorMessage);
                    } else {
                        if (this.messageMap[key]) {
                            errors.push(this.messageMap[key].replace(/{{(.+)}}/g, (_match, p1) => {
                                return error[p1];
                            }));
                        } else {
                            errors.push(key + ': ' + JSON.stringify(o.errors[key]));
                        }
                    }
                }
            }
        }
        return errors;
    }
}

