// login.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, } from '@angular/router';
import { LoginService } from '../service/login.service';

@Injectable()
export class LoginGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private authService: LoginService,
    ) { }

    public async canActivate(): Promise<boolean> {
        if (!await this.authService.verify()) {
            this.router.navigate(['login'], { queryParams: { sessionExpired: true } });
        }
        return true;
    }

    public canActivateChild(): Promise<boolean> {
        return this.canActivate();
    }

}
