import { Cube, createMeasure, createDimension, CubeDimensionType, CubeMeasureType } from './cube';

export enum ProductCountMeasures {
    productCount = 'Product.productCount'
}

export enum ProductDimensions {
    id = 'Product_[BI].id',
    status = 'Product_[BI].status',
    replenishmentSystem = 'Product_[BI].replenishmentSystem',
    abcTotal = 'Product_[BI].abcTotal',
    abcGroup = 'Product_[BI].abcGroup',
}

export const getProductCubeSql = (client: string) => `SELECT pr.*, pp.\`Direct Unit Cost\` as Unit_Cost, abcT.value AS abcTotal, abcG.value AS abcGroup FROM castly.${client}_product pr
    LEFT JOIN castly.${client}_purchase_price_recent pp ON pr.No_ = pp.\`Item No_\` AND pr.\`Vendor No_\` = pp.\`Vendor No_\`
    LEFT JOIN castly.product_abc_data_${client} abcT ON pr.No_ = abcT.productNo AND abcT.type = 'all'
    LEFT JOIN castly.product_abc_data_${client} abcG ON pr.No_ = abcG.productNo AND abcG.type = 'group'`;

export const Product: Cube<
    typeof ProductCountMeasures,
    typeof ProductDimensions
> = {
    sql: () => getProductCubeSql('wohnguide'),
    measures: {
        productCount: createMeasure(CubeMeasureType.count, '*'),
    },
    dimensions: {
        id: createDimension(CubeDimensionType.string, 'No_', true),
        status: createDimension(CubeDimensionType.string, '`Item Status`'),
        replenishmentSystem: createDimension(CubeDimensionType.string, '`Replenishment System`'),
        abcTotal: createDimension(CubeDimensionType.string, 'abcTotal'),
        abcGroup: createDimension(CubeDimensionType.string, 'abcGroup'),
    },
};


