export enum CubeMeasureType {
    number = 'number',
    count = 'count',
    countDistinct = 'countDistinct',
    countDistinctApprox = 'countDistinctApprox',
    sum = 'sum',
    avg = 'avg',
    min = 'min',
    max = 'max',
    runningTotal = 'runningTotal',
}

export enum CubeMeasureFormat {
    percent = 'percent',
    currency = 'currency',
}

export enum CubeDimensionType {
    time = 'time',
    string = 'string',
    number = 'number',
    boolean = 'boolean',
    geo = 'geo',
}

export enum CubeDimensionFormats {
    imageUrl = 'imageUrl',
    id = 'id',
    link = 'link',
    currency = 'currency',
    percent = 'percent',
}

export enum CubeRefreshInterval {
    second = 'second',
    minute = 'minute',
    hour = 'hour',
    day = 'day',
    week = 'week',
}

export enum CubeRelationship {
    belongsTo = 'belongsTo',
    hasMany = 'hasMany',
    hasOne = 'hasOne',
}

export enum CubeOperatorBinary {
    equals = 'equals',
    notEquals = 'notEquals',
    contains = 'contains',
    notContains = 'notContains',
    gt = 'gt',
    gte = 'gte',
    lt = 'lt',
    lte = 'lte',
    inDateRange = 'inDateRange',
    notInDateRange = 'notInDateRange',
    beforeDate = 'beforeDate',
    afterDate = 'afterDate',
}

export enum CubeOperatorUnary {
    set = 'set',
    notSet = 'notSet',
}

export enum CubeGranularity {
    second = 'second',
    minute = 'minute',
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
}

export enum CubeAggregationType {
    originalSql = 'originalSql',
    rollup = 'rollup',
    autoRollup = 'autoRollup',
}

export type CubeSql<T = string> = (...args) => string | T;
export type CubeSqlArray<T = string> = (...args) => (string | T)[];


export interface CubeCase {
    when: Array<{ sql: CubeSql; label: string | { sql: CubeSql } }>;
    else: { label: string };
}

export interface CubeMeasure<T extends CubeMeasureType = CubeMeasureType> {
    type: T;
    sql: CubeSql;
    format?: CubeMeasureFormat;
    title?: string;
    description?: string;
    shown?: boolean;
    filters?: Array<{sql: CubeSql}>;
    rollingWindow?: {
        trailing?: string,
        leading?: string,
        offset?: string,
    };
    drillMembers?: string[];
}

export interface CubeDimension<T extends CubeDimensionType = CubeDimensionType> {
    sql?: CubeSql;
    type: T;
    title?: string;
    description?: string;
    shown?: boolean;
    case?: CubeCase;
    primaryKey?: boolean;
    subQuery?: boolean;
}

export type CubeTimeDimension = CubeDimension<CubeDimensionType.time>;

export interface CubeSegment {
    sql: CubeSql;
}

export interface CubeJoin<C extends Cube> {
    relationship: CubeRelationship;
    sql: CubeSql;
}

export type CubeRefreshKey = { sql: CubeSql } | { every: string, incremental?: boolean, updateWindow?: string };

export interface CubeIndex<D> {
    columns: D[];
}

export type CubeReference<T> = CubeSql<T>;
export type CubeReferences<T> = CubeSqlArray<T>;

type CubeKey<T extends object | string = string> = T extends object ? keyof T : T;
type CubeValue<T extends object | string = string> = T extends object ? T[keyof T] : T;
type CubeLiteral<T extends object | string = string> = CubeKey<T> | CubeValue<T>;

// @TODO: without any, joined cubes may cause type mismatch. investigate
// tslint:disable-next-line:no-any
type JoinedMeasures<C extends Cube> = C extends Cube<infer CM, any, any> ? CM : never;
// tslint:disable-next-line:no-any
type JoinedDimensions<C extends Cube> = C extends Cube<any, infer CD, any> ? CD : never;
// tslint:disable-next-line:no-any
type JoinedTimeDimensions<C extends Cube> = C extends Cube<any, any, infer CT> ? CT : never;

export interface CubeAggregation<
    M extends string | object,
    D extends string | object,
    T extends string | object,
    J extends Cube> {
    type: keyof typeof CubeAggregationType;
    maxPreAggregations?: number;
    refreshKey?: CubeRefreshKey;
    measureReferences?: CubeReferences<CubeLiteral<M> | CubeValue<JoinedMeasures<J>>>;
    dimensionReferences?: CubeReferences<CubeLiteral<D> | CubeLiteral<T> | CubeValue<JoinedDimensions<J>>>;
    timeDimensionReference?: CubeReference<CubeLiteral<T> | CubeValue<JoinedTimeDimensions<J>>>;
    indexes?: Record<string, CubeIndex<D>>;
    granularity?: CubeGranularity;
    partitionGranularity?: CubeGranularity;
    scheduledRefresh?: boolean;
    useOriginalSqlPreAggregations?: boolean;
}


export interface Cube<
    M extends object | string = object | string,
    D extends object | string = object | string,
    T extends object | string = object,
    // @TODO: without any, joined cubes may cause type mismatch. investigate
    // tslint:disable-next-line:no-any
    J extends Record<string, Cube<any, any, any>> = {}> {
    sql: CubeSql;
    title?: string;
    extends?: string;
    refreshKey?: CubeRefreshKey;
    dataSource?: string;
    sqlAlias?: string;
    joins?: { [C in keyof J]: CubeJoin<J[C]> };
    preAggregations?: Record<string, CubeAggregation<M, D, T, J[keyof J]>>;
    measures: Record<CubeKey<M>, CubeMeasure>;
    dimensions: Record<CubeKey<D>, CubeDimension> & Record<CubeKey<T>, CubeTimeDimension>;
    segments?: Record<string, CubeSegment>;
}

function getSqlFuncForMeasureType(sql: CubeSql | string, type: CubeMeasureType) {
    if (typeof sql === 'function') {
        return sql;
    }
    switch (type) {
        case CubeMeasureType.count:
            return () => `${sql}`;
        default:
            return (CUBE) => `${CUBE}.${sql}`;
    }
}

function getSqlFuncForDimensionType(sql: CubeSql | string, type: CubeDimensionType) {
    if (typeof sql === 'function') {
        return sql;
    }
    switch (type) {
        default:
            return (CUBE) => `${CUBE}.${sql}`;
    }
}

export function createMeasure<T extends CubeMeasureType>(type: T, sqlOrCol: CubeSql | string, filters?: Array<CubeSql | string>): CubeMeasure<T> {
    return {
        type,
        sql: getSqlFuncForMeasureType(sqlOrCol, type),
        filters: filters ? filters.map(f => ({sql: typeof f === 'function' ? f : (CUBE) => `${CUBE}.${f}`})) : undefined,
    };
}

export function createSumMeasure(sqlOrCol: CubeSql | string, filters?: Array<CubeSql | string>) {
    return createMeasure(CubeMeasureType.sum, sqlOrCol, filters);
}

export function createCountDistinctMeasure(sqlOrCol: CubeSql | string, filters?: Array<CubeSql | string>) {
    return createMeasure(CubeMeasureType.countDistinct, sqlOrCol, filters);
}

export function createDimension<T extends CubeDimensionType>(type: T, sqlOrCol: CubeSql | string, primaryKey: boolean = false, shown: boolean = true): CubeDimension<T> {
    return {
        type,
        primaryKey: primaryKey,
        shown: shown,
        sql: getSqlFuncForDimensionType(sqlOrCol, type),
    };
}

export function createStringDimension(sqlOrCol: CubeSql | string, primaryKey: boolean = false, shown: boolean = true) {
    return createDimension(CubeDimensionType.string, sqlOrCol, primaryKey, shown);
}

export function createTimeDimension(sqlOrCol: CubeSql | string, primaryKey: boolean = false, shown: boolean = true) {
    return createDimension(CubeDimensionType.time, sqlOrCol, primaryKey, shown);
}

export function createReferences<T extends string>(...cols: T[]): CubeReferences<T> {
    return ((CUBE: string) => {
        let res: string[] = [];
        for (let col of cols) {
            // if the given column follows the format 'table.column', week keep the table
            if (col.indexOf('.') > 0) {
                res.push(col);
            } else {
                res.push(`${CUBE}.${col}`);
            }
        }
        return res;
    });
}

export function createReference<T extends string>(col: T): CubeReference<T> {
    return ((CUBE: string) => col.indexOf('.') > 0 ? col : `${CUBE}.${col}`);
}

