import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';


@Component({
    selector: 'login-layout',
    templateUrl: './login-layout.component.html',
})

export class LoginLayoutComponent {

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) {
        this.document.body.classList.remove('lichtblick');
    }
    public getPage(outlet) {
        let page = 'page';
        return outlet.activatedRouteData[page] || 'login';
    }
}
