import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'artNoAno'
})
export class ArticleNumberAnonymizer implements PipeTransform {

  public transform(value: string): string {
    if (!value) {
       return value;
    }
    let numberParts = value.split('.');
    if (!(numberParts.length == 4 || numberParts.length == 5)) {
      console.error('Strage Number: ' + value);
    }
    if ( numberParts.length === 5 ) {
      numberParts.splice(1, 1);
    }
    numberParts[1] = numberParts[1][0] == '0' ? numberParts[1].substring(1) : numberParts[1];
    numberParts[2] = numberParts[2] + '0';
    numberParts[3] = numberParts[3] + '0';
    return numberParts.join('.');
  }
}
