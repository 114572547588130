import { Component, OnInit, Input } from '@angular/core';
import { validate as passwordValidator } from '../core/validators/password.validator';
import { Validators } from '@angular/forms';
import { User, Role } from 'src/interfaces/user';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../core/service/user.service';
import { state } from 'src/state/state';
import { FormControlTS, FormGroupTS } from '@common_node_modules/angular-typesafe-forms';

export type UserForm = Pick<User, 'username' | 'firstname' | 'lastname' | 'mailaddress'> & {
    newPassword1?: string,
    newPassword2?: string,
};

@Component({
    selector: 'userprofile-edit',
    templateUrl: './userprofile-edit.component.html',
})
export class UserprofileEditComponent implements OnInit {

    @Input() public user: User;
    @Input() public userList: User[];

    public userLoading: boolean = false;
    public userListLoading: boolean = false;

    public form: FormGroupTS<UserForm>;
    public id: number;
    public changePassword = false;
    public standalone = true;

    public adminMode: boolean = false;
    public newUserMode: boolean = false;
    public showConfig: boolean = true;
    public calculatorOn: boolean = false;
    public forecastOn: boolean = false;
    public stocksOn: boolean = false;
    public distOn: boolean = false;
    public reportsOn: boolean = false;

    public messages: string[] = [];

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router) {
    }

    public ngOnInit() {

        this.route.paramMap.subscribe(params => {
            this.changePassword = false;
            this.adminMode = false;
            this.newUserMode = false;

            if (params.get('userId') == 'new') {
                this.newUserMode = true;
                this.user = {} as User;
                this.buildForm();
                this.initializeForm();
                this.togglePassword(true);
            } else {
                let userId = parseInt(params.get('userId'), 10);
                if (!userId) {
                    //@TODO: get current user id from storage, not from env
                    userId = state.loggedInUserId;
                }
                this.userLoading = true;
                this.userService.getUser(userId).then(user => {
                    this.user = user;
                    console.log(this.user);
                    if (this.user) {
                        this.buildForm();
                        this.initializeForm();
                        this.userLoading = false;
                    }
                });
            }
            if (state.loggedInUserRole == Role.ADMIN) {
                this.adminMode = true;
                this.loadUserTable();
            }
        });
    }

    protected loadUserTable() {
        this.userListLoading = true;
        this.userService.getAllUsers().then(users => {
            this.userList = users;
            this.userListLoading = false;
        });
    }

    protected initializeForm() {
    }

    protected buildForm() {
        this.form = new FormGroupTS<UserForm>({
            username: new FormControlTS(this.user.username, [Validators.required]),
            firstname: new FormControlTS(this.user.firstname, [Validators.required]),
            lastname: new FormControlTS(this.user.lastname, [Validators.required]),
            mailaddress: new FormControlTS(this.user.mailaddress, [Validators.required, Validators.email])
        }); //, this.validatePasswords);
        this.form.valueChanges.subscribe( () => {
            this.messages = [];
        });
        this.showConfig = true;
        this.checkSupplementalRoles();
    }

    public validatePasswords(group: FormGroupTS<UserForm>) {
        let passwords: string[] = [];
        let errors: any = {};
        for (let key in group.value) {
            if (group.value[key]) {
                if (!errors.passwordsSame && key.indexOf('newPassword') >= 0) {
                    if (passwords.filter(value => value !== group.value[key]).length > 0) {
                        errors.passwordsSame = {
                            valid: false,
                            errorMessage: 'Beide Passwörter müssen übereinstimmen'
                        };
                    } else {
                        passwords.push(group.value[key]);
                    }
                }
            } else {
                errors.formCompleted = {
                    valid: false,
                    errorMessage: 'Es wurden nicht alle Eingabefelder ausgefüllt!'
                };
            }
        }
        return Object.keys(errors).length ? errors : null;
    }

    public getFormErrorTooltip() {
        if (this.form.errors) {
            let ms = [];
            for (let k of Object.keys(this.form.errors)) {
                ms.push(this.form.errors[k].errorMessage);
            }
            return ms.join(' ');
        } else {
            return 'Beachten Sie die Fehlermeldungen im Formular.';
        }
    }

    public togglePassword(forceVal?: boolean) {
        this.changePassword = forceVal !== undefined ? forceVal : !this.changePassword;
        if (this.changePassword) {
            this.form.addControl('newPassword1', new FormControlTS('', passwordValidator));
            this.form.addControl('newPassword2', new FormControlTS('', passwordValidator));
        } else {
            this.form.removeControl('newPassword1');
            this.form.removeControl('newPassword2');
        }
    }

    public async save() {
        this.messages = [];
        this.userLoading = true;
        if (!this.user.id) {
            //this.isNewObject = true;
        }
        let newUserDate = Object.assign({}, this.user, this.form.value);
        if (!this.changePassword) {
            delete newUserDate.newPassword1;
            delete newUserDate.newPassword2;
        }
        try {
            let saveRes = await this.userService.saveUser(newUserDate);
            if (saveRes) {
                if (this.newUserMode) {
                    this.user = {} as User;
                    this.buildForm();
                    this.initializeForm();
                    this.togglePassword(true);
                    this.messages.push('Erfolgreich gespeichert!');
                } else {
                    this.user = newUserDate;
                    this.messages.push('Erfolgreich gespeichert!');
                }
                this.loadUserTable();
            } else {
                this.messages.push('Es ist ein Problem aufgetreten.');
            }
        } catch (error) {
            if (error.error && error.error.error) {
                switch (error.error.error) {
                    case 'usernameTaken':
                        this.messages.push('Fehler: Der Nutzername wird bereits verwendet.');
                        break;
                    case 'emailTaken':
                        this.messages.push('Fehler: Die Mailadresse wird bereits verwendet.');
                        break;
                    default:
                        this.messages.push('Fehler: ' + error.error.error);
                }
            } else {
                this.messages.push('Es ist ein Problem aufgetreten: ', error.message ? error.message : error);
            }
            console.error(error);
        }
        this.userLoading = false;
    }

    //@TODO: error handling inside the table functions

    public async forceEditOpen() {
        this.showConfig = true;
    }

    //@TODO: reconsider all implementations with switches. Make it more stable. Add loading indicator.
    //worked for now. put not to much effort into this feature
    public async activate(id: number) {
        let res = await this.userService.activateUser(id);
        this.loadUserTable();
    }

    public async deactivate(id: number) {
        let res = await this.userService.deactivateUser(id);
        this.loadUserTable();
    }

    public checkSupplementalRoles(): void {
        this.calculatorOn = this.user.supplementalRoles ? this.user.supplementalRoles.includes(Role.CALC_USER) : false;
        this.forecastOn = this.user.supplementalRoles ? this.user.supplementalRoles.includes(Role.FORECAST_USER) : false;
        this.stocksOn = this.user.supplementalRoles ? this.user.supplementalRoles.includes(Role.STOCKS_USER) : false;
        this.distOn = this.user.supplementalRoles ? this.user.supplementalRoles.includes(Role.DIST_USER) : false;
        this.reportsOn = this.user.supplementalRoles ? this.user.supplementalRoles.includes(Role.REPORT_USER) : false;
    }

    public async toggleCalculator() {
        if (this.calculatorOn) {
            await this.userService.removeUserRole(this.user.id, Role.CALC_USER);
        } else {
            await this.userService.addUserRole(this.user.id, Role.CALC_USER);
        }
        this.user.supplementalRoles = await this.userService.getUserRoles(this.user.id);
        this.checkSupplementalRoles();
    }

    public async toggleForecast() {
        if (this.forecastOn) {
            await this.userService.removeUserRole(this.user.id, Role.FORECAST_USER);
        } else {
            await this.userService.addUserRole(this.user.id, Role.FORECAST_USER);
        }
        this.user.supplementalRoles = await this.userService.getUserRoles(this.user.id);
        this.checkSupplementalRoles();
    }

    public async toggleStocks() {
        if (this.stocksOn) {
            await this.userService.removeUserRole(this.user.id, Role.STOCKS_USER);
        } else {
            await this.userService.addUserRole(this.user.id, Role.STOCKS_USER);
        }
        this.user.supplementalRoles = await this.userService.getUserRoles(this.user.id);
        this.checkSupplementalRoles();
    }

    public async toggleDist() {
        if (this.distOn) {
            await this.userService.removeUserRole(this.user.id, Role.DIST_USER);
        } else {
            await this.userService.addUserRole(this.user.id, Role.DIST_USER);
        }
        this.user.supplementalRoles = await this.userService.getUserRoles(this.user.id);
        this.checkSupplementalRoles();
    }

    public async toggleReports() {
        if (this.reportsOn) {
            await this.userService.removeUserRole(this.user.id, Role.REPORT_USER);
        } else {
            await this.userService.addUserRole(this.user.id, Role.REPORT_USER);
        }
        this.user.supplementalRoles = await this.userService.getUserRoles(this.user.id);
        this.checkSupplementalRoles();
    }

    public async delete(id: number, username: string) {
        if (id !== 1) {
            if (confirm(`Den Nutzer ${username} wirklich dauerhaft löschen?`)) {
                let res = await this.userService.deleteUser(id);
                this.loadUserTable();
                if (this.showConfig) {
                    this.router.navigate(['/user', 'new']);
                }
            }
        }
    }
}

