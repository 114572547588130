import { BiCountMeasures, BiSumMeasures, BiStringDimensions, BiTimeDimensions } from './wohnguide-cube';
import { PimCountMeasures, PimStringDimensions } from './pim-cube';
import { ProductDimensions } from './product-cube';
import { NotificationFilter, NotificationSort, PossibleNotificationFields } from './notification/notification';

export type BiMeasures
    = BiCountMeasures
    | BiSumMeasures
    | PimCountMeasures
    ;

export type CompareBiMembers = `Δ${BiMembers}`;

export type BiDimensions
    = BiStringDimensions
    | BiTimeDimensions
    | PimStringDimensions
    | ProductDimensions
    ;

export type BiMembers
    = BiMeasures
    | BiDimensions
    ;

export enum BiEntity {
    debitor = 'debitor',
    debitorType = 'debitorType',
    creditor = 'creditor', // pseudo-entity for use with calculator and fc tool only
    status = 'status',
    replenishmentSystem = 'replenishmentSystem',
    location = 'location',
    shippingAgent = 'shippingAgent',
    dayOfWeek = 'dayOfWeek',
    weekOfYear = 'weekOfYear',
    monthOfYear = 'monthOfYear',
    year = 'year',
    productGroup = 'productGroup', // @TODO: define from PIM data
    customerZip = 'customerZip',
    customerGender = 'customerGender', // @TODO: lacking data - infer from first name via NLP model?
    productKey = 'productKey',
    legacyKey = 'legacyKey',
    type = 'type',
    mounting = 'mounting',
    material = 'material',
    brand = 'brand',
    color = 'color',
    width = 'width',
    length = 'length',
    shippingType = 'shippingType',
    parcelType = 'parcelType',
    producedIn = 'producedIn',
    abcTotal = 'abcTotal',
    abcGroup = 'abcGroup',
}

export const FilterEntities: Array<keyof typeof BiEntity> = [
    'debitor',
    'debitorType',
    'creditor',
    'status',
    'replenishmentSystem',
    'location',
    'shippingAgent',
    'legacyKey',
    'productKey',
    'abcTotal',
    'abcGroup',
    'mounting',
    'material',
    'brand',
    'color',
    'width',
    'length',
    'producedIn',
    'parcelType',
    'monthOfYear',
    'weekOfYear',
];

export enum BiChart {
    lineTimespan = 'lineTimespan',
    lineEntity = 'lineEntity',
    barEntities = 'barEntities',
    stackedBarEntities = 'stackedBarEntities',
    pieEntity = 'pieEntity',
    mapZip = 'mapZip',
}

export enum BiClient {
    wohnguide = 'WohnGuide',
    roomy = 'Roomy',
    lichtblick = 'Lichtblick',
}

export enum BiPresets {
    orders = 'orders',
    ordersPrel = 'ordersPrel',
    customerOrdersPrel = 'customerOrdersPrel',
    sales = 'sales',
    returns = 'returns',
    cancellations = 'cancellations',
    items = 'items',
    region = 'region',
    custom = 'custom',
}

export enum BiTimeframe {
    year = 'year',
    quarter = 'quarter',
    month = 'month',
    isoWeek = 'isoWeek',
}

export type BiFilter<T = string> = {
    -readonly [K in keyof typeof BiEntity]?: T[];
};

export type WidgetType = 'graph' | 'notification';
export interface BiWidgetBasic {
    id?: number;
    client?: BiClient;
    user?: number;
    type: WidgetType;
    name: string;
    isFavourite?: boolean;
    widgetGroupId?: number;
    disabled?: boolean;
    createdAt?: Date;
}

export interface BiWidgetGraph extends BiWidgetBasic {
    type: 'graph';
    entity: BiEntity;
    entity2?: BiEntity;
    preset?: BiPresets;
    columns?: BiMeasures[];
    measure: BiMeasures;
    chart: BiChart;
    filter: BiFilter;
}

export interface BiWidgetNotification extends BiWidgetBasic {
    type: 'notification';
    configIds: number[];
    filter: NotificationFilter;
    sort?: NotificationSort;
    group?: PossibleNotificationFields;
}

export interface BiWidgetGroup {
    id?: number;
    client?: BiClient;
    user?: number;
    name: string;
}


export interface BiWidgetDaterange extends BiWidgetGraph {
    timeframe?: BiTimeframe;
    compTimeframe?: BiTimeframe;
    offset?: number;
    dateStart: string;
    dateEnd: string;
    dateCompStart?: string;
    dateCompEnd?: string;
}

export interface BiWidgetTimeframe extends BiWidgetGraph {
    dateStart?: string;
    dateEnd?: string;
    timeframe: BiTimeframe;
    compTimeframe: BiTimeframe;
    offset: number;
    dateCompStart?: string;
    dateCompEnd?: string;
}

export type GraphBiWidgets = BiWidgetDaterange | BiWidgetTimeframe;
export type NotificationBiWidgets = BiWidgetNotification;
export type BiWidget = GraphBiWidgets | NotificationBiWidgets;

export const BiColors: string[] = [
    'rgb(164, 196, 0)',
    'rgb(240, 167, 10)',
    'rgb(0, 171, 157)',
    'rgb(166, 44, 4)',
    'rgb(0, 138, 0)',
    'rgb(140, 23, 169)',
    'rgb(100, 118, 135)',
    'rgb(0, 80, 239)',

    'rgb(187, 173, 52)',
    'rgb(255, 148, 120)',
    'rgb(66, 161, 224)',
    'rgb(216, 0, 115)',
    'rgb(0, 194, 16)',
    'rgb(146, 112, 0)',
    'rgb(88, 66, 184)',
    'rgb(196, 56, 0)',

    // 'rgba(164, 196, 0, 1)',
    // 'rgba(96, 169, 23, 1)',
    // 'rgba(0, 138, 0, 1)',
    // 'rgba(109, 135, 100, 1)',
    // 'rgba(0, 171, 169, 1)',
    // 'rgba(100, 118, 135, 1)',
    // 'rgba(227, 200, 0, 1)',
    // 'rgba(240, 163, 10, 1)',
    // 'rgba(250, 104, 0, 1)',
    // 'rgba(216, 0, 115, 1)',
    // 'rgba(255, 148, 120, 1)',
    // 'rgba(162, 0, 37, 1)',
    // 'rgba(229, 20, 0, 1)',
    // 'rgba(27, 161, 226, 1)',
    // 'rgba(106, 0, 255, 1)',
    // 'rgba(0, 80, 239, 1)',
];
