import { Injectable } from '@angular/core';
import { getCurrentDate } from './date.service';

type CachedItem<DataType, KeyType> = {
    assignedKey: KeyType,
    data: DataType,
    timestamp: Date
};

/**
 * @TODO: local storage
 * @TODO: invalidation, max age, max size, LRU cleaning
 * @TODO: intercept observables and/or http service
 * @TODO: integrate with cubejs mutex
 * @TODO: supply as an npm package
 */
@Injectable({
    providedIn: 'root',
}) // tslint:disable-next-line: no-any
export class CacheService<DataType = any, KeyType = string> {

    protected items: Map<KeyType, CachedItem<DataType, KeyType>>;

    constructor() {
        this.items = new Map();
    }

    public put(key: KeyType, data: DataType) {
        if (!key) {
            throw new Error('cannot put cache item without key.');
        }
        this.items.set(key, {assignedKey: key, timestamp: getCurrentDate(), data: data});
    }

    public delete(key: KeyType) {
        if (!key) {
            throw new Error('cannot delete cache item without key.');
        }
        this.items.delete(key);
    }


    public has(key: KeyType) {
        const item = this.items.get(key);
        if (item) {
            // discard items that are not from the current day
            const currentDay = getCurrentDate().getDate();
            if (item.timestamp.getDate() !== currentDay) {
                this.items.delete(key);
                return false;
            }
            return true;
        }
        return false;
    }

    public get(key: KeyType): DataType | undefined {
        if (this.has(key)) {
            console.info('cache hit for', key);
            return this.items.get(key).data;
        } else {
            console.info('cache miss for', key);
            return undefined;
        }
    }
}
