import { CheckableArticle, CheckableArticleWithRef, PriceChecker, RegroupedCheckables } from './PriceChecker';

export abstract class Check {
    protected regrouped: RegroupedCheckables;

    constructor() {
        //
    }

    public abstract check(): void;


    protected sortAll(field: keyof CheckableArticle, secondaryField?: keyof CheckableArticle): void {
        PriceChecker.applyToGroups(this.regrouped, a => {
            this.doSort(a, field, secondaryField);
        });
    }

    protected doSort(targetArray: CheckableArticleWithRef[], field: keyof CheckableArticle, secondaryField?: keyof CheckableArticle): void {
        targetArray.sort((a, b) => {
            //articles can have the same target value. Make sure sort order is deterministic via the natural sort field.
            if (b[field] == a[field]) {
                if (!secondaryField) {
                    console.error(targetArray, field, secondaryField);
                    throw new Error('Sort order is non deterministic and secondary field was not supplied.');
                } else if (b[secondaryField] == a[secondaryField]) {
                    throw new Error('Sort order is non deterministic despite secondary field.');
                }
                return b[secondaryField] < a[secondaryField] ? 1 : -1;
            } else {
                return b[field] < a[field] ? 1 : -1;
            }
        });
    }
}
