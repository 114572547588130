import { removeFloatingPointError } from '../Calculator';
import { Check } from './Check';
import { CheckableArticleFixed, CheckableArticleTargets, CheckableArticleWithRef, PriceChecker, RegroupedCheckables } from './PriceChecker';

type PreferredTargetSort = 'higher' | 'lower';

export class LinearCheck extends Check {

    protected regrouped: RegroupedCheckables;
    private _preferredTargetSort: PreferredTargetSort;

    constructor(protected articles: CheckableArticleWithRef[], protected fixedFields: (keyof CheckableArticleFixed)[], protected sortField: keyof CheckableArticleFixed, protected targetFields: (keyof CheckableArticleTargets)[]) {
        super();
        this.regrouped = PriceChecker.regroup(articles, ...fixedFields);
        this.preferredTargetSort = 'lower';
        //sort field is not changed in calculator. so we can sort once here.
        this.sortAll(this.sortField);
    }

    public get preferredTargetSort(): PreferredTargetSort {
        return this._preferredTargetSort;
    }

    public set preferredTargetSort(value: PreferredTargetSort) {
        this._preferredTargetSort = value;
    }

    public check(): void {
        //make sure that targetFields were reloaded via PriceChecker before checking
        for (let targetField of this.targetFields) {
            this.compareAll(targetField);
        }
    }

    protected compareAll(targetField: keyof CheckableArticleTargets) {
        PriceChecker.applyToGroups(this.regrouped, a => {
            this.compare(a, targetField);
        });
    }

    protected compare(naturallySorted: CheckableArticleWithRef[], targetField: keyof CheckableArticleTargets) {
        let foundProblematics: Set<string> = new Set();
        let targetSorted = Array.from(naturallySorted);
        this.doSort(targetSorted, targetField, this.sortField);
        //console.log('lists before check');
        //this.logListCompare(naturallySorted, targetSorted);
        for (let i = 0; i < naturallySorted.length; i++) {
            if (naturallySorted[i].articleRef !== targetSorted[i].articleRef) {
                //@TODO: we cannot have both modes of preferredTargetSort at the same time. Needs discussion.
                //we handle the mismatch depending on the price difference
                //if we presume that the problematic article is always too cheap
                if (this.preferredTargetSort == 'lower') {
                    //because we sorted ascending, all pairs before are correct or were corrected alread.
                    //The current target article must be the problematic one because ascending sort put it here with the smaller target value
                    //as a fail safe, we keep problematic articles unique, but still move them again
                    if (!foundProblematics.has(targetSorted[i].articleRef.id)) {
                        PriceChecker.addCheckResult(targetSorted[i], 'outOfOrder', this.fixedFields, this.sortField, targetField, naturallySorted[i]);
                        foundProblematics.add(targetSorted[i].articleRef.id);
                    }
                    //move the problematic article to the position it should have inside the target array. That is its position in the natural array.
                    this.moveElement(targetSorted, i, this.findCheckableIndex(naturallySorted, targetSorted[i]));
                    //Because the article that jumped to the current position could be wrong too, we need to check here again
                    i = i - 1;
                    continue;
                } else { //if we presume that the problematic article is always too expensive
                    //because we sorted ascending, all pairs before are correct or were corrected alread.
                    //The current natural article must be the problematic one because ascending sort put its counterpart lower on the list.
                    if (!foundProblematics.has(targetSorted[i].articleRef.id)) {
                        PriceChecker.addCheckResult(naturallySorted[i], 'outOfOrder', this.fixedFields, this.sortField, targetField, targetSorted[i]);
                        foundProblematics.add(targetSorted[i].articleRef.id);
                    }
                    //move the problematic article from its current position in the target array to the current position
                    this.moveElement(targetSorted, this.findCheckableIndex(targetSorted, targetSorted[i]), i);
                    //Because the article that jump to the current position must be the correct one, we can move on with the next index without decrement
                }

                //console.log('lists after check');
                //this.logListCompare(naturallySorted, targetSorted);
            }
            //prices have to rise at least a fixed amount according to natural sort order.
            //check the predecessor to be less than the current checkable here. Note that we can assume that both lists are correct until the current index
            if (i > 0) {
                if (!foundProblematics.has(targetSorted[i].articleRef.id)) {
                    if (removeFloatingPointError(targetSorted[i - 1][targetField]) == removeFloatingPointError(targetSorted[i][targetField])) {
                        PriceChecker.addCheckResult(targetSorted[i], 'outOfOrder', this.fixedFields, this.sortField, targetField, targetSorted[i - 1]);
                        foundProblematics.add(targetSorted[i].articleRef.id);
                    }
                }
            }
        }
    }

    protected logListCompare(naturallySorted: CheckableArticleWithRef[], targetSorted: CheckableArticleWithRef[]): void {
        for (let i = 0; i < naturallySorted.length; i++) {
            console.info([i, naturallySorted[i].articleRef.articleNumber, targetSorted[i].articleRef.articleNumber].join('\t'));
        }
    }

    protected findCheckableIndex(checkables: CheckableArticleWithRef[], target: CheckableArticleWithRef): number {
        return checkables.findIndex(v => { return v.articleRef === target.articleRef; });
    }

    protected moveElement(array: CheckableArticleWithRef[], fromIndex: number, toIndex: number): void {
        array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
    }
}
