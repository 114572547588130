import { UntypedFormControl } from '@angular/forms';

export function validate(c: UntypedFormControl) {

    const LETTERS_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;

    let mincount: number = 8;
    let maxcount: number = 30;

    if (!c.value) {
        return null;
    }
    if (c.value.length < mincount) {
        return {
            password: {
                valid: false,
                pattern: null,
                errorMessage: 'Passwort muss zwischen 8 und 30 Zeichen lang sein'
            }
        };
    }
    if (c.value.length > maxcount) {
        return {
            password: {
                valid: false,
                pattern: null,
                errorMessage: 'Passwort muss zwischen 8 und 30 Zeichen lang sein'
            }
        };
    }
    return LETTERS_REGEXP.test(c.value) ? null : {
        password: {
            valid: false,
            pattern: LETTERS_REGEXP.toString(),
            errorMessage: 'Passwort muss mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen beinhalten.'
        }
    };
}
