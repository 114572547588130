// login.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, } from '@angular/router';
import { DistributionComponent } from 'src/app/features/distribution/distribution.component';
import { Role } from 'src/interfaces/user';
import { LoginService } from '../service/login.service';

@Injectable()
export class DistributionGuard implements CanActivate, CanActivateChild, CanDeactivate<DistributionComponent> {
    constructor(
        private authService: LoginService
    ) { }

    public async canActivate(): Promise<boolean> {
        //@TODO: need to do something on false? There is no "offical" UI way to reach this of role is not present?
        return this.authService.getUserRoles().indexOf(Role.DIST_USER) > -1;
    }

    public canActivateChild(): Promise<boolean> {
        return this.canActivate();
    }

    public canDeactivate(dist?: DistributionComponent) {
        if (dist?.planSource) {
            return window.confirm('Achtung: Es befindet sich ein Umlagerungsplan in Bearbeitung. Beim Verlassen des Umlagerungstools gehen alle Eingaben in diesem Plan verloren. Wenn sie erhalten bleiben sollen, muss der Plan vor dem Verlassen fertig gestellt werden. Wirklich verlassen und Eingaben verwerfen?');
        }
        return true;
    }

}
