import { LocationForFormat } from './calculator/Product';

export enum FormatNo {
    AMAZON = 'AMAZON',
    BAULINGER = 'BAULINGER',
    CHANNEL21 = 'CHANNEL21',
    //FARU = 'FARU',
    HAGEBAU = 'HAGEBAU',
    HOME24 = 'HOME24',
    HORNBACH = 'HORNBACH',
    LICHTBLICK = 'LICHTBLICK',
    LIDL = 'LIDL',
    NORMA = 'NORMA',
    OBI = 'OBI',
    OTTO = 'OTTO',
    SMARKETER = 'SMARKETER',
    TBONE = 'TBONE',
    WAYFAIR = 'WAYFAIR',
    WOHNFUEHLIDEE = 'WOHNFUEHLIDEE',
}

export type ImportResponse = {
    databaseResponse: { valid: number, failed: number };
    missingArtNo: string[];
    redundantArtNo: string[];
    wrongWarehouse: string[];
    missingDebitorPrice: string[]
    missingOttoArtNo: string[];
    missingEanArtNo: string[];
};

export type FileIsReadyResponse = {
    isReady: boolean
    failedFormats?: string[];
};

export type ReportConf = {
    format: FormatNo;
    reportQuote: number;
    lockedAmount: number;
    productionAmount: number;
    debNr: string
    useDebWarehouse: boolean;
    useDifferentWareHouseTerm: boolean;
    hannoverTerm: string | null;
    heiligenstadtTerm: string | null;
    leinefeldeTerm: string | null;
    fileUploadDate: Date | null;
    date?: Date;
};

export type ReportConsumtionFileType = {
    Interface: FormatNo;
    Artikelnummer: string;
    Menge: number;
    Start: Date;
    Ende: Date;
    line?: string;
};

export type ReportConsumtionType = {
    formatNo: FormatNo;
    productNo: string;
    amount: number;
    startDate: Date;
    endDate: Date;
    importDate: Date;
};

export type ExtendetReportConsumtionType = ReportConsumtionType & { locationForFormat?: LocationForFormat };

export type ConsumptionResponse = {
    valueUndefined: ReportConsumtionFileType[];
    notAnInterface: ReportConsumtionFileType[];
    invalidDate: ReportConsumtionFileType[];
    overlappingPeriods: ReportConsumtionFileType[];
    invalidArticles: ReportConsumtionFileType[];
};

export type ProductsWithPriceButNoReport = {
    productNo: string;
    debitorNo: string;
    reportImportDate: Date;
};
