import { Injectable } from '@angular/core';
import { BiClient } from 'src/interfaces/bi-widget';
import { DistributionPlan } from 'src/interfaces/distribution/DistributionPlan';
import { ApiService } from './api.service';

@Injectable()
export class DistributionPlanService {

    constructor(private apiService: ApiService) { }

    public async getPlans(client: BiClient): Promise<DistributionPlan[]> {
        const res = await this.apiService.get<DistributionPlan[]>(`plans/${client}`);
        if (res && res.data) {
            return res.data.reverse();
        } else {
            console.error(res.error);
        }
    }

    public async savePlan(plan: DistributionPlan) {
        let res = await this.apiService.post<DistributionPlan>(plan, `plan/save`).toPromise();
        return res;
    }

    public async deletePlan(plan: DistributionPlan) {
        let res = await this.apiService.post<DistributionPlan>(plan, `plan/delete`).toPromise();
        return res;
    }
}
