import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

export type PossibleScrollEventType = 'scroll' | 'wheel' | 'touchmove' | 'keydown' | 'focusout' | 'touchend';

export interface PreventScrollingOptions {
    eventTypes?: Set<PossibleScrollEventType>; // event types to prevent. note: 'keydown' prevents scroll events triggered by keys on body element
    inherit?: boolean; // set to true to prevent scroll events on child elements as well
    active?: boolean; // set to false to explicitly disable directive
}

function handler(event: Event) {
    const options: PreventScrollingOptions = this.options;
    const element: HTMLElement = this.el.nativeElement;
    // console.log('EVENT', options, event);
    if (this.options.active) {
        switch (event.type) {
            case 'keydown':
                if (event.target === document.body) {
                    switch ((event as KeyboardEvent).code) {
                        case 'ArrowUp':
                        case 'ArrowDown':
                        case 'ArrowLeft':
                        case 'ArrowRight':
                        case 'PageUp':
                        case 'PageDown':
                        case 'Home':
                        case 'End':
                        case 'Space':
                            event.preventDefault();
                            event.stopPropagation();
                    }
                }
                break;
            default:
                if (event.target === element || options.inherit && element.contains(event.target as Node)) {
                    event.preventDefault();
                    event.stopPropagation();
                }
        }
    }
}

@Directive({ selector: '[preventScrolling]' })
export class PreventScrollingDirective implements OnInit, OnDestroy {

    @Input() set preventScrolling(options: PreventScrollingOptions | '') {
        if (typeof options === 'object') {
            this.options = { ...this.options, ...options };
        }
    }

    private options: PreventScrollingOptions = {
        eventTypes: new Set(['scroll', 'wheel', 'touchmove', 'keydown', 'focusout', 'touchend']),
        inherit: false,
        active: true,
    };

    private handler: EventListener;

    // tslint:disable-next-line:no-ununsed-variable
    constructor(private el: ElementRef) {
    }

    public ngOnInit() {
        this.handler = handler.bind(this);
        for (let et of this.options.eventTypes) {
            window.addEventListener(et, this.handler, { passive: false });
        }
        document.body.style.setProperty('overflow', 'hidden');
    }

    public ngOnDestroy() {
        for (let et of this.options.eventTypes) {
            window.removeEventListener(et, this.handler);
        }
        document.body.style.setProperty('overflow', 'inherit');
    }
}
