import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BiClient } from 'src/interfaces/bi-widget';
import { DataFilter } from 'src/interfaces/calculator/QueryLib';
import { ApiService } from './api.service';
import { ApiResponse } from 'src/interfaces/api';
import { NotificationType, NotificationConfigMap, NotificationConfig, WidgetRequestType, NotificationApiResponses, NotificationEntityRequest, NotificationValueType } from 'src/interfaces/notification/notification';
import { NotificationTextGeneratorMap, NotificationTextGeneratorsPick, setupTextGenerators } from 'src/interfaces/notification/notificationTextGenerators';

@Injectable()
export class NotificationService {
    private textGenerators: NotificationTextGeneratorMap;
    private notificationConfigMap: NotificationConfigMap;
    constructor(private apiService: ApiService) {
        this.textGenerators = setupTextGenerators();
        this.notificationConfigMap = new Map();
    }

    public setNotificationConfig(notifiConfigs: NotificationConfig[]) {
        for (let config of notifiConfigs) {
            this.notificationConfigMap.set(config.id, config);
        }
    }

    public getNotificationConfigById(configId: number): NotificationConfig {
        return this.notificationConfigMap.get(configId);
    }

    public arciveNotification(client: BiClient, notificationId: number) {
        return this.apiService.getWithParams<boolean>({client}, 'notification', 'arcive', notificationId);
    }

    public getNotificationConfig(client: BiClient, filter: DataFilter<NotificationConfig> = {}): Observable<ApiResponse<NotificationConfig[]>> {
        return this.apiService.post<DataFilter<NotificationConfig>, NotificationConfig[]>(filter, `notification/config/${client}`);
    }

    public getNotifications(widgetRequestType: WidgetRequestType): Observable<ApiResponse<NotificationApiResponses>> {
        return this.apiService.post<WidgetRequestType, NotificationApiResponses>(widgetRequestType, `notification`);
    }

    public getNotificationsFilterEntities(client: BiClient, notificationEntityRequest: NotificationEntityRequest): Observable<ApiResponse<NotificationValueType[]>> {
        return this.apiService.post<NotificationEntityRequest, NotificationValueType[]>(notificationEntityRequest, `notification/entity/${client}`);
    }

    public getTextGenerator<T extends NotificationType>(notificationType: T): NotificationTextGeneratorsPick<T> {
        return this.textGenerators.get(notificationType);
    }
}
