import { Injectable } from '@angular/core';
import { Role, User } from '../../../interfaces/user';
import { ApiService } from './api.service';

@Injectable()
export class UserService {

    constructor(private apiService: ApiService) { }

    async getUser(id: number): Promise<User> {
        try {
            const res = await this.apiService.get<User>('user', id);
            console.log('getUser', id, res);
            if (res && res.data) {
                console.log('returning ', res.data);
                return res.data;
            }
            return undefined;
        } catch (error) {
            console.error(error);
        }
    }

    async getAllUsers(): Promise<User[]> {
        try {
            const res = await this.apiService.get<User[]>('users');
            if (res && res.data) {
                return res.data;
            }
            return undefined;
        } catch (error) {
            console.error(error);
        }
    }

    async saveUser(user: User): Promise<boolean> {
        let res;
        if (user.id) {
            res = await this.apiService.post<User>(user, 'user', user.id).toPromise();
        } else {
            res = await this.apiService.post<User>(user, 'user', 'new').toPromise();
        }
        console.log('saveUser', user, res);
        if (res && res.data) {
            return true;
        }
        return undefined;
    }

    async activateUser(id: number): Promise<boolean> {
        let res = await this.apiService.get<boolean>('user', 'activate', id);
        if (res && res.data) {
            return true;
        }
        return false;
    }

    async deactivateUser(id: number): Promise<boolean> {
        let res = await this.apiService.get<boolean>('user', 'deactivate', id);
        if (res && res.data) {
            return true;
        }
        return false;
    }

    //@TODO: buttons for role functions are only shown to admin. API checks permissions to execute the change too.
    public async getUserRoles(id: number): Promise<Role[]> {
        let res = await this.apiService.get<Role[]>('user', 'roles', 'get', id);
        if (res && res.data) {
            return res.data;
        }
        return []; //@TODO: throw error?
    }

    public async addUserRole(id: number, role: Role): Promise<boolean> {
        let res = await this.apiService.get<boolean>('user', 'roles', 'add', id, role);
        if (res && res.data) {
            return true;
        }
        return false;
    }

    public async removeUserRole(id: number, role: Role): Promise<boolean> {
        let res = await this.apiService.get<boolean>('user', 'roles', 'remove', id, role);
        if (res && res.data) {
            return true;
        }
        return false;
    }

    async deleteUser(id: number): Promise<boolean> {
        let res = await this.apiService.get<boolean>('user', 'delete', id);
        if (res && res.data) {
            return true;
        }
    }
}
