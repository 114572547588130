// login.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Role } from 'src/interfaces/user';
import { LoginService } from '../service/login.service';

@Injectable()
export class ExportGuard implements CanActivate, CanActivateChild {
    constructor(
        private authService: LoginService
    ) { }

    public async canActivate(): Promise<boolean> {
        //@TODO: need to do something on false? There is no "offical" UI way to reach this of role is not present?
        return this.authService.getUserRoles().indexOf(Role.ADMIN) > -1;
    }

    public canActivateChild(): Promise<boolean> {
        return this.canActivate();
    }

}
