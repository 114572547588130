import { Article } from '../Article';
import { Check } from './Check';

//@TODO: acessors my be better to save RAM
//@TODO: add better logic to extract relevant article properties
export type CheckableArticleFixed = {
    articleGroup: string,
    debitor: string,
    width: number,
    heigth: number,
    color: string //color part has some suffix sometimes. as we are not sorting by color for now, it is ok to have it as string.
};

export type CheckableArticleTargets = {
    ekPrice: number,
    uvp: number,
    debPriceNet: number
};

export type CheckProblem = 'outOfOrder' | 'notEqual';

export enum CheckProblemLabel {
    outOfOrder = 'Falsche Reihenfolge',
    notEqual = 'Ungleichheit'
}

export enum CheckableArticleFixedLabel {
    articleGroup = 'Artikelgruppe',
    debitor = 'Debitor',
    width = 'Breite',
    heigth = 'Länge',
    color = 'Farbe'
}

export enum CheckableArticleTargetLabel {
    ekPrice = 'EK Preis',
    uvp = 'UVP',
    debPriceNet = 'Netto Preis'
}

export type CheckResult = {
    problem: CheckProblem,
    fixedFields: (keyof CheckableArticleFixed)[],
    sortField: keyof CheckableArticleFixed,
    targetField: keyof CheckableArticleTargets,
    encounteredComparator: CheckableArticleWithRef
};

export type CheckableArticle = CheckableArticleFixed & CheckableArticleTargets;

export type CheckableArticleWithRef = CheckableArticle & {
    articleRef: Article,
    checkResults?: CheckResult[];
    hasResult: boolean;
};

export type RegroupedCheckables = Map<string, RegroupedCheckables | CheckableArticleWithRef[]>;

export type ApplicableFunction = (a: CheckableArticleWithRef[]) => void;
/**
 * Takes articles, groups them and can apply checks. Note that it should work by reference. Articles should not be copied. Only check fields are changed here.
 * @TODO: filtering and grouping is partly duplicated from FE logic. But this logic alone wouldn't suffice. Maybe create a abstract grouping helper for all use cases. Maybe even in Database/SQL style functions.
 * @TODO: Reconsider: Do groups in DB. Drawback: Data will be loaded twice
 * @TODO: how to handle articles without number of type XXX.XXX.XXX.XXX
 * @TODO: performance may be a concern.
 */
export class PriceChecker {

    protected allCheckables: CheckableArticleWithRef[];
    protected checks: Check[];

    public static regroup(articles: CheckableArticleWithRef[], ...fields: (keyof CheckableArticleFixed)[]): RegroupedCheckables {
        const regrouped: RegroupedCheckables = new Map();
        const nestFields = Array.from(fields);
        const lastField = nestFields.pop()!;
        // tslint:disable-next-line: no-any
        for (const a of articles) {
            let currentLevel = regrouped;
            for (const field of nestFields) {
                const val = a[field].toString();
                if (!currentLevel.has(val)) {
                    currentLevel.set(val, new Map());
                }
                currentLevel = currentLevel.get(val) as RegroupedCheckables;
            }
            const lastVal = a[lastField].toString();
            if (!currentLevel.has(lastVal)) {
                currentLevel.set(lastVal, []);
            }
            (currentLevel.get(lastVal)! as Array<CheckableArticle>).push(a);
        }
        return regrouped;
    }

    public static getAllGroups(regrouped: RegroupedCheckables): CheckableArticleWithRef[][] {
        const res: CheckableArticleWithRef[][] = [];
        for (let level of regrouped.values()) {
            if (Array.isArray(level)) {
                res.push(level);
            } else {
                res.push(...PriceChecker.getAllGroups(level));
            }
        }
        return res;
    }

    public static addCheckResult(checkable: CheckableArticleWithRef, problem: CheckProblem, fixedFields: (keyof CheckableArticleFixed)[], sortField: keyof CheckableArticleFixed, targetField: keyof CheckableArticleTargets, encounteredComparator: CheckableArticleWithRef): void {
        checkable.hasResult = true;
        checkable.checkResults!.push({
            problem,
            encounteredComparator,
            fixedFields,
            sortField,
            targetField
        });
    }

    /**
     * @TODO: validate the performance. I'm presuming that this is the optimal form without more copies or instances
     * @TODO: consider async
     * @param regrouped groups to iterate
     * @param f function to apply. Needs to work inplace or refer to callers scope
     */
    public static applyToGroups(regrouped: RegroupedCheckables, f: ApplicableFunction): void {
        for (let level of regrouped.values()) {
            if (Array.isArray(level)) {
                f(level);
            } else {
                PriceChecker.applyToGroups(level, f);
            }
        }
    }

    constructor() {
        this.allCheckables = [];
        this.checks = [];
    }

    public setArticles(articles: Article[]): void {
        this.allCheckables = [];
        for (const a of articles) {
            const c = this.toCheckable(a);
            if (c) {
                this.allCheckables.push(c);
            }
        }
    }

    public getAllCheckables(): CheckableArticleWithRef[] {
        return this.allCheckables;
    }

    /**
     * @TODO: how to handle articles without number of type XXX.XXX.XXX.XXX
     */
    protected toCheckable(a: Article): CheckableArticleWithRef | undefined {
        let parts = a.articleNumber.split('.');
        // console.log(parts);
        if (parts.length != 4) {
            return undefined;
        } else if (!this.onlyNumbers(parts[1]) || !this.onlyNumbers(parts[2]) || !this.onlyNumbers(parts[3])) {
            return undefined;
        }
        return {
            articleRef: a,
            articleGroup: a.articleGroup,
            debitor: a.debitorNumber,
            width: parseInt(parts[1], 10),
            heigth: parseInt(parts[2], 10),
            color: parts[3],
            ekPrice: a.ekPrice,
            uvp: a.uvp,
            debPriceNet: a.debPriceNet,
            checkResults: [],
            hasResult: false
        };
    }

    protected onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
      }

    public resetAllCheckables(): void {
        for (const a of this.allCheckables) {
            this.resetTargetFields(a);
        }
    }

    protected resetTargetFields(a: CheckableArticleWithRef): void {
        a.ekPrice = a.articleRef.ekPrice;
        a.uvp = a.articleRef.uvp;
        a.debPriceNet = a.articleRef.debPriceNet;
        a.checkResults = [];
        a.hasResult = false;
        a.articleRef.warnings = [];
    }

    public checkAll(): void {
        for (let check of this.checks) {
            check.check();
        }
        this.propagateCheckResults();
    }

    /**
     * Call this if prices have changed and checks should rerun
     */
    public reloadAndCheck(): void {
        this.resetAllCheckables();
        this.checkAll();
    }

    //@TODO: this is dirty but seems to be the fastest way to forward info from pricechecker to the FE. Refactor.
    //@TODO: maybe the results themself should be set on article. Would be better to create messages in FE, not here....
    public propagateCheckResults(): void {
        for (const a of this.allCheckables) {
            this.propagateCheckResult(a);
        }
    }

    public propagateCheckResult(a: CheckableArticleWithRef): void {
        if (a.hasResult) {
            a.articleRef.warnings = [];
            for (let r of a.checkResults!) {
                a.articleRef.warnings.push(`${CheckProblemLabel[r.problem]} bei ${CheckableArticleTargetLabel[r.targetField]} in Bezug auf ${CheckableArticleFixedLabel[r.sortField]}`);
            }
        }
    }
}
