import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { CubejsClientModule } from '@cubejs-client/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToolLayoutComponent } from './layout/tool-layout.component';
import { LoginLayoutComponent } from './layout/login-layout.component';

import { WidgetService } from './core/service/widget.service';
import { CacheService } from './core/service/cache.service';
import { ApiService } from './core/service/api.service';
import { RouterModule } from '@angular/router';
import { EntityService } from './core/service/entity.service';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './components/login.component';
import { LoginService } from './core/service/login.service';
import { LoginGuard } from './core/guard/login.guard';

import { UserprofileEditComponent } from './components/userprofile-edit.component';
import { UserService } from './core/service/user.service';
import { ArticleDataService } from './core/service/articledata.service';
import { CalculatorGuard } from './core/guard/calculator.guard';
import { ForecastGuard } from './core/guard/forecast.guard';
import { CalculatorService } from './core/service/calculator.service';
import { ForecastDataService } from './core/service/forecastdata.service';
import { ForecastService } from './core/service/forecaster.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SharedModule } from './shared';
import { ProductPurchaseService } from './core/service/product-purchase.service';
import { state } from 'src/state/state';
import { DistributionPlanService } from './core/service/distribution-plan.service';
import { DistributionGuard } from './core/guard/distribution.guard';
import { StocksGuard } from './core/guard/stocks.guard';
import { ExportGuard } from './core/guard/export.guard';
import { Chart } from 'chart.js';
import annotations from 'chartjs-plugin-annotation';
import { PriceCheckerService } from './core/service/priceChecker.service';
import { ReportsGuard } from './core/guard/reports.guard';
// import { PriceMatrixComponent } from './features/price-matrix/price-matrix.component';
import { MatrixService } from './core/service/matrix.service';
import { StockImportService } from './core/service/stock-import-service';
import { ForecastListService } from './core/service/forecaster-list.service';
import { NotificationService } from './core/service/notification.service';


Chart.register(annotations);
Chart.register({
    id: 'increase-legend-spacing',
    afterInit(chart: any) {
        console.log('CHART', chart);
        const fit = chart.legend.fit;
        chart.legend.fit = function() {
            fit.bind(chart.legend)();
            this.height += 10;
        };
    }
});

const cubejsOptions = {
    token: () => { return state.token; },
    options: {
        apiUrl: environment.apiUrl + 'cubejs-api/v1',
    }
};

registerLocaleData(localeDe);

@NgModule({
    declarations: [
        AppComponent,
        ToolLayoutComponent,
        LoginLayoutComponent,
        LoginComponent,
        UserprofileEditComponent,
        // PriceMatrixComponent,
    ],
    imports: [
        SharedModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        RouterModule.forRoot([
            {
                path: '',
                canActivate: [LoginGuard],
                canActivateChild: [LoginGuard],
                component: ToolLayoutComponent,
                children: [
                    {
                        path: 'user',
                        component: UserprofileEditComponent,
                    },
                    {
                        path: 'user/:userId',
                        component: UserprofileEditComponent,
                    },
                    {
                        path: 'bi',
                        loadChildren: () => import('./features/bi').then(m => m.BiModule),
                    },
                    // redirect to maintain compatibility with deep links
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'bi/dashboard',
                    },
                    // redirect to maintain compatibility with deep links
                    {
                        path: 'dashboard',
                        redirectTo: 'bi/dashboard',
                    },
                    // redirect to maintain compatibility with deep links
                    {
                        path: 'details',
                        redirectTo: 'bi/details',
                    },
                    {
                        path: 'notificationDetails',
                        redirectTo: 'bi/notificationDetails',
                    },
                    // redirect to maintain compatibiliy
                    {
                        path: 'forecast-continuous',
                        redirectTo: 'forecast',
                    },
                    {
                        path: 'export',
                        loadChildren: () => import('./features/export').then(m => m.ExportModule),
                        canActivate: [ExportGuard],
                        canActivateChild: [ExportGuard],
                    },
                    {
                        path: 'manual',
                        loadChildren: () => import('./features/manual').then(m => m.ManualModule),
                    },
                    {
                        path: 'calculator',
                        loadChildren: () => import('./features/calculator').then(m => m.CalculatorModule),
                        canActivate: [CalculatorGuard],
                        canActivateChild: [CalculatorGuard],
                    },
                    {
                        path: 'stocks',
                        loadChildren: () => import('./features/stocks').then(m => m.StocksModule),
                        canActivate: [StocksGuard],
                        canActivateChild: [StocksGuard],
                    },
                    {
                        path: 'forecast',
                        loadChildren: () => import('./features/forecast').then(m => m.ForecastModule),
                        canActivate: [ForecastGuard],
                        canActivateChild: [ForecastGuard],
                    },
                    {
                        path: 'distribution',
                        loadChildren: () => import('./features/distribution').then(m => m.DistributionModule),
                        canActivate: [DistributionGuard],
                        canActivateChild: [DistributionGuard],
                    },
                    {
                        path: 'reports',
                        loadChildren: () => import('./features/reports').then(m => m.ReportsModule),
                        canActivate: [ReportsGuard],
                        canActivateChild: [ReportsGuard],
                    },
                    {
                        path: 'crawler',
                        loadChildren: () => import('./features/crawler').then(m => m.CrawlerModule),
                        canActivate: [ReportsGuard],
                        canActivateChild: [ReportsGuard],
                    },
                    {
                        path: 'price-martix',
                        loadChildren: () => import('./features/price-matrix').then(m => m.PriceMatrixModule),
                        canActivate: [ReportsGuard],
                        canActivateChild: [ReportsGuard],
                    },
                    {
                        path: 'forecast',
                        redirectTo: 'forecast-continuous',
                    },
                ]
            },
            {
                path: 'login',
                component: LoginLayoutComponent,
                children: [{
                    path: '',
                    component: LoginComponent,
                }]
            },

        ], {
    onSameUrlNavigation: 'reload'
}),
        CubejsClientModule.forRoot(cubejsOptions),
    ],
    providers: [
        ApiService,
        WidgetService,
        CacheService,
        EntityService,
        LoginService,
        UserService,
        ProductPurchaseService,
        ArticleDataService,
        NotificationService,
        DistributionPlanService,
        StockImportService,
        CalculatorService,
        PriceCheckerService,
        ForecastDataService,
        ForecastService,
        ForecastListService,
        LoginGuard,
        CalculatorGuard,
        ForecastGuard,
        StocksGuard,
        DistributionGuard,
        ReportsGuard,
        ExportGuard,
        MatrixService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
