import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserCredentials, LoginService } from '../core/service/login.service';
import { Validators } from '@angular/forms';
import { BiClient } from 'src/interfaces/bi-widget';
import { takeWhile } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FormControlTS, FormGroupTS } from '@common_node_modules/angular-typesafe-forms';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {

    public form: FormGroupTS<UserCredentials>;
    public authenticating: boolean = false;
    public error: string;
    private alive: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private loginService: LoginService) {
        this.form = new FormGroupTS<UserCredentials>({
            username: new FormControlTS('', [Validators.required]),
            password: new FormControlTS('', [Validators.required]),
            client: new FormControlTS(BiClient.wohnguide, [Validators.required]),
        });
    }

    public ngOnInit() {
        this.route.queryParams.pipe(takeWhile(() => this.alive))
        .subscribe(params => {
            if (params.sessionExpired) {
                this.error = 'Session ist abgelaufen. Bitte erneut einloggen.';
            }
        });
    }

    public ngOnDestroy() {
        this.alive = false;
    }

    public login() {
        this.authenticating = true;
        this.loginService.login(this.form.value).then(success => {
            if (!success) {
                this.authenticating = false;
                this.error = 'Ungültiger Benutzername und/oder Passwort.';
            }
        });
    }
}
