import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { BiClient } from 'src/interfaces/bi-widget';
import { Role, User } from 'src/interfaces/user';
import { state } from 'src/state/state';

export interface UserCredentials {
    username: string;
    password: string;
    client?: BiClient;
}

export interface ApiSession {
    user?: User;
    client?: BiClient;
    cubeToken?: string;
    cookie?: {
        originalMaxAge: number,
        expires: string,
        httpOnly: boolean,
        path: string,
    };
}

@Injectable()
export class LoginService {

//@TODO: should use proper storage to handle the login information

    constructor(
         private router: Router,
         private apiService: ApiService) { }

    public async login(credentials: UserCredentials): Promise<boolean> {
        try {
            const response = await this.apiService.post<UserCredentials, ApiSession>(credentials, 'login').toPromise();
            // console.log('login', response);
            if (response.success && response.data) {
                state.login = true;
                state.cube = response.data.client;
                state.token = response.data.cubeToken;
                state.loggedInUserId = response.data.user.id;
                state.loggedInUserRole = response.data.user.role;
                state.loggedInUserSupplementalRoles = response.data.user.supplementalRoles;
                state.loggedInUserName = response.data.user.username;
                this.router.navigate(['dashboard']);
                return true;
            }
        } catch (error) {
            console.error(error);
        }
        state.login = false;
        return false;
    }

    public async logout() {
        try {
            const response = await this.apiService.post({}, 'logout').toPromise();
            // console.log('logout', response);
        } catch (error) {
            console.error(error);
        }
        state.login = false;
        this.router.navigate(['/login']);
    }

    public async verify() {
        try {
            const response = await this.apiService.get<ApiSession>('verify');
            // console.log('verify', response);
            if (response.success && response.data) {
                state.login = true;
                state.token = response.data.cubeToken;
                state.loggedInUserId = response.data.user.id;
                state.loggedInUserRole = response.data.user.role;
                state.loggedInUserSupplementalRoles = response.data.user.supplementalRoles;
                state.loggedInUserName = response.data.user.username;
                return true;
            }
        } catch (error) {
            console.error(error);
        }
        state.login = false;
        return false;
    }

    public getUserRoles(): Role[] {
        return [state.loggedInUserRole].concat(...state.loggedInUserSupplementalRoles);
    }

    //@TODO: should use guards or the router. But this feature does not exists.
    public isCalculatorUser(): boolean {
        return this.getUserRoles().indexOf(Role.CALC_USER) > -1;
    }

    public isForecastUser(): boolean {
        return this.getUserRoles().indexOf(Role.FORECAST_USER) > -1;
    }

    public isStocksUser(): boolean {
        return this.getUserRoles().indexOf(Role.STOCKS_USER) > -1;
    }

    public isDistributionUser(): boolean {
        return this.getUserRoles().indexOf(Role.DIST_USER) > -1;
    }

    public isReportsUser(): boolean {
        return this.getUserRoles().indexOf(Role.REPORT_USER) > -1;
    }
    // according to Matthias there is no need to make a new Role for Crawler User. The same rights for ReportUser and CrawlerUser
    public isCrawlerUser(): boolean {
        return this.getUserRoles().indexOf(Role.REPORT_USER) > -1;
    }

    public isMatrixUser(): boolean {
        return this.getUserRoles().indexOf(Role.REPORT_USER) > -1;
    }

    public isAdminUser(): boolean {
        return this.getUserRoles().indexOf(Role.ADMIN) > -1;
    }
}

