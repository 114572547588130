// login.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, } from '@angular/router';
import { Role } from 'src/interfaces/user';
import { LoginService } from '../service/login.service';

//@TODO: guess it would be more clean to pass user info and request route to canActivate? Or is this the angular way meant to be?
@Injectable()
export class CalculatorGuard implements CanActivate, CanActivateChild {
    constructor(
        private authService: LoginService
    ) { }

    public async canActivate(): Promise<boolean> {
        //@TODO: need to do something on false? There is no "offical" UI way to reach this of role is not present?
        return this.authService.getUserRoles().indexOf(Role.CALC_USER) > -1;
    }

    public canActivateChild(): Promise<boolean> {
        return this.canActivate();
    }

}
