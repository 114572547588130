import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BiEntity } from 'src/interfaces/bi-widget';
import { TitleForBiEntity } from 'src/app/core/service/widget.service';
import { EntityService, EntityValues } from 'src/app/core/service/entity.service';
import { ProductWithDebitor } from 'src/interfaces/calculator/Product';
import { DataFilter } from 'src/interfaces/calculator/QueryLib';
import { FormatNo } from 'src/interfaces/im-export';

export type ProductFilterOptions = {
    withCrawlerDataOnly?: boolean,
};

export type ProductFilter = DataFilter<ProductWithDebitor>;
export const BiEntityForProductProperty: { [K in keyof ProductWithDebitor]?: BiEntity } = {
    debitorNo: BiEntity.debitor,
    debitorType: BiEntity.debitorType,
    creditorNo: BiEntity.creditor,
    itemStatus: BiEntity.status,
    replenishmentSystem: BiEntity.replenishmentSystem,
    abcTotal: BiEntity.abcTotal,
    abcGroup: BiEntity.abcGroup,
    legacyKey: BiEntity.legacyKey,
    key: BiEntity.productKey,
    mounting: BiEntity.mounting,
    material: BiEntity.material,
    brand: BiEntity.brand,
    colourPim: BiEntity.color,
    width: BiEntity.width,
    length: BiEntity.length,
    productionCountry: BiEntity.producedIn,
    formatNo: undefined,
    debitors: BiEntity.debitor,
};
export const ProductFilterEntities = Object.keys(BiEntityForProductProperty) as Array<keyof ProductWithDebitor>;

export const DefaultProductFilterEntites = ProductFilterEntities.filter(e => e !== 'formatNo' && e !== 'debitors' && e !== 'debitorNo' && e !== 'creditorNo');

export interface SelectObject<T extends string = string> {
    id: T;
    name: string;
}

export function getTitleForProperty(property: keyof ProductWithDebitor) {
    switch (property) {
        case 'formatNo': return 'Interface';
    }
    return TitleForBiEntity[BiEntityForProductProperty[property]];
}


@Component({
    selector: 'app-product-filter',
    templateUrl: './product-filter.component.html',
})
export class ProductFilterComponent<T extends keyof ProductWithDebitor> implements OnInit {
    @Input()
    public disabled: boolean = false;
    @Input()
    public property: T;
    @Input()
    public defaultValue: ProductFilter[T];
    @Input()
    public filterFilter: ProductFilter;
    @Input()
    public options: ProductFilterOptions = {};
    private previousFilter: ProductFilter[T];
    private _filterValue: ProductFilter[T];
    @Output()
    public filterValueChange = new EventEmitter();
    public filterOrder: BiEntity[] = [];
    public filterList: SelectObject[] = [];
    public dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Alle auswählen',
        unSelectAllText: 'Alle auswählen',
        searchPlaceholderText: 'Suchen',
        noDataAvailablePlaceholderText: 'Keine Daten',
        itemsShowLimit: 3,
        enableCheckAll: true,
        allowSearchFilter: true
    };
    @Input() set filterValue(value: ProductFilter[T]) {
        this.previousFilter = this._filterValue;
        this._filterValue = value;
    }
    get filterValue(): ProductFilter[T] {
        return this._filterValue;
    }

    @Input()
    public set itemsShowLimit(no: number) {
        this.dropdownSettings.itemsShowLimit = no;
    }

    constructor(
        private entityService: EntityService,
    ) {

    }

    public async ngOnInit() {
        this.resetFilter();
    }

    public async resetFilter() {
        this.previousFilter = this.filterValue ?? this.defaultValue;
        this.filterValue = this.filterValue ?? this.defaultValue;
        // this.filterValueChange.emit(this.filterValue);
        // console.log('resetFilter', this.property, this.filterFilter);
        let values: EntityValues[] = [];
        if (!BiEntityForProductProperty[this.property]) {
            switch (this.property) {
                case 'formatNo':
                    values = Object.entries(FormatNo).map(([id, name]) => ({ id, name }));
            }
        } else {
            values = await this.entityService.requestEntity(BiEntityForProductProperty[this.property], this.filterFilter, false, this.options.withCrawlerDataOnly);
        }
        switch (this.property) {
            case 'debitors':
            case 'debitorNo':
            case 'creditorNo':
                for (const d of values) {
                    d.name = d.id ? d.id + ' - ' + d.name : d.name;
                }
        }
        const firstEmpty = values.findIndex(v => !v.id);
        if (firstEmpty >= 0) { // filter all empty values except the first
            values = values.filter((v, i) => i <= firstEmpty || v.id);
        }
        this.filterList = values; //.filter(v => v.visible);
        // console.log(values, 'values');
        // console.log(this.filterList, 'this.filterList')
    }

    public get title() {
        return getTitleForProperty(this.property);
    }
    public onSelect() {
        setTimeout(() => {
            let filter = [];
            if (Array.isArray(this.filterValue) && this.filterValue.length > 0) {
                filter = this.filterValue.map(v => v && v.id !== undefined ? v.id ? v.id : null : v);
                if (filter.indexOf(null) >= 0) {
                    filter.push('');
                }
            }
            if (JSON.stringify(filter) !== JSON.stringify(this.previousFilter)) {
                this.previousFilter = filter as ProductFilter[T];
                this.filterValueChange.emit(filter);
            }
        });
    }

}
