import { Injectable } from '@angular/core';
import { BiWidget, BiEntity, BiChart, BiClient, BiDimensions, BiMeasures, BiMembers, BiPresets, BiTimeframe, BiWidgetGroup, GraphBiWidgets, WidgetType } from 'src/interfaces/bi-widget';
import { BiStringDimensions, BiTimeDimensions, BiCountMeasures, BiSumMeasures } from 'src/interfaces/wohnguide-cube';
import { CubeGranularity, CubeOperatorBinary } from 'src/interfaces/cube';
import { EnumForEnum, NumbersForEnum, TextsForEnum, TypeForEnum } from 'src/interfaces/types';
import { ChartType } from 'chart.js';
import { ApiService } from './api.service';
import { PimStringDimensions, PimCountMeasures } from 'src/interfaces/pim-cube';

import * as moment from 'moment';
import { BiQuery, BiQueryFilter, BiQueryTimeDimension } from '../misc/cube-helpers';
import { ProductDimensions } from 'src/interfaces/product-cube';
import { getCurrentMoment } from './date.service';
import { BiWidgetNotificationMultipleSelect } from 'src/app/features/bi/notification-detail.component';

export const OperatorForBiMember: Partial<EnumForEnum<BiMembers, CubeOperatorBinary>> = {
    [BiStringDimensions.orderItemNo]: CubeOperatorBinary.contains,
};

export const ChartTypeForBiChart: EnumForEnum<BiChart, ChartType> = {
    lineTimespan: 'line',
    lineEntity: 'line',
    barEntities: 'bar',
    stackedBarEntities: 'bar', // @TODO: formerly 'horizontalBar'. adapt to chart.js 4
    pieEntity: 'pie',
    mapZip: undefined,
};

export const ChartSizeForBiChart: NumbersForEnum<BiChart> = {
    lineTimespan: 2,
    lineEntity: 2,
    barEntities: 2,
    stackedBarEntities: 1,
    pieEntity: 1,
    mapZip: 1,
};

export const DimensionForBiEntity: EnumForEnum<BiEntity, BiDimensions> = {
    debitor: BiStringDimensions.debitorNo,
    debitorType: undefined, //@TODO: maybe activate it here too
    creditor: undefined,
    status: ProductDimensions.status,
    replenishmentSystem: ProductDimensions.replenishmentSystem,
    abcTotal: ProductDimensions.abcTotal,
    abcGroup: ProductDimensions.abcGroup,
    shippingAgent: BiStringDimensions.orderShippingAgent,
    location: BiStringDimensions.orderItemLocation,
    dayOfWeek: BiStringDimensions.orderDayOfWeek,
    weekOfYear: BiStringDimensions.orderWeekOfYear,
    monthOfYear: BiStringDimensions.orderMonthOfYear,
    year: BiStringDimensions.orderYear,
    productGroup: BiStringDimensions.orderItemNo,
    customerGender: BiStringDimensions.recipientGender,
    customerZip: BiStringDimensions.recipientZipRange,
    productKey: BiStringDimensions.orderItemKey,
    legacyKey: BiStringDimensions.orderItemNo1,
    type: PimStringDimensions.type,
    mounting: PimStringDimensions.mounting,
    material: PimStringDimensions.material,
    brand: PimStringDimensions.brand,
    color: PimStringDimensions.color,
    width: PimStringDimensions.width,
    length: PimStringDimensions.length,
    shippingType: PimStringDimensions.shippingType,
    parcelType: BiStringDimensions.orderItemParcelType,
    producedIn: PimStringDimensions.producedIn,
};

export const GranularityForBiChart: Partial<EnumForEnum<BiChart, CubeGranularity>> = {
    lineTimespan: CubeGranularity.day,
    lineEntity: CubeGranularity.day,
};

export const LimitForBiChart: Partial<NumbersForEnum<BiChart>> = {
    barEntities: 20,
    stackedBarEntities: 12,
    pieEntity: 12,
    mapZip: 1000,
    lineEntity: 12,
    lineTimespan: 2,
};

export const TitleForBiEntity: TextsForEnum<BiEntity> = {
    debitor: 'Debitor',
    debitorType: 'Debitorengruppe',
    creditor: 'Kreditor',
    status: 'Status',
    replenishmentSystem: 'Beschaffungsmethode',
    abcTotal: 'ABC (Gesamt)',
    abcGroup: 'ABC (Gruppe)',
    location: 'Lager',
    shippingAgent: 'Versandart',
    dayOfWeek: 'Wochentag',
    weekOfYear: 'Kalenderwoche',
    monthOfYear: 'Monat',
    year: 'Jahr',
    customerGender: 'Geschlecht Kunde',
    customerZip: 'PLZ-Region Kunde',
    productGroup: 'Artikelnummer',
    productKey: 'Kürzel (Item Code)',
    legacyKey: 'Kürzel (Artikelnummer)',
    type: 'Produkttyp (PIM)',
    mounting: 'Befestigungsart',
    material: 'Material',
    brand: 'Marke',
    color: 'Farbe',
    width: 'Breite',
    length: 'Länge',
    shippingType: 'Vorgesehene Versandart',
    parcelType: 'Pakettyp',
    producedIn: 'Produktionsland',
};

export const TitleForBiPreset: TextsForEnum<BiPresets> = {
    orders: 'Bestellungen',
    ordersPrel: 'Bestellungen (vorl.)',
    sales: 'Umsatz',
    returns: 'Retouren',
    cancellations: 'Stornierungen',
    items: 'Summe Artikel',
    region: 'PLZ-Karte (Best., nur Endkunden)',
    customerOrdersPrel: 'Bestellungen (vorl., nur Endkunden)',
    custom: '[Eigene Auswahl]',
};

export const TitleForBiMeasure: TextsForEnum<BiMeasures> = {
    [BiCountMeasures.orderCount]: 'Bestellungen',
    [BiCountMeasures.customerOrderCount]: 'Bestellungen (nur Endk.)',
    [BiCountMeasures.orderCountPrel]: 'Bestellungen (vorl.)',
    [BiCountMeasures.customerOrderCountPrel]: 'Bestellungen (vorl., nur Endk.)',
    [BiCountMeasures.cancelCount]: 'Stornierungen',
    [BiCountMeasures.customerCancelCount]: 'Stornierungen (nur Endk.)',
    [BiCountMeasures.returnCount]: 'Retouren',
    [BiCountMeasures.customerReturnCount]: 'Retouren (nur Endk.)',
    [BiSumMeasures.orderItemQuantity]: 'Bestellte Artikel',
    [BiSumMeasures.customerOrderItemQuantity]: 'Bestellte Artikel (nur Endk.)',
    [BiSumMeasures.orderItemQuantityPrel]: 'Bestellte Artikel (vorl.)',
    [BiSumMeasures.customerOrderItemQuantityPrel]: 'Bestellte Artikel (vorl., nur End.)',
    [BiSumMeasures.cancelItemQuantity]: 'Stornierte Artikel',
    [BiSumMeasures.customerCancelItemQuantity]: 'Stornierte Artikel (nur Endk.)',
    [BiSumMeasures.returnItemQuantity]: 'Retournierte Artikel',
    [BiSumMeasures.customerReturnItemQuantity]: 'Retournierte Artikel (nur Endk.)',
    [BiSumMeasures.scrapItemQuantity]: 'Verschrottete Artikel',
    [BiSumMeasures.customerScrapItemQuantity]: 'Verschrottete Artikel (nur Endk.)',
    [BiSumMeasures.orderItemSum]: 'Umsatz Bestellungen',
    [BiSumMeasures.customerOrderItemSum]: 'Umsatz Bestellungen (nur Endk.)',
    [BiSumMeasures.orderItemSumPrel]: 'Umsatz Bestellungen (vorl.)',
    [BiSumMeasures.customerOrderItemSumPrel]: 'Umsatz Bestellungen (vorl., nur Endk.)',
    [BiSumMeasures.cancelItemSum]: 'Umsatz Stornierungen',
    [BiSumMeasures.customerCancelItemSum]: 'Umsatz Stornierungen (nur Endk.)',
    [BiSumMeasures.returnItemSum]: 'Umsatz Retouren',
    [BiSumMeasures.customerReturnItemSum]: 'Umsatz Retouren (nur Endk.)',
    [BiSumMeasures.scrapItemSum]: 'Umsatz verschrottete Retouren',
    [BiSumMeasures.customerScrapItemSum]: 'Umsatz verschrottete Retouren (nur Endk.)',
    [BiSumMeasures.ledgerSum]: 'Umsatz bereinigt',
    [BiSumMeasures.orderItemDiscount]: 'Summe Rabatte',
    [BiSumMeasures.orderCostSum]: 'Kosten (nach akt. EK)',
    [BiSumMeasures.itemsPerParcel]: 'Artikel pro Bestellung',
    [PimCountMeasures.productCount]: 'Anzahl Produkttyp (PIM)',
};

export const TableColumns: BiMeasures[] = [
    BiCountMeasures.orderCount,
    BiCountMeasures.customerOrderCount,
    BiCountMeasures.returnCount,
    BiCountMeasures.customerReturnCount,
    BiCountMeasures.cancelCount,
    BiCountMeasures.customerCancelCount,
    BiSumMeasures.orderItemQuantity,
    BiSumMeasures.customerOrderItemQuantity,
    BiSumMeasures.cancelItemQuantity,
    BiSumMeasures.customerCancelItemQuantity,
    BiSumMeasures.returnItemQuantity,
    BiSumMeasures.customerReturnItemQuantity,
    BiSumMeasures.scrapItemQuantity,
    BiSumMeasures.customerScrapItemQuantity,
    BiSumMeasures.orderItemSum,
    BiSumMeasures.customerOrderItemSum,
    BiSumMeasures.cancelItemSum,
    BiSumMeasures.customerCancelItemSum,
    BiSumMeasures.returnItemSum,
    BiSumMeasures.customerReturnItemSum,
    BiSumMeasures.scrapItemSum,
    BiSumMeasures.customerScrapItemSum,
    BiSumMeasures.ledgerSum,
    BiCountMeasures.orderCountPrel,
    BiCountMeasures.customerOrderCountPrel,
    BiSumMeasures.orderItemQuantityPrel,
    BiSumMeasures.customerOrderItemQuantityPrel,
    BiSumMeasures.orderItemSumPrel,
    BiSumMeasures.customerOrderItemSumPrel,
    BiSumMeasures.orderCostSum,
    BiSumMeasures.itemsPerParcel,
];

export const PrelTableColumns: BiMeasures[] = [
    BiCountMeasures.orderCountPrel,
    BiCountMeasures.customerOrderCountPrel,
    BiSumMeasures.orderItemQuantityPrel,
    BiSumMeasures.customerOrderItemQuantityPrel,
    BiSumMeasures.orderItemSumPrel,
    BiSumMeasures.customerOrderItemSumPrel,
];

export const ColumnsForPresets: TypeForEnum<BiMeasures[], BiPresets> = {
    orders: [
        BiCountMeasures.orderCount,
        BiSumMeasures.orderItemQuantity,
        BiSumMeasures.itemsPerParcel,
        BiCountMeasures.cancelCount,
        BiCountMeasures.returnCount,
        BiSumMeasures.orderItemSum,
        BiSumMeasures.orderCostSum,
    ],
    sales: [
        BiSumMeasures.orderItemSum,
        BiSumMeasures.cancelItemSum,
        BiSumMeasures.returnItemSum,
        BiSumMeasures.scrapItemSum,
        BiSumMeasures.ledgerSum
    ],
    returns: [
        BiCountMeasures.orderCount,
        BiSumMeasures.orderItemQuantity,
        BiCountMeasures.returnCount,
        BiSumMeasures.returnItemSum,
        BiSumMeasures.returnItemQuantity,
        BiSumMeasures.scrapItemQuantity,
        BiSumMeasures.scrapItemSum,
    ],
    cancellations: [
        BiCountMeasures.orderCount,
        BiSumMeasures.orderItemQuantity,
        BiCountMeasures.cancelCount,
        BiSumMeasures.orderItemSum,
        BiSumMeasures.cancelItemQuantity,
        BiSumMeasures.cancelItemSum,
    ],
    items: [
        BiSumMeasures.orderItemQuantity,
        BiSumMeasures.cancelItemQuantity,
        BiSumMeasures.returnItemQuantity,
        BiSumMeasures.scrapItemQuantity,
    ],
    ordersPrel: [
        BiCountMeasures.orderCountPrel,
        BiSumMeasures.orderItemQuantityPrel,
        BiSumMeasures.orderItemSumPrel,
    ],
    customerOrdersPrel: [
        BiCountMeasures.customerOrderCountPrel,
        BiSumMeasures.customerOrderItemQuantityPrel,
        BiSumMeasures.customerOrderItemSumPrel,
    ],
    region: [
        BiCountMeasures.customerOrderCount,
        BiSumMeasures.customerOrderItemQuantity,
        BiSumMeasures.itemsPerParcel,
        BiCountMeasures.customerCancelCount,
        BiCountMeasures.customerReturnCount,
        BiSumMeasures.customerOrderItemSum,
        BiSumMeasures.orderCostSum,
    ],
    custom: undefined,
};

export const MeasuresForPresets: TypeForEnum<BiMeasures, BiPresets> = {
    orders: BiCountMeasures.orderCount,
    ordersPrel: BiCountMeasures.orderCountPrel,
    customerOrdersPrel: BiCountMeasures.customerOrderCountPrel,
    sales: BiSumMeasures.orderItemSum,
    returns: BiCountMeasures.returnCount,
    cancellations: BiCountMeasures.cancelCount,
    items: BiSumMeasures.orderItemQuantity,
    region: BiCountMeasures.customerOrderCount,
    custom: undefined,
};

export const ChartTypesForPresets: TypeForEnum<BiChart, BiPresets> = {
    orders: BiChart.lineEntity,
    ordersPrel: BiChart.lineEntity,
    customerOrdersPrel: BiChart.lineEntity,
    sales: BiChart.lineEntity ,
    returns: BiChart.lineEntity,
    cancellations: BiChart.lineEntity,
    items: BiChart.lineEntity,
    region: BiChart.mapZip,
    custom: undefined,
};

export function getChartLimitForWidget(widget: GraphBiWidgets): number {
    if (widget) {
        switch (widget.chart) {
            case BiChart.lineEntity:
            case BiChart.lineTimespan:
                switch (widget.entity) {
                    case BiEntity.weekOfYear:
                        return 53;
                    default:
                        return LimitForBiChart[widget.chart];
                }
            default:
                return LimitForBiChart[widget.chart];
        }
    }
    return undefined;
}

const YtdWidgetSales: GraphBiWidgets = {
    id: 111111,
    name: 'Umsatz YTD',
    type: 'graph',
    entity: BiEntity.debitor,
    preset: BiPresets.sales,
    measure: BiSumMeasures.orderItemSum,
    chart: BiChart.lineTimespan,
    columns: ColumnsForPresets[BiPresets.sales],
    filter: {},
    timeframe: BiTimeframe.year,
    compTimeframe: BiTimeframe.year,
    offset: 0,
    disabled: true,
};

const YtdWidgetOrders: GraphBiWidgets = {
    id: 222222,
    name: 'Bestellungen YTD',
    type: 'graph',
    entity: BiEntity.debitor,
    preset: BiPresets.orders,
    measure: BiCountMeasures.orderCount,
    chart: BiChart.lineTimespan,
    columns: ColumnsForPresets[BiPresets.orders],
    filter: {},
    timeframe: BiTimeframe.year,
    compTimeframe: BiTimeframe.year,
    offset: 0,
    disabled: true,
};

const YtdWidgetReturns: GraphBiWidgets = {
    id: 3333333,
    name: 'Retouren YTD',
    type: 'graph',
    entity: BiEntity.debitor,
    preset: BiPresets.returns,
    measure: BiCountMeasures.returnCount,
    chart: BiChart.lineTimespan,
    columns: ColumnsForPresets[BiPresets.returns],
    filter: {},
    timeframe: BiTimeframe.year,
    compTimeframe: BiTimeframe.year,
    offset: 0,
    disabled: true,
};

const YtdWidgetsCancellations: GraphBiWidgets = {
    id: 444444,
    name: 'Stornierungen YTD',
    type: 'graph',
    entity: BiEntity.debitor,
    preset: BiPresets.cancellations,
    measure: BiCountMeasures.cancelCount,
    chart: BiChart.lineTimespan,
    columns: ColumnsForPresets[BiPresets.cancellations],
    filter: {},
    timeframe: BiTimeframe.year,
    compTimeframe: BiTimeframe.year,
    offset: 0,
    disabled: true,
};

@Injectable()
export class WidgetService {

    constructor(private apiService: ApiService) { }

    public getTitleForBiMeasure(measure: BiMeasures) {
        return TitleForBiMeasure[measure];
    }

    public getDimensionForBiEntity(entity: BiEntity) {
        return DimensionForBiEntity[entity];
    }

    public getGranularityForWidget(widget: GraphBiWidgets): CubeGranularity {
        switch (widget.chart) {
            case BiChart.lineEntity:
            case BiChart.lineTimespan:
                const months = widget.timeframe ? this.getMonthsForTimeframe(widget.timeframe) : moment(widget.dateEnd).diff(moment(widget.dateStart), 'months');
                return months > 35 ? CubeGranularity.month : months > 5 ? CubeGranularity.week : CubeGranularity.day;
            default:
                return GranularityForBiChart[widget.chart];
        }
    }

    public getLimitForForBiChart(chart: BiChart): number {
        return 10000;
    }

    private transformFilter(widget: GraphBiWidgets): BiQueryFilter[] {
        const filters: BiQueryFilter[] = [];
        for (const [e, f] of Object.entries(widget.filter)) {
            if (f.length) {
                const member = this.getDimensionForBiEntity(e as BiEntity);
                const operator: CubeOperatorBinary = OperatorForBiMember[member] ? OperatorForBiMember[member] : CubeOperatorBinary.equals;
                const filter = f.map(v => v ? v : null);
                if (filter.indexOf(null) >= 0) {
                    filter.push('');
                }
                if (typeof filter[0] === 'string' && filter[0].includes('*')) {
                    const wildParts = filter[0].split('*');
                    for (const wildPart of wildParts) {
                        filters.push({ member, operator: CubeOperatorBinary.contains, values: [wildPart] });
                    }
                } else {
                    filters.push({ member, operator, values: filter });
                }
            }
        }
        // filters.push({
        //     member: BiCountMeasures.orderCount,
        //     operator: CubeOperator.gt,
        //     values: ['0'],
        // });
        // console.log('transformFilter', widget.filter, filters);
        return filters;
    }

    private getMonthsForTimeframe(timeframe: BiTimeframe) {
        switch (timeframe) {
            case 'year': return 12;
            case 'quarter': return 4;
            case 'month': return 1;
            case 'isoWeek': return 0.25;
        }
        return 0;
    }

    private getBasicQuery(widget: GraphBiWidgets): BiQuery {
        const measures = [widget.measure];
        const dimensions = [this.getDimensionForBiEntity(widget.entity)];
        return {
            measures,
            dimensions,
            filters: this.transformFilter(widget),
            order: {
                [widget.measure]: 'desc',
            }
        };
    }

    public getTimeDimensionForWidget(widget: GraphBiWidgets): BiTimeDimensions {
        // console.log('getting time dimension', widget);
        if (widget.columns && widget.columns[0] && (widget.columns[0].indexOf('Prel') > -1)) {
            return BiTimeDimensions.prelOrderDate;
        }
        return BiTimeDimensions.orderDate;
    }

    public getQueryForTopEntities(widget: GraphBiWidgets): BiQuery {
        const query = this.getBasicQuery(widget);
        query.timeDimensions = [{
            dimension: this.getTimeDimensionForWidget(widget), //@TODO: date range should be in sync with granularity
            dateRange: [moment.utc(widget.dateStart).startOf('day').toISOString(), moment.utc(widget.dateEnd).endOf('day').toISOString()],
            granularity: null
        }];
        query.limit = getChartLimitForWidget(widget);
        // console.log('getQueryForTopEntities', widget, query);
        return query;
    }

    public getQueryForTable(widget: GraphBiWidgets, compare = false, allowNested = true): BiQuery {
        const query = this.getBasicQuery(widget);
        query.measures = widget.columns;
        query.timeDimensions = this.getTimeDimensions(widget, false, compare);
        if (widget.entity2 && allowNested) {
            switch (widget.chart) {
                case BiChart.barEntities:
                case BiChart.stackedBarEntities:
                    query.dimensions.push(this.getDimensionForBiEntity(widget.entity2));
                    break;
            }
        }
        // console.log(compare ? 'getQueryForComparisonTable' : 'getQueryForTable', widget, query);
        return query;
    }

    private getRollingDate(widget: GraphBiWidgets) {
        return getCurrentMoment().add(widget.offset, widget.timeframe as moment.unitOfTime.DurationConstructor);
    }

    public getRealDateStart(widget: GraphBiWidgets) {
        return widget.timeframe ? this.getRollingDate(widget).startOf(widget.timeframe) : moment.utc(widget.dateStart).startOf('day');
    }

    public getRealDateEnd(widget: GraphBiWidgets) {
        return widget.timeframe ? this.getRollingDate(widget).endOf(widget.timeframe) : moment.utc(widget.dateEnd).endOf('day');
    }

    public getRealDateCompStart(widget: GraphBiWidgets) {
        return widget.timeframe ? this.getRollingDate(widget).subtract(widget.compTimeframe ? 1 : 0, widget.compTimeframe ? widget.compTimeframe as any : 'days').startOf(widget.timeframe) : moment.utc(widget.dateCompStart).startOf('day');
    }

    public getRealDateCompEnd(widget: GraphBiWidgets) {
        return widget.timeframe ? this.getRollingDate(widget).subtract(widget.compTimeframe ? 1 : 0, widget.compTimeframe ? widget.compTimeframe as any : 'days').endOf(widget.timeframe) : moment.utc(widget.dateCompEnd).endOf('day');
    }

    private getTimeDimensions(widget: GraphBiWidgets, chart = true, compare = false): BiQueryTimeDimension[] {
        let dateStart: moment.Moment, dateEnd: moment.Moment;
        dateStart = compare || (chart && widget.chart === BiChart.lineTimespan) ? this.getRealDateCompStart(widget) : this.getRealDateStart(widget);
        dateEnd = compare ? this.getRealDateCompEnd(widget) : this.getRealDateEnd(widget);
        return [{
            dimension: this.getTimeDimensionForWidget(widget), //@TODO: date range should be in sync with granularity
            dateRange: [dateStart.toISOString(), dateEnd.toISOString()],
            granularity: chart ? this.getGranularityForWidget(widget) : null,
        }];
    }

    public getQueryForWidget(widget: GraphBiWidgets): BiQuery {
        const query = this.getBasicQuery(widget);
        query.timeDimensions = this.getTimeDimensions(widget);
        if (widget.chart === BiChart.lineTimespan) {
            const dateCompEnd = this.getRealDateCompEnd(widget);
            const dateStart = this.getRealDateStart(widget);
            if (dateStart.isAfter(dateCompEnd)) {
                // Because we query both Timeframes at once, we need to remove the portion not selected.
                // Unfortunately, there is no other way to do this in CUBE
                query.filters.push({
                    member: this.getTimeDimensionForWidget(widget),
                    operator: CubeOperatorBinary.notInDateRange,
                    values: [
                        dateCompEnd.toISOString(),
                        dateStart.subtract(1, 'days').toISOString(),
                    ],
                });
            }
            delete query.dimensions;
        }
        query.limit = 10000;
        if (widget.entity2) {
            switch (widget.chart) {
                case BiChart.barEntities:
                case BiChart.stackedBarEntities:
                    query.dimensions.push(this.getDimensionForBiEntity(widget.entity2));
                    break;
            }
        }
        // console.log('getQueryForWidget', widget, query);
        return query;
    }

    public createNotificationWidget(): BiWidgetNotificationMultipleSelect{
        return { 
            name: '',
            type: 'notification',
            filter: {},
            sort: [{ field: 'priority', direction: 'DESC' }],
            widgetGroupId: null,
            notificationType: [],
            configIds: [],
        };
    }

    public createWidget(): GraphBiWidgets {
        return {
            name: '',
            type: 'graph',
            widgetGroupId: null,
            dateStart: getCurrentMoment().startOf('year').format('YYYY-MM-DD'),
            dateEnd: getCurrentMoment().endOf('year').format('YYYY-MM-DD'),
            entity: null,
            measure: null,
            chart: null,
            filter: {},
        };
    }

    public async getWidgets(client: BiClient): Promise<BiWidget[]> {
        const res = await this.apiService.get<BiWidget[]>('widgets', client);
        if (res && res.data) {
            return res.data;
        }
        return [];
    }

    public async getWidget(id: number | string, widgetType: WidgetType): Promise<BiWidget> {
        if(widgetType == 'graph'){
            if (!id) {
                return this.createWidget();
            }
            switch (id) {
                case 'sums': return YtdWidgetSales;
                case 'orders': return YtdWidgetOrders;
                case 'returns': return YtdWidgetReturns;
                case 'cancellations': return YtdWidgetsCancellations;
                default:
                    const res = await this.apiService.get<BiWidget>('widget', id);
                    if (res && res.data) {
                        if(widgetType != res.data.type) {
                            return this.createWidget();
                        }
                        return res.data;
                    }
            }
        } else if(widgetType == 'notification'){
            if (!id) {
                return this.createNotificationWidget();
            } else{
                const res = await this.apiService.get<BiWidget>('widget', id);
                return res.data;
            }
        }
        return null;
    }

    public async saveWidget(widget: BiWidget): Promise<boolean> {
        if(widget.type == 'graph') {
            this.fixWidgetDates(widget);
        }
        const res = await this.apiService.post(widget, 'widget', 'save').toPromise();
        console.log('saveWidget', res);
        if (res && res.data) {
            return res.data.affectedRows > 0;
        }
        return false;
    }

    public async deleteWidget(widget: { id: number }): Promise<boolean> {
        const res = await this.apiService.post(widget, 'widget', 'delete').toPromise();
        console.log('deleteWidget', res);
        if (res && res.data) {
            return res.data.affectedRows > 0;
        }
        return false;
    }

    public fixWidgetDates(widget: GraphBiWidgets): void {
        widget.dateStart = moment.utc(widget.dateStart).toISOString();
        widget.dateEnd = moment.utc(widget.dateEnd).toISOString();
        if (widget.dateCompStart) {
            widget.dateCompStart = moment.utc(widget.dateCompStart).toISOString();
        }
        if (widget.dateCompEnd) {
            widget.dateCompEnd = moment.utc(widget.dateCompEnd).toISOString();
        }
    }

    public async saveWidgetGroup(widgetGroup: BiWidgetGroup): Promise<boolean> {
        const res = await this.apiService.post(widgetGroup, 'widgetgroup', 'save').toPromise();
        console.log('saveWidgetGroup', res);
        if (res && res.data) {
            return res.data.affectedRows > 0;
        }
        return false;
    }

    public async deleteWidgetGroup(id: number): Promise<boolean> {
        const res = await this.apiService.post({ id }, 'widgetgroup', 'delete', id).toPromise();
        console.log('deleteeWidgetGroup', res);
        if (res && res.data) {
            return res.data.affectedRows > 0;
        }
        return false;
    }

    public async getWidgetGroup(id: number): Promise<BiWidgetGroup> {
        const res = await this.apiService.get<BiWidgetGroup>(id, 'widgetgroup', 'save');
        console.log('getWidgetGroup', res);
        if (res && res.data) {
            return res.data;
        }
        return null;
    }

    public async getWidgetGroups(client: BiClient): Promise<BiWidgetGroup[]> {
        const res = await this.apiService.get<BiWidgetGroup[]>('widgetgroups', client);
        console.log('getWidgetGroups', res);
        if (res && res.data) {
            return res.data;
        }
        return [];
    }

    public async getWidgetsInGroup(id: number): Promise<BiWidget[]> {
        const res = await this.apiService.get<BiWidget[]>('widgetgroup', 'widgets', id);
        console.log('getWidgetsInGroup', res);
        if (res && res.data) {
            return res.data;
        }
        return [];
    }

    public async getFavouriteWidgets(client: BiClient): Promise<BiWidget[]> {
        const res = await this.apiService.get<BiWidget[]>('widgetgroup', 'widgets', 'favourites', client);
        console.log('getFavouriteWidgets', res);
        if (res && res.data) {
            return res.data;
        }
        return [];
    }

}
