import { Component, HostListener, OnInit } from '@angular/core';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: []
})
export class AppComponent implements OnInit {

    public innerWidth: number = 0;
    public innerHeight: number = 0;
    constructor(
    ) {
        //
    }
    public async ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;
        // console.log('INIT Width:', this.innerWidth, 'Height:', this.innerHeight);
        document.documentElement.style.setProperty('width', this.innerWidth + 'px');
        document.documentElement.style.setProperty('height', this.innerHeight + 'px');
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;
        // console.log('Width:', this.innerWidth, 'Height:', this.innerHeight);
        document.documentElement.style.setProperty('width', this.innerWidth + 'px');
        document.documentElement.style.setProperty('height', this.innerHeight + 'px');
    }
}
