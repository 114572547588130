import { SimpleProductWithStockData, SimpleProductWithStockHistory } from '../calculator/Product';
import { ABCValue, AdditionalConsumptionItem, ArticleForecast, ArticleForecastWithStockData, Consumption, dateStringToMonth, ForecastElement, ForecastElements, ForecastMethod, ForecastStatus, PalletData, Relocation, RelocationList, StockData, Warehouses, YHats } from './Article';
import moment, { Moment } from 'moment';
import { BiDimensions, BiMeasures } from '../bi-widget';
import { BiStringDimensions, BiTimeDimensionsWithGranularity } from '../wohnguide-cube';

type BiLoadResponseResult = { [K in BiDimensions]?: string } &
    { [K in BiTimeDimensionsWithGranularity]?: string } &
    { [K in BiMeasures]?: number };


type ConsumptionForcastExtension = number;

export class Forecaster {

    protected forecasts: Map<string, ArticleForecast>;
    //@TODO: add dropdowns or datepicker to select this. Options may include the periods that are in the forecast
    protected startingPeriod: Date;
    protected startingDay: Date;
    protected startingOffset: number;
    protected endPeriod: Date;
    protected maxPeriods: number;
    protected availablePeriods: number;
    protected availableEndPeriod: Date;
    protected defaultPackageSize: number;
    protected defaultStock: number;
    protected usableStatus: ForecastStatus[] = [ForecastStatus.SUCCESS, ForecastStatus.PROPHET_ERROR, ForecastStatus.UNCERTAIN];

    public static copyConsumptions(cs: Consumption[]): Consumption[] {
        let res: Consumption[] = [];
        if (Array.isArray(cs)) {
            for (let c of cs) {
                res.push(Object.assign({}, c));
            }
        }
        return res;
    }

    public static deaggregateConsumptions(cs: Consumption[]): Consumption[] {
        let res: Consumption[] = [];
        if (cs.length > 0) {
            res.push({
                amount: cs[0].amount,
                month: cs[0].month
            });
            if (cs.length > 1) {
                for (let i = 1; i < cs.length; i++) {
                    res.push({
                        amount: cs[i].amount - cs[i - 1].amount,
                        month: cs[i].month
                    });
                }
            }
        }
        return res;
    }

    constructor(private getCurrentMoment: () => Moment) {
        this.forecasts = new Map();
        this.startingPeriod = this.getCurrentMoment().startOf('month').toDate();
        this.startingDay = this.getCurrentMoment().toDate();
        this.startingOffset = moment(this.startingDay).diff(this.startingPeriod, 'days');
        this.maxPeriods = 18;
        this.endPeriod = this.getCurrentMoment().startOf('month').add(this.maxPeriods, 'months').toDate();
        this.availablePeriods = 18;
        this.availableEndPeriod = this.getCurrentMoment().startOf('month').add(this.availablePeriods, 'months').toDate();
        this.defaultPackageSize = 6;
        this.defaultStock = 0;
    }

    /**
     * You need to call this before applying anything.
     * Needs to be the same local and remote
     * @TODO: should be cleaner and transferred between local and remote
     * @param maxPeriods
     */
    public setMaxPeriods(maxPeriods: number): void {
        this.maxPeriods = maxPeriods;
        this.endPeriod = this.getCurrentMoment().startOf('month').add(this.maxPeriods, 'months').toDate();
    }

    protected getArticleNumberPrefix(a: ArticleForecast): string {
        return a.articleNumber.replace(/^RO\./, '').split(/[^0-9A-Za-z]/)[0];
    }

    public isProductWithStockData(product: SimpleProductWithStockData | SimpleProductWithStockHistory): product is SimpleProductWithStockHistory {
        return Array.isArray((product as SimpleProductWithStockHistory).stockDateHistory);
    }

    protected createConsumptions<T extends number | null | Consumption[]>(value: T): Record<ForecastMethod, T> {
        return {
            ownFC: value,
            lm: value,
            perc: value,
            combi: value,
            mean: value,
        };
    }

    public transform(product: SimpleProductWithStockData | SimpleProductWithStockHistory): ArticleForecast {
        let forecast: ArticleForecast = {
            status: ForecastStatus.UNKNOWN,
            isNewProduct: false,
            articleGroup: product.productGroup,
            articleNumber: product.number,
            ean: product.ean!,
            size: product.description2 ? product.description2 : '',
            color: product.colourPim,
            description: product.description,
            discontinued: false, //@TODO: get it
            width: product.width,
            length: product.length,
            creditorNumber: product.creditorNo,
            packageSize: product.lotSize > 0 ? product.lotSize : this.defaultPackageSize,
            location: product.location,
            currentStock: product.invNohra + product.invLFD + product.invHannover + product.invHIG + product.invZW,
            //examples based on April 2021
            lastYearConsumption: null, //sum of consumption in 2020
            nextLastPeriodConsumption: [], //consumption April to September 2019
            fullNextLastPeriodConsumption: null, //sum of the above
            lastPeriodConsumption: [], //consumption April to September 2020
            fullLastPeriodConsumption: null, //sum of the above
            lastPeriodBeforeConsumption: [], //consumption November 2019 to April 2020
            currentPeriodBeforeConsumption: [], //consumption November 2020 to April 2021
            ytdConsumption: [],
            fullYtdConsumption: null,
            predictedConsumption: this.createConsumptions(null),
            predictedConsumptions: this.createConsumptions([]),
            predictedLowerBoundConsumptions: this.createConsumptions([]),
            predictedUpperBoundConsumptions: this.createConsumptions([]),
            choosenConsumption: null,
            choosenConsumptions: [],
            choosenLowerBoundConsumption: [],
            choosenUpperBoundConsumption: [],
            fullConsumption: 0,
            fullConsumptions: [],
            fullLowerBoundConsumptions: [],
            fullUpperBoundConsumptions: [],
            openPurchase: product.pending,
            additionalArbitraryConsumption: [],
            fullAdditionalArbitraryConsumption: 0,
            abcFull: { value: ABCValue.UNKNOWN, rank: 0, of: 0 },
            abcSupport: { value: ABCValue.UNKNOWN, rank: 0, of: 0 },
            shouldOrder: false,
            recommendedOrderAmount: 0,
            predictedCurrentReach: this.startingPeriod,
            predictedReach: this.startingPeriod,
            overreached: false,
            currentFinalStock: 0,
            predictedFinalStock: 0,
            choosenPrediction: null
        };
        forecast.articleGroup = this.getArticleNumberPrefix(forecast); //@TODO: use the correct on as soon as customer has a good solution
        if (this.isProductWithStockData(product)) {
            // console.log('PRODUCT', product);
            forecast.stocks = {
                dates: product.stockDateHistory,
                nohra: {
                    current: product.invNohra,
                    history: product.invNohraHistory,
                    outOfStock: product.invNohraExpDate,
                    ve: product.lotSize
                },
                heiligenstadt: {
                    current: product.invHIG,
                    history: product.invHIGHistory,
                    outOfStock: product.invHIGExpDate,
                    ve: product.lotSize
                },
                leinefelde: {
                    current: product.invLFD,
                    history: product.invLFDHistory,
                    smooth: product.invLFDSmooth,
                    outOfStock: product.invLFDExpDate,
                    ve: product.lotSize
                },
                hannover: {
                    current: product.invHannover,
                    history: product.invHannoverHistory,
                    smooth: product.invHannoverSmooth,
                    outOfStock: product.invHannoverExpDate,
                    ve: product.lotSize
                },
                zwischen: {
                    current: product.invZW,
                    history: product.invZWHistory,
                    outOfStock: product.invZWExpDate,
                    ve: product.lotSize
                },
            };
        } else {
            forecast.stocks = {
                nohra: product.invNohra,
                heiligenstadt: product.invHIG,
                leinefelde: product.invLFD,
                hannover: product.invHannover,
                zwischen: product.invZW,
            };
        }
        return forecast;
    }

    public async forecastFor(products: SimpleProductWithStockData[], forecasts?: ForecastElements): Promise<void> {
        this.addProducts(products);
        if (forecasts) {
            this.applyForecasts(forecasts!);
            this.finalizeAll();
        }
    }

    public async relocateFor(forecasts: ArticleForecastWithStockData[], pallets?: PalletData[]): Promise<void> {
        // tslint:disable-next-line: no-use-before-declare
        let relocator: StockRelocator = new StockRelocator(this.getCurrentMoment);
        forecasts.forEach(v => {
            const palletsForProduct = pallets?.filter(p => p.productNo === v.articleNumber);
            let h = relocator.recommendRelocationFor('hannover', v, palletsForProduct);
            let l = relocator.recommendRelocationFor('leinefelde', v, palletsForProduct);
            v.stocks.relocations = {
                hannover: { demand15d: relocator.calcDemand(v.stocks.hannover, 15), demand30d: relocator.calcDemand(v.stocks.hannover, 30) },
                leinefelde: { ...l, demand15d: relocator.calcDemand(v.stocks.leinefelde, 15), demand30d: relocator.calcDemand(v.stocks.leinefelde, 30) },
            };
            v.stocks.relocationSum = (h.sum ?? 0) + (l.sum ?? 0);
        });
    }

    public addProducts(products: SimpleProductWithStockData[]) {
        products.forEach(p => { this.forecasts.set(p.number, this.transform(p)); });
    }

    // tslint:disable-next-line: no-any
    public applyConsumptions(data: any) { // @TODO: proper types are only available from cube client frontend lib
        //group by article number
        const itemFilter = data.query?.filters?.find(f => f.member === BiStringDimensions.orderItemNo);
        let cons: Map<string, BiLoadResponseResult[]> = new Map();
        for (let d of data.data as BiLoadResponseResult[]) {
            const itemNo = d[BiStringDimensions.orderItemNo];
            if (itemNo) {
                if (!cons.has(itemNo)) {
                    cons.set(itemNo, []);
                }
                cons.get(itemNo)!.push(d);
            }
        }
        // take article numbers from filter values to account for those without consumptions
        for (const v of itemFilter?.values) {
            let fc = this.getForecastForArticleNumber(v);
            if (fc) {
                this.applyConsumption(fc, cons.get(v) ?? []);
            }
        }
    }

    public applyConsumption(article: ArticleForecast, consumptions: BiLoadResponseResult[]) {
        const monthly: Map<string, number> = new Map(consumptions.map(c => [c['[BI].orderDate.month']!.substr(0, 7), c['[BI].orderItemQuantity']!]));
        this.applyConsumptionForLastYear(article, monthly);
        this.applyConsumptionForNextLastPeriod(article, monthly);
        this.applyConsumptionForLastPeriod(article, monthly);
        this.applyConsumptionForYTD(article, monthly);
        this.applyConsumptionLastPeriodBefore(article, monthly);
        this.applyConsumptionCurrentPeriodBefore(article, monthly);
    }

    protected applyConsumptionForLastYear(article: ArticleForecast, monthlyConsumptions: Map<string, number>) {
        let res = this.filterBIConsumptions(monthlyConsumptions,
            moment.utc(this.startingPeriod).subtract(1, 'year').startOf('year'),
            moment.utc(this.startingPeriod).subtract(1, 'year').endOf('year')
        );
        article.lastYearConsumption = this.sumConsumptions(res) ?? 0;
    }

    protected applyConsumptionForNextLastPeriod(article: ArticleForecast, monthlyConsumptions: Map<string, number>) {
        article.nextLastPeriodConsumption = this.filterBIConsumptions(monthlyConsumptions,
            moment.utc(this.startingPeriod).subtract(2, 'year'),
            moment.utc(this.endPeriod).subtract(2, 'year')
        );
        article.fullNextLastPeriodConsumption = this.sumConsumptions(article.nextLastPeriodConsumption) ?? 0;
    }

    protected applyConsumptionForLastPeriod(article: ArticleForecast, monthlyConsumptions: Map<string, number>) {
        article.lastPeriodConsumption = this.filterBIConsumptions(monthlyConsumptions,
            moment.utc(this.startingPeriod).subtract(1, 'year'),
            moment.utc(this.endPeriod).subtract(1, 'year').subtract(1, 'month') //@TODO: it works with 1 month less, but i'm not really sure why.
        );
        article.fullLastPeriodConsumption = this.sumConsumptions(article.lastPeriodConsumption) ?? 0;
    }

    protected applyConsumptionForYTD(article: ArticleForecast, monthlyConsumptions: Map<string, number>) {
        article.ytdConsumption = this.filterBIConsumptions(monthlyConsumptions,
            moment.utc(this.startingPeriod).startOf('year'),
            this.getCurrentMoment()
        );
        article.fullYtdConsumption = this.sumConsumptions(article.ytdConsumption) ?? 0;
    }

    protected applyConsumptionLastPeriodBefore(article: ArticleForecast, monthlyConsumptions: Map<string, number>) {
        article.lastPeriodBeforeConsumption = this.filterBIConsumptions(monthlyConsumptions,
            moment.utc(this.startingPeriod).subtract(1, 'year').subtract(6, 'months'),
            moment.utc(this.startingPeriod).subtract(1, 'year').subtract(1, 'month')
        );
    }

    protected applyConsumptionCurrentPeriodBefore(article: ArticleForecast, monthlyConsumptions: Map<string, number>) {
        article.currentPeriodBeforeConsumption = this.filterBIConsumptions(monthlyConsumptions,
            moment.utc(this.startingPeriod).subtract(6, 'months'),
            moment.utc(this.startingPeriod).subtract(1, 'month')
        );
    }

    protected filterBIConsumptions(monthlyConsumptions: Map<string, number>, start: moment.Moment, end: moment.Moment): Consumption[] {
        const res: Consumption[] = [];
        for (let current = moment(start); current <= end; current.add(1, 'month')) {
            res.push({
                month: current.toDate(),
                amount: monthlyConsumptions.get(current.format('YYYY-MM')) ?? 0,
            });
        }
        return res;
    }

    public applyStocks() {
        //@TODO:
    }

    public applyStock() {
        //@TODO:
    }

    public applyForecasts(forecasts: ForecastElements) {
        let elements: Map<string, ForecastElement> = new Map();
        let statuus: Map<ForecastStatus, number> = new Map();
        for (let k of Object.keys(forecasts)) {
            elements.set(k, forecasts[k]);
        }

        this.forecasts.forEach(a => {
            if (elements.has(a.articleNumber)) {
                try {
                    this.applyForecast(a, elements.get(a.articleNumber)!);
                } catch (e) {
                    console.error('error with forecast', a);
                    throw e;
                }
                if (!statuus.has(a.status)) {
                    statuus.set(a.status, 0);
                }
                statuus.set(a.status, statuus.get(a.status)! + 1);
                elements.delete(a.articleNumber);
            } else {
                //console.warn('no forecast found for article', a.articleNumber);
            }
        });
        if (elements.size != 0) {
            //console.warn('forcasts without article found', Array.from(elements.keys()));
        }
        //console.info('forecast statuus', statuus.entries());
    }

    protected getConsumptions(yhats: YHats, type: 'mean' | 'lower' | 'upper' = 'mean'): Consumption[] {
        let res: Consumption[] = [];
        for (let i = 0; i < yhats.period.length; i++) {
            let yhat = type === 'lower' ? yhats.yhat_lower[i] : type === 'upper' ? yhats.yhat_upper[i] : yhats.yhat[i];
            if (typeof yhat !== 'number') {
                throw new Error('inconsistent forecast periods');
            }
            res.push({
                month: dateStringToMonth(yhats.period[i]),
                amount: Math.max(0, yhat)
            });
        }
        return res;
    }

    public sumConsumptions(cs: Consumption[], exact: boolean = false) {
        if (exact) {
            cs = this.pruneConsumptions(cs, true);
        }
        return cs.map(c => c.amount).reduce((s, v) => {
            return s + v;
        }, 0);
    }

    public maxConsumption(cs: Consumption[]) {
        return cs[cs.length - 1].amount;
    }

    protected sortConsumptions(cs: Consumption[]) {
        cs.sort((a, b) => {
            if (moment(a.month).isSame(b.month)) {
                return 0;
            }
            return moment(a.month).isBefore(b.month) ? -1 : 1;
        });
    }

    //@TODO: combine pruneConsumptions and matchPeriodsOnConsumptions to one function
    protected pruneConsumptions(cs: Consumption[], exact: boolean = false) {
        let res = Array.from(cs);
        res = res.filter(c => {
            return moment(c.month).isSameOrAfter(this.startingPeriod, 'month') && moment(c.month).isSameOrBefore(this.endPeriod);
        });
        if (exact && (res.length > 0)) {
            this.sortConsumptions(res);
            //get a factor to calculate the share of the month. May be inaccurate sometimes,but ok.
            let factor = Math.max(1, (30 - Math.min(this.startingOffset, 30))) / 30;
            //@TODO: this is dangerous. We need to check for references everywhere and copy the objects. Consider this during reimplementation
            let currentMonth = res.find(c => c.month && new Date(c.month).getMonth() === this.startingPeriod.getMonth());
            if (currentMonth) {
                res[0] = Object.assign({}, res[0]);
                res[0].amount = Math.round(res[0].amount * factor);
            }
        }
        return res;
    }

    protected matchPeriodsOnConsumptions(cs: Consumption[], extensions: ConsumptionForcastExtension) {
        let res = Array.from(cs);
        res = res.filter(c => {
            return moment(c.month).isSameOrAfter(this.startingPeriod, 'month') && moment(c.month).isSameOrBefore(this.endPeriod);
        });
        this.fillConsumptionGaps(res, extensions);

        return res;
    }

    /**
     * makes sure that consumption series has no gaps in front or end
     * @param cs needs to be sorted already
     * @param extensions source for data to fill in gaps
     */
    protected fillConsumptionGaps(cs: Consumption[], extensions: ConsumptionForcastExtension) {
        if (cs[0]) {
            //fill up cs before current start
            if (moment(this.startingPeriod).isBefore(cs[0].month, 'month')) {
                let beforeFill = moment(this.startingPeriod);
                while (moment(beforeFill).isBefore(cs[0].month, 'month')) {
                    cs.unshift({amount: this.getValueFromForcastExtension(extensions, beforeFill), month: beforeFill.toDate()});
                    beforeFill = beforeFill.add(1, 'month');
                }
            }
            //NOTE: we wont touch the middle. May be empty ore zero deliberately.
            //fill up cs after current end
            if (moment(this.endPeriod).isAfter(cs[cs.length - 1].month, 'month')) {
                let afterFill = moment(cs[cs.length - 1].month).add(1, 'month');
                while (moment(afterFill).isSameOrBefore(this.endPeriod, 'month')) {
                    cs.push({amount: this.getValueFromForcastExtension(extensions, afterFill), month: afterFill.toDate()});
                    afterFill = afterFill.add(1, 'month');
                }
            }
        }
    }

    protected getValueFromForcastExtension(extensions: ConsumptionForcastExtension, _moment: moment.Moment): number {
        return extensions;
    }

    public applyForecast(a: ArticleForecast, e: ForecastElement) {
        a.status = e.statusCode;
        a.isNewProduct = !!e.isNewProduct;
        //@TODO: check other forecast types
        /*if ((a.status == ForecastStatus.SUCCESS) && (!Array.isArray(e.forecast!.period) || (e.forecast!.period.length < 1))) {
            a.status = ForecastStatus.FILTERED;
        }*/
        if (e.abc && e.abc[a.articleNumber]) {
            a.abcFull = {
                value: e.abc[a.articleNumber].all[0].ABC,
                rank: e.abc[a.articleNumber].all[0].rank,
                of: e.abc[a.articleNumber].all[0].maxRank
            };
            //@TODO: this should be based on articleGroup. But R assumes the number prefix atm.
            a.abcSupport = {
                value: e.abc[a.articleNumber][this.getArticleNumberPrefix(a)][0].ABC,
                rank: e.abc[a.articleNumber][this.getArticleNumberPrefix(a)][0].rank,
                of: e.abc[a.articleNumber][this.getArticleNumberPrefix(a)][0].maxRank
            };
        }
        //@TODO: for now th FE rearranges the stuff. Reconsider.
        a.seasonality = e.seasonality;

        if (e.metaData && (e.metaData.chosenModel as string == 'combi')) {
            a.status = ForecastStatus.COMBI;
        }

        //@TODO: clean this up after model discussion is settled
        let foundOne: boolean = false;
        if (this.usableStatus.includes(a.status)) {
            for (let key of Object.keys(ForecastMethod) as ForecastMethod[]) {
                let candidate = e['forecast_' + key];
                if (candidate && Array.isArray(candidate.period) && (candidate.period.length > 0) && (candidate.yhat[0] != 'NA')) {
                    //@TODO: get the correct extensions here
                    a.predictedConsumptions[key] = this.matchPeriodsOnConsumptions(this.getConsumptions(candidate), 100);
                    a.predictedLowerBoundConsumptions[key] = this.matchPeriodsOnConsumptions(this.getConsumptions(e['forecast_' + key], 'lower'), 100);
                    a.predictedUpperBoundConsumptions[key] = this.matchPeriodsOnConsumptions(this.getConsumptions(e['forecast_' + key], 'upper'), 100);
                    this.sortConsumptions(a.predictedConsumptions[key]);
                    a.predictedConsumption[key] = this.sumConsumptions(a.predictedConsumptions[key], true);
                    foundOne = true;
                }
            }
            if (!foundOne) {
                let fallback = e.forecast;
                if (fallback && Array.isArray(fallback.period) && (fallback.period.length > 0)) {
                    a.predictedConsumptions.combi = this.matchPeriodsOnConsumptions(this.getConsumptions(fallback), 100);
                    this.sortConsumptions(a.predictedConsumptions.combi);
                    a.predictedConsumption.combi = this.sumConsumptions(a.predictedConsumptions.combi, true);
                    foundOne = true;
                }
                a.choosenPrediction = ForecastMethod.combi;
                foundOne = true;
            } else {
                a.choosenPrediction = e.metaData.chosenModel;
            }
            this.setChoosenPrediction(a, a.choosenPrediction);
        }
        if (this.usableStatus.includes(a.status) && !foundOne) {
            a.status = ForecastStatus.FILTERED;
            //@TODO: we could completely rely on the extensions here
        }
    }

    public setChoosenPrediction(a: ArticleForecast, method: ForecastMethod): void {
        if (method) {
            a.choosenPrediction = method;
            a.choosenConsumptions = Forecaster.copyConsumptions(a.predictedConsumptions[method]);
            a.choosenConsumption = a.predictedConsumption[method];
            a.fullConsumptions = Forecaster.copyConsumptions(a.predictedConsumptions[method]);
            a.choosenLowerBoundConsumption = a.predictedLowerBoundConsumptions[method];
            a.choosenUpperBoundConsumption = a.predictedUpperBoundConsumptions[method];
            a.fullLowerBoundConsumptions = Forecaster.copyConsumptions(a.choosenLowerBoundConsumption);
            a.fullUpperBoundConsumptions = Forecaster.copyConsumptions(a.choosenUpperBoundConsumption);
        }
    }

    public getFullChoosenPrediction(forecast: ArticleForecast): number {
        //let c = forecast['fullPredictedConsumption_' + forecast.choosenPrediction];
        let c = forecast.fullConsumption;
        return c ? c : 0;
    }

    public finalizeAll() {
        this.forecasts.forEach(v => this.finalize(v));
    }

    public finalize(forecast: ArticleForecast, exact = true) {
        this.mergePredictionAndAdditionals(forecast, exact);
        this.checkCurrentReach(forecast);
        this.recommendOrder(forecast);
        this.checkFinalReach(forecast);
    }

    /**
     * @TODO: do we need different starting points? Should we define a "contingency" or can additionalArbitraryConsumption be interpreted like that
     */
    public checkCurrentReach(forecast: ArticleForecast): void {
        let check = this.checkReach(forecast, forecast.currentStock + forecast.openPurchase);
        forecast.predictedCurrentReach = check.reach;
        forecast.currentFinalStock = check.available;
    }

    /**
     * NOTE: assumes that currentFinalStock was calculated correctly
     */
    public recommendOrder(forecast: ArticleForecast): void {
        if (forecast.currentFinalStock < 0) {
            forecast.recommendedOrderAmount = this.getOrderableAmount(forecast, Math.abs(forecast.currentFinalStock));
            forecast.shouldOrder = true;
        } else {
            forecast.recommendedOrderAmount = 0;
            forecast.shouldOrder = false;
        }
    }

    public getOrderableAmount(forecast: ArticleForecast, targetAmount: number): number {
        let remainder = targetAmount % forecast.packageSize;
        if (remainder != 0) {
            return targetAmount - remainder + forecast.packageSize;
        } else {
            return targetAmount;
        }
    }

    //@TODO: do we need different starting points?
    public checkFinalReach(forecast: ArticleForecast): void {
        let check = this.checkReach(forecast, forecast.currentStock + forecast.openPurchase + forecast.recommendedOrderAmount);
        forecast.predictedReach = check.reach;
        forecast.predictedFinalStock = check.available;
        if (forecast.predictedFinalStock > forecast.packageSize) { //if we have more than we would have ordered, we are overreaching. @TODO: use a more sophisticated check
            forecast.overreached = true;
            forecast.predictedCurrentReach = moment(check.reach).add(1, 'month').toDate();
            forecast.predictedReach = moment(check.reach).add(1, 'month').toDate();
        } else {
            forecast.overreached = false;
        }
    }

    /**
     * @returns the last period with stock and the (possibly negative) stock after ALL periods
     */
    public checkReach(forecast: ArticleForecast, available: number, aggregated: boolean = false): { reach: Date, available: number } {
        let reach: Date = this.startingPeriod;
        let remain: number = available;
        if (forecast.fullConsumptions.length < 1) {
            //if we have no prediction. assume it will last till the end. A warning will be displayed elsewhere
            reach = this.endPeriod;
        }
        let consumptions = this.pruneConsumptions(forecast.fullConsumptions, false);
        for (let c of consumptions) {
            if (aggregated) {
                remain = available - c.amount;
            } else {
                remain = remain - c.amount;
            }
            if (remain >= 0) {
                reach = c.month;
            } else {
                //keep going to get the final missing count
            }
        }
        return {
            reach: reach,
            available: remain
        };
    }

    public setForecasts(forecasts: ArticleForecast[]): void {
        this.forecasts = new Map();
        for (let f of forecasts) {
            this.forecasts.set(f.articleNumber, f);
        }
    }

    //@TODO: implement a good and fast data source. Scanning and filtering the map may be inefficient
    //NOTE: the following functions are not used to filter the UI. It looks up the data that should be changed in the currently loaded article set.
    public getAllForecasts(): ArticleForecast[] {
        return Array.from(this.forecasts.values());
    }

    public getForecastForArticleNumber(articleNumber: string): ArticleForecast | undefined {
        return this.forecasts.get(articleNumber);
    }

    public addPalletData(pallets: PalletData[]) {
        const forecasts = this.getAllForecasts();
        // transform pallets array into two-dimensional map (warehouse, productNo)
        const warehouseProductPalletsMap: Map<Warehouses, Map<string, PalletData[]>> = new Map();
        for (const pt of pallets) {
            let productPalletsMap = warehouseProductPalletsMap.get(pt.warehouse!);
            if (!productPalletsMap) {
                productPalletsMap = new Map();
                warehouseProductPalletsMap.set(pt.warehouse!, productPalletsMap);
            }
            let palletsMap = productPalletsMap.get(pt.productNo);
            if (!palletsMap) {
                palletsMap = [];
                productPalletsMap.set(pt.productNo, palletsMap);
            }
            palletsMap.push(pt);
        }
        for (const fc of forecasts) {
            for (const wh of Object.keys(fc.stocks!)) {
                if (fc.stocks?.[wh].history) { // duck typing checking for StockData object
                    const matchingPallets = warehouseProductPalletsMap.get(wh as Warehouses)?.get(fc.articleNumber);
                    if (matchingPallets) {
                        fc.stocks[wh].pallets = matchingPallets;
                        let palletSum = matchingPallets.reduce((a, p) => a += p.amount, 0);
                        fc.stocks[wh].palletsSum = palletSum;
                        let stockIncon =  this.compareSystemAndPalletStock(fc.stocks[wh].current, palletSum);
                        fc.stockDifference = fc.stockDifference ?? 0 > stockIncon ? fc.stockDifference ?? 0 : stockIncon;
                    } else {
                        if (fc.stocks?.[wh].current && wh == 'hannover') {
                            fc.stockDifference = 2;
                        }
                    }
                }
            }
        }
    }

    private compareSystemAndPalletStock(systemStock: number, palletStock: number): 0 | 1 | 2 {
        let validPercentage = 10;
        let warnPercentage = 50;
        if ((systemStock * ((100 - validPercentage) / 100)) <= palletStock &&
            (systemStock * ((100 + validPercentage) / 100)) >= palletStock) {
            return 0;
        }
        if ((systemStock * ((100 - warnPercentage) / 100)) <= palletStock &&
            (systemStock * ((100 + warnPercentage) / 100)) >= palletStock) {
            return 1;
        }
        return 2;
    }

    /**
     * make sure to finalize afterwards
     */
    public resetAdditionalArbitraryConsumptions(): void {
        for (let f of this.getAllForecasts()) {
            f.additionalArbitraryConsumption = [];
            f.fullAdditionalArbitraryConsumption = 0;
        }
    }

    public applyAdditionalArbitraryConsumptions(items: AdditionalConsumptionItem[]): void {
        let numberMap: Map<string, AdditionalConsumptionItem[]> = new Map();
        for (let item of items) {
            if (!numberMap.has(item.articleNumber)) {
                numberMap.set(item.articleNumber, []);
            }
            numberMap.get(item.articleNumber)!.push(item);
        }
        numberMap.forEach((v, k) => {
            let article = this.getForecastForArticleNumber(k);
            if (article) {
                this.applyAdditionalArbitraryConsumption(article, v);
            }
        });
    }

    public applyAdditionalArbitraryConsumption(a: ArticleForecast, items: AdditionalConsumptionItem[]): void {
        let cons: Consumption[] = [];
        for (let item of items) {
            let ex = cons.find(c => { return moment(c.month).isSame(moment(item.startDate).startOf('month')); });
            if (ex) {
                ex.amount = ex.amount + item.amount;
            } else {
                cons.push({
                    amount: item.amount,
                    month: moment(item.startDate).startOf('month').toDate()
                });
            }
            this.sortConsumptions(cons);
            let exact = false;
            if (cons[0] && moment(cons[0].month).isSame(this.startingPeriod, 'month')) {
                exact = true;
            }
            a.additionalArbitraryConsumption = this.pruneConsumptions(cons, exact); //@TODO: do we need to be exact for current month?
            a.fullAdditionalArbitraryConsumption = this.sumConsumptions(a.additionalArbitraryConsumption);
        }
    }

    public mergePredictionAndAdditionals(a: ArticleForecast, exact = true): void {
        let additionals = new Map(a.additionalArbitraryConsumption.map(c => [c.month, c.amount]));
        for (let i = 0; i < a.fullConsumptions.length; i++) {
            let cur = a.fullConsumptions[i].month;
            let add = additionals.get(cur);
            if (add) {
                a.fullConsumptions[i].amount = a.choosenConsumptions[i].amount + add;
                a.fullLowerBoundConsumptions[i].amount = a.choosenLowerBoundConsumption[i].amount + add;
                a.fullUpperBoundConsumptions[i].amount = a.choosenUpperBoundConsumption[i].amount + add;
                additionals.delete(cur);
            }
        }
        for (let [month, amount] of additionals) {
            a.fullConsumptions.push({
                month,
                amount,
            });
        }
        /*if (a.articleNumber === 'AAA.060.150.01') {
            console.log('after merging', a.articleNumber, a.additionalArbitraryConsumption, a.fullConsumptions, a.fullConsumption);
        }*/
        a.fullConsumption = this.sumConsumptions(a.fullConsumptions, exact);
    }

    public getLinearPrediction(articles: SimpleProductWithStockHistory[], depth = 60) {
        //
        for (const article of articles) {
            if (Array.isArray(article.stockDateHistory)) {
                const sorted = this.sortDependentArrays([
                    article.stockDateHistory,
                    article.invHannoverHistory,
                    article.invHIGHistory,
                    article.invLFDHistory,
                    article.invZWHistory,
                ]);
                article.stockDateHistory = sorted[0].slice(0, depth);
                article.invHannoverHistory = sorted[1].slice(0, depth);
                article.invHIGHistory = sorted[2].slice(0, depth);
                article.invLFDHistory = sorted[3].slice(0, depth);
                article.invZWHistory = sorted[4].slice(0, depth);

                // insert null values for missing days
                let today = this.getCurrentMoment();
                let latest = moment.utc(article.stockDateHistory[0]);
                let diff = today.diff(latest, 'days');
                for (let i = 1; i < depth - diff; i++) {
                    const iDay = latest.format('YYYY-MM-DD');
                    const aDay = moment(article.stockDateHistory[i]).format('YYYY-MM-DD');
                    if (iDay !== aDay) {
                        article.stockDateHistory.splice(i, 0, iDay as unknown as Date);
                        article.invHannoverHistory.splice(i, 0, null as unknown as number);
                        article.invHIGHistory.splice(i, 0, null as unknown as number);
                        article.invLFDHistory.splice(i, 0, null as unknown as number);
                        article.invZWHistory.splice(i, 0, null as unknown as number);
                    }
                    latest.add(-1, 'day');
                }

                // @TODO: maybe pad missing dates due to pipeline problems?
                article.invHannoverExpDate = this.findOutOfStockDateByLeastSquaresLine(article.stockDateHistory, article.invHannoverHistory);
                article.invLFDExpDate = this.findOutOfStockDateByLeastSquaresLine(article.stockDateHistory, article.invLFDHistory);

                //we need those to relocate. The others are all or nothing. So no need to bloat up the response. @TODO: can we prune them too to save size? Maybe we should create the sums we need serverside
                article.invHannoverSmooth = this.calculateLeastSquaresLineAlt(article.stockDateHistory, article.invHannoverHistory, -10000, 60).map(Math.round); //we need enough values to predict demands up to eight weeks
                article.invLFDSmooth = this.calculateLeastSquaresLineAlt(article.stockDateHistory, article.invLFDHistory, -10000, 60).map(Math.round);
            } else {
                article.stockDateHistory = [];
                article.invHannoverHistory = [];
                article.invHIGHistory = [];
                article.invLFDHistory = [];
                article.invZWHistory = [];
            }
        }
        return articles;
    }
    private sortDependentArrays<T extends [master: unknown[], ...slaves: unknown[][]]>(arrays: T, comparator = (a, b) => (a < b) ? 1 : (a > b) ? -1 : 0): T {
        const master = arrays[0];
        if (!Array.isArray(master)) {
            return arrays;
        }
        let sortedIndexes = Object.keys(master).sort((a, b) => comparator(master[a], master[b]));
        let sortByIndexes = (array: unknown[], sorted: string[]) => sorted.map(sortedIndex => array[sortedIndex]) as unknown[];
        return arrays.map((_, i) => sortByIndexes(arrays[i], sortedIndexes)) as T;
    }

    private calculateLeastSquaresParamsForOutOfStockDate(days: Date[], values: number[], ascThreshold = 100, descThreshold = 100, skipStockThreshold = 10): [m: number, b: number, o: number] {
        // only give prediction days and values match in size
        if (days.length != values.length) {
            return [0, 0, 0];
        }
        let x = 0;
        let y = 0;
        let xSum = 0;
        let ySum = 0;
        let xySum = 0;
        let xxSum = 0;
        let xOffsetNull = 0;
        let xOffsetOos = 0;
        let yOffset = 0;
        let xStart = 0;
        let last = 0;
        let oos = false;
        for (let i = 0; i < days.length; i++) {
            // ignore missing data points (= null values)
            if (values[i] == null) {
                // time axis compression for missing data points within an out of stock period
                if (oos) {
                    xOffsetOos--;
                // otherwise keep time axis to allow proper interpolation
                } else {
                    xOffsetNull--;
                }
                continue;
            }
            // if skipStockThreshold is >=0, we ignore days where stocks remains below the threshold, in order to filter out-of-stock situations
            // and cut the out of stock period from the time axis to simulate continuous availability
            if (skipStockThreshold >= 0) {
                if (values[i] <= skipStockThreshold && (i === days.length - 1 || values[i + 1] <= skipStockThreshold)) {
                    xOffsetOos--;
                    oos = true;
                    continue;
                }
            }
            // if threshold is >=0, we are ignoring days where the stock increased above (ascThreshold) or decreased below (descThreshold)
            // the threshold compared to to preceeding day and padd the y value accordingly in order to create an even graph
            if (x > 0 && (ascThreshold >= 0 || descThreshold >= 0)) {
                if ((ascThreshold >= 0 && values[i] + ascThreshold < values[last]) || descThreshold >= 0 && values[i] - descThreshold > values[last]) {
                    yOffset += values[last] - values[i];
                }
            }
            // do the least squares math
            x = i + xOffsetOos;
            y = values[i] + yOffset;
            xSum += x;
            ySum += y;
            xxSum += x * x;
            xySum += x * y;
            if (x === 0) {
                xStart = xOffsetOos;
            }
            last = i;
            oos = false;
            // console.log('x, y | i, v | xOffA, xOffB, yOff, xStart|||', x, y, '|', i, values[i], '|', xOffsetNull, xOffsetOos, yOffset, xStart);
        }
        // only give prediction if we have filtered data for at least 5 days
        let count = days.length + xOffsetNull + xOffsetOos;
        if (count < 5) {
            return [0, 0, 0];
        }
        // caclculate m and b for linear function: y = x * m + b
        let m = (count * xySum - xSum * ySum) / (count * xxSum - xSum * xSum);
        let b = (ySum / count) - (m * xSum) / count;
        // console.log('m, b, xStart, count, last', m, b, xStart, count, last);
        return [m, b, xStart];
    }

    public findOutOfStockDateByLeastSquaresLine(days: Date[], values: number[], ascThreshold = 100, descThreshold = 100, outOfStockThreshold = 10, offset = 0): Date | undefined {
        // if we are currently out of stock, skip calculation and just return the day the article went below the threshold
        if (values[0] <= outOfStockThreshold) {
            const lastDayWithStock = days.find((_, i) => values[i] > outOfStockThreshold);
            // console.info('last stock, last day with stock', days[0], lastDayWithStock);
            return (lastDayWithStock ? moment.utc(lastDayWithStock).add(1, 'days') : moment.utc(days[days.length - 1])).toDate();
        }
        const [m, b, o] = this.calculateLeastSquaresParamsForOutOfStockDate(days, values, ascThreshold, descThreshold, outOfStockThreshold);
        // im m is negative or zero, the stock is increasing or constant and we can't predict a shit
        if (m <= 0) {
            return undefined;
        }
        // return first day (x) where the line falls below out of stock threshold on the y-axis
        let xIntersect = Math.ceil((outOfStockThreshold + offset - b) / -m) - o + 1;
        // console.log('m, b, o, oost, xInt:', m, b, o, outOfStockThreshold, xIntersect);
        // if the calculated date is more than 5 years in the future, we'll better not give a prediction
        if (xIntersect > 1825) {
            return undefined;
        }
        // if out of stock is calculated for a past day, make sure shift to the future until we are actually out of stock (or reached tomorrow)
        if (xIntersect < 0) {
            while (values[-xIntersect] > outOfStockThreshold) {
                xIntersect++;
            }
        }
        return moment.utc(days[0]).add(xIntersect, 'days').toDate();
    }

    public calculateLeastSquaresLineAlt(days: Date[], values: number[], minY = 0, maxPoints = 365, ascThreshold = 100, descThreshold = 100, outOfStock = 10, cutOffPoint = 0): number[] {
        return this.calculateLeastSquaresLine(days, values, ascThreshold, descThreshold, outOfStock, cutOffPoint, minY, maxPoints);
    }

    public calculateLeastSquaresLine(days: Date[], values: number[], ascThreshold = 100, descThreshold = 100, outOfStock = 10, cutOffPoint = 0, minY = -1000, maxPoints = 365): number[] {
        const count = days.length;
        const daysSplice = days.slice(0, cutOffPoint > 0 ? cutOffPoint : undefined);
        const valuesSplice = values.slice(0, cutOffPoint > 0 ? cutOffPoint : undefined);
        const [m, b, o] = this.calculateLeastSquaresParamsForOutOfStockDate(daysSplice, valuesSplice, ascThreshold, descThreshold, outOfStock);
        // console.log('ascThreshold, descThreshold, outOfStock, m, b, o', ascThreshold, descThreshold, outOfStock, m, b, o);
        const y: number[] = [];
        let i = count + o;
        do {
            y.push(m * i + b);
            i--;
        }
        while ((m > 0 || y[y.length - 1] >= minY) && i > -maxPoints);
        return y;
    }

}
export class StockRelocator {

    //@TODO: do not create them hard coded on today..?
    protected fourWeeksAgo = this.getCurrentMoment().add(-28, 'days').toDate();
    protected twoWeeksFromNow = this.getCurrentMoment().add(14, 'days').toDate();
    protected fourWeeksFromNow = this.getCurrentMoment().add(28, 'days').toDate();

    protected allowedTargetWarehouses: Warehouses[] = ['leinefelde', 'nohra'];
    protected relocationAmountTimeframe: number = 30; //relocate stuff for the next x days demand
    protected keepStockForDays: number = 30; //keep at least stock for x days if relocation between "delivering" warehouses
    protected defaultPackageSize: number = 6;

    constructor(private getCurrentMoment: () => Moment) {
        //
    }

    //this is here to have warnings for ooo and relocations in sync. Make sure to check StocksComponent getWarning if somethings changes.
    //@TODO: refactor
    public hasWarning(date: Date | string | null | undefined): boolean {
        if (date) {
            date = new Date(date);
            if (date < this.fourWeeksAgo) {
                return false;
            }
            if (date < this.twoWeeksFromNow) {
                return true;
            }
            if (date < this.fourWeeksFromNow) {
                return true;
            }
            return false;
        }
        return false;
    }

    public recommendRelocationFor(ws: Warehouses, fc: ArticleForecastWithStockData, pallets?: PalletData[]): RelocationList {
        const stocks = fc.stocks;
        let relocations: Relocation[] = [];
        if (!this.allowedTargetWarehouses.includes(ws)) {
            return this.list(relocations, 0);
        }
        // @TODO: comment in when finished
        // if (!this.hasWarning(stocks[ws].outOfStock)) {
        //     return this.list(relocations, 0);
        // }
        let targetAmount: number = this.calcDemand(stocks[ws], 30);
        //if for some reason nothing shall be moved, return empty array
        if (targetAmount <= 0) { return this.list(relocations, 0); }
        if (ws == 'leinefelde') {
            // first, take stray units from heiligenstadt
            targetAmount = this.relocate('heiligenstadt', stocks.heiligenstadt, targetAmount, ws, relocations, undefined, false);
            if (targetAmount <= 0) { return this.list(relocations, 0); }
            // then, take overstock from hannover
            targetAmount = this.relocate('hannover', stocks.hannover, targetAmount, ws, relocations, this.getStockInDays(stocks.hannover, this.keepStockForDays), pallets);
            if (targetAmount <= 0) { return this.list(relocations, 0); }
        } else if (ws == 'hannover') {
            // first take overstock from leinefelde
            targetAmount = this.relocate('leinefelde', stocks.leinefelde, targetAmount, ws, relocations, this.getStockInDays(stocks.leinefelde, this.keepStockForDays));
            if (targetAmount <= 0) { return this.list(relocations, 0); }
            // if we still have demand, check heiligenstadt, but only full VEs
            targetAmount = this.relocate('heiligenstadt', stocks.heiligenstadt, targetAmount, ws, relocations);
        }

        if (targetAmount <= 0) { return this.list(relocations, 0); }
        //if we still need to move, get everything we need from hannover
        //if we dont have enough in all warehouses, its not our problem here.
        //@TODO: check future demand in source warehouse
        return this.list(relocations, targetAmount);
    }

    protected list(relocations: Relocation[], remainder: number): RelocationList {
        let sum = relocations.reduce( (p, c) => { return p + c.amount; }, 0);
        return {
            relocations: relocations,
            unsatisfied: remainder,
            sum: sum
        };
    }

    protected getPalletListForAmount(amount: number, pallets: PalletData[], level = 0): PalletData[] {
        if (level === 0) {
            pallets.sort((a, b) => b.amount - a.amount); // 1. sort descending by amount
        }
        let i = pallets.findIndex((p) => p.amount < amount) - 1;
        const availablePallets = pallets.slice(i >= 0 ? i : 0); // 2. discard all pallets larger than target except the last one
        const choosenPallets: PalletData[] = [];
        let total = 0;
        i = 0;
        for (const p of availablePallets) {
            i++;
            total += p.amount;
            if (total <= amount) {
                choosenPallets.push(p);
                if (total === amount) {
                    break;
                }
            } else {
                const last = total - p.amount;
                const pr = this.getPalletListForAmount(
                    amount - last,
                    availablePallets.slice(i),
                    level + 1
                );
                const sr = pr.reduce((a, c) => a += c.amount, 0);
                const dc = Math.abs(amount - total);
                const dl = Math.abs(amount - last);
                const dr = Math.abs(amount - (last + sr));
                const closest = Math.min(dr, dc, dl);
                if (closest === dr) {
                    return choosenPallets.concat(pr);
                } else {
                    if (closest === dc) {
                        choosenPallets.push(p);
                    }
                    return choosenPallets;
                }
            }
        }
        // if no pallets have been selected and we have a required amount, choose the smallest available pallet
        if (level === 0 && pallets.length > 0 && choosenPallets.length === 0 && amount > 0) {
            choosenPallets.push(pallets[pallets.length - 1]);
        }
        return choosenPallets;
    }


    /**
     *
     * @param from warehouse
     * @param fromStock stocks in warehouse
     * @param targetAmount
     * @param to warehouse
     * @param relocations array
     * @returns new target amount, maybe zero
     */
    protected relocate(from: Warehouses, fromStock: StockData, targetAmount: number, to: Warehouses, relocations: Relocation[], maxAmount?: number | undefined , usePallets?: PalletData[]): number;
    protected relocate(from: Warehouses, fromStock: StockData, targetAmount: number, to: Warehouses, relocations: Relocation[], maxAmount?: number | undefined, usePackageSize?: boolean): number;
    protected relocate(from: Warehouses, fromStock: StockData, targetAmount: number, to: Warehouses, relocations: Relocation[], maxAmount: number | undefined = undefined, usePalletsOrPackageSize: PalletData[] | boolean = true): number {
        if (Array.isArray(usePalletsOrPackageSize)) {
            const pallets = usePalletsOrPackageSize;
            const list = this.getPalletListForAmount(targetAmount, pallets);
            const sum = list.reduce((a, p) => a += p.amount, 0);
            relocations.push({
                from: from,
                to: to,
                amount: sum,
                choosenPalletIds: list.map(p => p.id!),
            });
            targetAmount -= sum;
        } else {
            let moveableAmount = this.getMoveableAmount(fromStock, targetAmount, maxAmount, usePalletsOrPackageSize);
            if (moveableAmount > 0) {
                relocations.push({
                    from: from,
                    to: to,
                    amount: moveableAmount
                });
                targetAmount -= moveableAmount;
            }
        }
        return targetAmount;
    }

    public calcDemand(stock: StockData, timeframe?: number): number {
        let historyLength = stock.history.length;
        //projected demand after today
        if (!stock.smooth) {
            return 0;
        }
        let demand = stock.smooth[Math.min(stock.smooth.length - 1, historyLength)] - stock.smooth[Math.min(stock.smooth.length - 1, historyLength + (timeframe ?? this.relocationAmountTimeframe))];
        demand -= stock.current;
        return demand && demand > 0 ? demand : 0;
    }

    public getStockInDays(stock: StockData, days: number): number {
        let historyLength = stock.history.length;
        if (!stock.smooth || (stock.smooth.length < (historyLength + days))) {
            console.warn('stock index missing', historyLength + days, stock.smooth);
            return 0;
        } else {
            return stock.smooth[historyLength + days];
        }
    }

    public getPackageSize(s: StockData): number {
        return s?.ve > 0 ? s.ve : this.defaultPackageSize;
    }

    //rounds up to package size. may be zero.
    public getMoveableAmount(s: StockData, targetAmount: number, maxAmount: number | undefined = undefined, usePackageSize = true): number {
        // make sure maxAmount is not above current stock
        if (!maxAmount || maxAmount > s.current) {
            maxAmount = s.current;
        }
        // make sure targetAmount is not above maxAmount
        if (maxAmount < targetAmount) {
            targetAmount = maxAmount;
        }
        let packageSize = this.getPackageSize(s);
        // round up to VE if maxAmount isn't exceeded, else round down
        let remainder = targetAmount % packageSize;
        if (usePackageSize && remainder != 0) {
            targetAmount += (targetAmount + packageSize - remainder > maxAmount ? 0 : packageSize) - remainder ;
        }
        return targetAmount;
    }
}
