import * as moment from 'moment';
import { TextsForEnum } from '../types';
import { AggregatedDayDoc, AggregatedSeries } from './continuous/DaySeries';
import { ForecastData, ForecastDataWithoutHistory } from './continuous/Transformer';

export function dateStringToMonth(s: string): Date {
    //to save any hassle with formats, construct the Date manually
    let parts = s.split('-');
    let i: moment.MomentInputObject = {
        year: parseInt(parts[0], 10),
        month: parseInt(parts[1], 10) - 1, //month are zero based
        day: 1,
    };
    let m = moment.utc(i);
    return m.toDate();
}

//@TODO: that's suboptimal. Should be generic and replaced by nothing in FE. But works for now.
export enum ForecastStatus {
    SUCCESS = 0, //forecast usable
    ERROR = 1,
    FILTERED = 2,
    FORECAST_ERROR = 3,
    PROPHET_ERROR = 4, //forecast usable
    UNCERTAIN = 5, //forecast usable
    COMBI = 6,
    UNKNOWN = 99,
    TRANSFORMER_ERROR = 255
}

export enum ForecastMethod {
    ownFC = 'ownFC',
    lm = 'lm',
    perc = 'perc',
    combi = 'combi',
    mean = 'mean',
}

export const ForecastMethodTexts: TextsForEnum<ForecastMethod> = {
    ownFC: 'Trends',
    lm: 'LM',
    perc: 'Prozentual',
    combi: 'NEU',
    mean: 'Mittelwert'
};

export enum ABCValue {
    A = 'A',
    B = 'B',
    C = 'C',
    UNKNOWN = 'X'
}

export type ABC = {
    value: ABCValue,
    rank: number,
    of: number
};


export type YHats = {
    period: string[], //date as Y-M-D
    yhat: number[], //forecast value corresponding to period
    yhat_lower: number[],
    yhat_upper: number[]
};

export type ForecastElement = {
    forecast_ownFC?: YHats,
    forecast_nbeats?: YHats,
    forecast_lm?: YHats,
    forecast_perc?: YHats,
    forecast_combi?: YHats,
    forecast_mean?: YHats,
} &
// use the following type instead of the type above if FE supports TS >= 4.1 - keeps interface in sync with enum
// { -readonly [K in keyof typeof ForecastMethod as `forecast_${K}`]?: YHats } &
{ //article number
    statusCode: ForecastStatus,
    isNewProduct: boolean | undefined,
    abc: {
        [index: string]: { //article number
            [index: string]: [ //article number or literal "all"
                {
                    group: string,
                    product: string,
                    sales: number,
                    percentage: number,
                    cumsumPerc: number,
                    ABC: ABCValue,
                    rank: number,
                    maxRank: number
                }
            ]
        }
    },
    activeSellingTime: {
        start: string, //date as Y-M-D
        end: string //date as Y-M-D
    },
    forecast?: YHats,
    metaData:
        {
            startPrediction: string, //date as Y-M-D
            debitor: string, //@TODO: what is that?
            name: string, //article number
            timeFrequency: string, //day, week month
            periodLength: number,
            chosenModel: ForecastMethod,
        },
    hyperParams: [
        {
            seasonality_mode: string,
            seasonality_prior_scale: number,
            changepoint_prior_scale: number,
            daily_seasonality: boolean
        }
    ],
    seasonality?: {
        yearly: number[],
        trend: {
            dates: string[], //date as Y-M-D
            values: number[]
        }
    },
    modelQuality: number
};

export type ForecastElements = {
    [index: string]: ForecastElement
};

export interface PalletData {
    id?: number;
    warehouse?: Warehouses;
    locationId?: string;
    productNo: string;
    amount: number;
}

export interface StockData {
    current: number;
    pallets?: PalletData[];
    palletsSum?: number;
    history: number[];
    smooth?: number[];
    outOfStock?: Date | string;
    ve: number;
}

export interface Consumption {
    month: Date;
    amount: number;
}

export type ConsumptionByMethod = {
    -readonly [K in keyof typeof ForecastMethod]: Consumption[];
};

export type FullConsumptionByMethod = {
    -readonly [K in keyof typeof ForecastMethod]: number | null;
};

//@TODO: feels like there is a lot of potential to refactor types between BI and calculator
export interface ArticleForecast {
    status: ForecastStatus;
    isNewProduct: boolean;
    articleGroup: string;
    articleNumber: string;
    ean: string;
    size: string;
    color: string;
    description: string;
    discontinued: boolean;
    width: number;
    length: number;
    creditorNumber: string;
    packageSize: number;
    location?: WarehouseNumeric;
    stocks?: {
        nohra: number | StockData;
        heiligenstadt: number | StockData;
        leinefelde: number | StockData;
        hannover: number | StockData;
        zwischen: number | StockData;
        dates?: Date[],
    };
    stockDifference?: 0 | 1 | 2; // for frontendfilter: 0 == <10%, 1 == >10%, 2 == >50%
    currentStock: number;
    //examples based on April 2021
    lastYearConsumption: number | null; //sum of consumption in 2020
    nextLastPeriodConsumption: Consumption[]; //consumption April to September 2019
    fullNextLastPeriodConsumption: number | null; //sum of the above
    lastPeriodConsumption: Consumption[]; //consumption April to September 2020
    fullLastPeriodConsumption: number | null; //sum of the above
    ytdConsumption: Consumption[]; //consumption April to September 2020
    fullYtdConsumption: number | null; //sum of the above
    lastPeriodBeforeConsumption: Consumption[]; //consumption November 2019 to April 2020
    currentPeriodBeforeConsumption: Consumption[]; //consumption November 2020 to April 2021
    predictedConsumption: FullConsumptionByMethod;
    predictedConsumptions: ConsumptionByMethod;
    predictedUpperBoundConsumptions: ConsumptionByMethod;
    predictedLowerBoundConsumptions: ConsumptionByMethod;
    fullConsumption: number | null;
    fullConsumptions: Consumption[];
    fullLowerBoundConsumptions: Consumption[];
    fullUpperBoundConsumptions: Consumption[];
    choosenConsumption: number | null;
    choosenConsumptions: Consumption[];
    choosenLowerBoundConsumption: Consumption[];
    choosenUpperBoundConsumption: Consumption[];
    additionalArbitraryConsumption: Consumption[];
    choosenPrediction: ForecastMethod | null;
    openPurchase: number;
    fullAdditionalArbitraryConsumption: number;
    abcFull: ABC;
    abcSupport: ABC;
    shouldOrder: boolean;
    recommendedOrderAmount: number;
    predictedCurrentReach: Date;
    predictedReach: Date;
    overreached: boolean;
    currentFinalStock: number;
    predictedFinalStock: number;
    //@TODO: remove after model discussion is settled
    seasonality?: {
        yearly: number[],
        trend: {
            dates: string[], //date as Y-M-D
            values: number[]
        }
    };
}

export interface ArticleForecastBase {
    status: ForecastStatus;
    isNewProduct: boolean;
    articleGroup: string;
    articleNumber: string;
    ean: string;
    size: string;
    color: string;
    description: string;
    discontinued: boolean;
    width: number;
    length: number;
    height: number;
    creditorNumber: string;
    packageSize: number;
    stocks?: {
        nohra: number;
        heiligenstadt: number;
        leinefelde: number;
        hannover: number;
        zwischen: number;
        dates?: Date[],
    };
    currentStock: number;
    leadTime: number;
}

export interface ArticleForecastWithSeries extends ArticleForecastBase {
    data?: ForecastDataWithoutHistory | ForecastData;
    choosenPrediction: ForecastMethod | null;
    choosenSeries: AggregatedSeries;
    //@TODO: not yet sure about the series with orders...
    choosenSeriesWithRecommendedOrder?: AggregatedSeries;
    allSeries: {
        [M in ForecastMethod]?: AggregatedSeries;
    };
    allFullDocs: {
        [M in ForecastMethod]?: AggregatedDayDoc;
    };
    choosenFullDoc: AggregatedDayDoc;
    choosenFullDocWithRecommendedOrder?: AggregatedDayDoc;
    abcFull: ABC;
    abcSupport: ABC;
    seasonality?: {
        yearly: number[],
        trend: {
            dates: string[], //date as Y-M-D
            values: number[]
        }
    };
    //@TODO: consider including all historical data into the series as well. Keep an eye on performance
    //examples based on April 2021
    lastYearConsumption: number | null; //sum of consumption in 2020
    nextLastPeriodConsumption: Consumption[]; //consumption April to September 2019
    fullNextLastPeriodConsumption: number | null; //sum of the above
    lastPeriodConsumption: Consumption[]; //consumption April to September 2020
    fullLastPeriodConsumption: number | null; //sum of the above
    ytdConsumption: Consumption[]; //consumption April to September 2020
    fullYtdConsumption: number | null; //sum of the above
    lastPeriodBeforeConsumption: Consumption[]; //consumption November 2019 to April 2020
    currentPeriodBeforeConsumption: Consumption[]; //consumption November 2020 to April 2021
    fullcurrentPeriodBeforeConsumption: number | null; //sum of the above
    fullLast12MonthConsumption: number | null; //sum of the above
    openPurchase: number | undefined;
    additionalArbitraryConsumption: number | null;
    predictedCurrentReach: Date | undefined;
    currentFinalStock: number;
    overreachedCurrent: boolean;
    fullConsumption: number | null;
    fullDemand: number | null;
    lostOpportunity: number | null;
    setOrderAmount: number | null;
    recommendedOrderAmount: number | null;
    recommendedOrderAmountNotRounded: number | null;
    predictedReach: Date;
    predictedFinalStock: number;
    overreachedFinal: boolean;
    choosenConsumption: number | null;
    choosenDemand: number | null;
    lm?: number;
}

export interface ForecastListeArticle {
    data?: ForecastDataWithoutHistory | ForecastData;
    status: ForecastStatus;
    isNewProduct: boolean;
    articleGroup: string;
    articleNumber: string;
    creditorNumber: string;
    width: number;
    length: number;
    height: number;
    color: string;
    description: string;
    stocks?: {
        nohra: number;
        heiligenstadt: number;
        leinefelde: number;
        hannover: number;
        zwischen: number;
        dates?: Date[],
    };
    currentStock: number;
    choosenPrediction: ForecastMethod | null;
    abcFull: ABC;
    abcSupport: ABC;
    //examples based on April 2021
    lastYearConsumption: number | null; //sum of consumption in 2020
    fullNextLastPeriodConsumption: number | null; //sum of the above
    fullLastPeriodConsumption: number | null; //sum of the above
    fullYtdConsumption: number | null; //sum of the above
    fullcurrentPeriodBeforeConsumption: number | null;
    openPurchase: number | undefined;
    additionalArbitraryConsumption: number | null;
    predictedCurrentReach: Date | undefined;
    overreachedCurrent: boolean;
    fullConsumption: number | null;
    fullDemand: number | null;
    lostOpportunity: number | null;
    recommendedOrderAmount: number | null;
    predictedReach: Date;
    predictedFinalStock: number;
    overreachedFinal: boolean;
    choosenDemand: number | null;
    lm?: number;
}

//@TODO: defined a detailed type, containing all model series

export type Relocation = {
    from: Warehouses;
    to: Warehouses;
    amount: number;
    pallets?: PalletData[];
    choosenPalletIds?: number[];
};

export type RelocationList = {
    relocations?: Relocation[],
    unsatisfied?: number,
    sum?: number,
    demand15d?: number,
    demand30d?: number,
};

export interface ArticleForecastWithStockData extends ArticleForecast {
    stocks: {
        nohra: StockData;
        heiligenstadt: StockData;
        leinefelde: StockData;
        hannover: StockData;
        zwischen: StockData;
        dates?: Date[],
        relocations?: {
            [K in Warehouses]?: RelocationList
        },
        relocationSum: number;
    };
}

export type Warehouses = 'nohra' | 'leinefelde' | 'heiligenstadt' | 'hannover' | 'zwischen';
export enum WarehouseShorts {
    'nohra' = 'NO',
    'leinefelde' = 'LFD',
    'heiligenstadt' = 'HIG',
    'hannover' = 'H',
    'zwischen' = 'Z'
}

export const WarehouseNumeric = {
    0: null,
    1: 'hannover',
    2: 'leinefelde',
};

export type WarehouseNumeric = keyof typeof WarehouseNumeric;

export const WarehouseNames: TextsForEnum<Warehouses> = {
    nohra: 'Lager 1',
    leinefelde: 'Lager 2',
    heiligenstadt: 'Lager 3',
    hannover: 'Lager 4',
    zwischen: 'Zwischenlager',
};

export type StockList = {
    [key in Warehouses]: StockData;
};

export interface ArticleForecastWithoutStockData extends ArticleForecast {
    stocks: {
        nohra: number;
        heiligenstadt: number;
        leinefelde: number;
        hannover: number;
        zwischen: number;
    };
}

export interface ForecastUpdate {
    key: keyof ArticleForecast;
    value: number;
}

export interface ForecastSeriesUpdate {
    key: keyof ForecastListeArticle;
    value: number;
}

//matches the csv headers used
export type AdditionalConsumptionInput = {
    Artikelnummer: string,
    Start: string,
    Ende: string,
    Menge: string
};

export type AdditionalConsumptionItem = {
    articleNumber: string,
    startDate: Date,
    endDate: Date,
    amount: number
};

export interface MonthlyOrderPreaggregation {
    orderItemNo: string;
    orderQuantity: number;
    orderDateMonth: Date;
}
