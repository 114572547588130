import { BiClient } from '../bi-widget';
import { ItemStatus, ReplenishmentSystem } from '../calculator/Product';
import { RelocationList, WarehouseNumeric, Warehouses } from '../forecast/Article';
import { FormatNo } from '../im-export';

export const FilterbleFieldsText: Readonly<PossibleNotificationFields> = [
    'articleNr',
    'orderNr',
] as const;
export const FilterbleFieldsObject: Readonly<PossibleNotificationFields> = [
    'relocation',
] as const;
export const FilterbleFieldsNumber: Readonly<PossibleNotificationFields> = [
    'updateCount',
    'lostOpportunity',
    'recommendedOrderAmount',
    'currentWHStock',
    'totalStock',
    'quantityOrdered',
    'quantityOutstanding',
    'priceOld',
    'priceNew',
    'timeFrameInDays',
    'price',
    'debitorPrice',
    'quantitySoldPast',
    'quantitySoldNow',
    'quantityDiff',
    'lBPrice',
    'priceDiff',
    'currentStock',
    'last12MonthConsumption',
    'score',
] as const;
export const FilterbleFieldsDate: Readonly<PossibleNotificationFields> = [
    'createdAt',
    'updatedAt',
    'totalOOS',
    'predictedReach',
    'wHOOS',
    'reportedDate',
    'crawledDate',
    'orderDate',
    'expectedArrivalDate',
    'dateOfSearchOld',
    'dateOfSearchNew',
    'dateOfSearch',
    'debitorDateOfSearch',
    'lBdateOfSearch',
] as const;
export const FilterbleFieldsSelect: Readonly<PossibleNotificationFields> = [
    // 'confPriority',
    // 'muted',
    'priority',
    'articleStatus',
    'articleReplenishmentSystem',
    'articleLocation',
    'articleLegacyKey',
    'articleProductKey',
    'articleMounting',
    'articleMaterial',
    'articleBrand',
    'articleColor',
    'articleWidth',
    'articleLength',
    'articleProducedIn',
    'articleParcelType',
    'warehouse',
    'debitor',
    'gotReported',
    'gotCrawled',
    'foundArticleOnWebsite',
    'kreditor',
    'rating',
    'format',
] as const;

export const FilterbleFields: Readonly<PossibleNotificationFields> = [...FilterbleFieldsText, ...FilterbleFieldsObject, ...FilterbleFieldsNumber, ...FilterbleFieldsDate, ...FilterbleFieldsSelect];

export const SortableFields: Readonly<PossibleNotificationFields> = [
    // 'confPriority',
    'type',
    'createdAt',
    'updatedAt',
    'updateCount',
    // 'muted',
    'priority',
    'totalOOS',
    'articleNr',
    'articleStatus',
    'articleReplenishmentSystem',
    'articleLocation',
    'articleLegacyKey',
    'articleProductKey',
    'articleMounting',
    'articleMaterial',
    'articleBrand',
    'articleColor',
    'articleWidth',
    'articleLength',
    'articleProducedIn',
    'articleParcelType',
    'lostOpportunity',
    'recommendedOrderAmount',
    'predictedReach',
    'warehouse',
    'wHOOS',
    'currentWHStock',
    'totalStock',
    'relocation',
    'debitor',
    'reportedDate',
    'gotReported',
    'crawledDate',
    'gotCrawled',
    'foundArticleOnWebsite',
    'orderNr',
    'kreditor',
    'quantityOrdered',
    'quantityOutstanding',
    'orderDate',
    'expectedArrivalDate',
    'format',
    'priceOld',
    'priceNew',
    'dateOfSearchOld',
    'dateOfSearchNew',
    'timeFrameInDays',
    'price',
    'debitorPrice',
    'priceDiff',
    'quantitySoldPast',
    'quantitySoldNow',
    'quantityDiff',
    'debitorDateOfSearch',
    'lBdateOfSearch',
    'lBPrice',
    'currentStock',
    'last12MonthConsumption',
    'score',
] as const;

export const GroupableFields: Readonly<PossibleNotificationFields> = [
    // 'confPriority',
    'type',
    'updateCount',
    // 'muted',
    'priority',
    'articleNr',
    'articleStatus',
    'articleReplenishmentSystem',
    'articleLocation',
    'articleLegacyKey',
    'articleProductKey',
    'articleMounting',
    'articleMaterial',
    'articleBrand',
    'articleColor',
    'articleWidth',
    'articleLength',
    'articleProducedIn',
    'articleParcelType',
    'warehouse',
    'relocation',
    'debitor',
    'gotReported',
    'crawledDate',
    'gotCrawled',
    'foundArticleOnWebsite',
    'orderNr',
    'kreditor',
    'format',
    'timeFrameInDays',
    'timeFrameInDays',
    'currentStock',
    'last12MonthConsumption',
    'score',
] as const;

export const ExportableFields: Readonly<PossibleNotificationFields> = [
    'type',
    'createdAt',
    'updatedAt',
    'updateCount',
    'priority',

    'articleNr',
    'articleStatus',
    'articleReplenishmentSystem',
    'articleLocation',
    'articleProductKey',
    'articleLegacyKey',
    'articleMounting',
    'articleMaterial',
    'articleBrand',
    'articleColor',
    'articleWidth',
    'articleLength',
    'articleParcelType',
    'articleProducedIn',

    'totalOOS',
    'lostOpportunity',
    'recommendedOrderAmount',
    'predictedReach',

    'warehouse',
    'wHOOS',
    'currentWHStock',
    'totalStock',

    'debitor',
    'reportedDate',
    'gotReported',
    'crawledDate',
    'gotCrawled',
    'foundArticleOnWebsite',

    'orderNr',
    'kreditor',
    'quantityOrdered',
    'quantityOutstanding',
    'orderDate',
    'expectedArrivalDate',

    'format',
    'rating',
    'ratingCountNew',
    'ratingDiff',
    'ratingCountOld',
    'timeFrameInDays',

    'priceOld',
    'dateOfSearchOld',
    'priceDiff',
    'priceNew',
    'dateOfSearchNew',
    'dateOfSearch',

    'price',
    'debitorPrice',

    'quantitySoldPast',
    'quantitySoldNow',
    'quantityDiff',

    'debitorDateOfSearch',
    'lBdateOfSearch',
    'lBPrice',
    'priceDiff',

    'currentStock',
    'last12MonthConsumption',
    'score',
] as const;


export const SpecificDataTotalOOSCheck: SpecificDataTotalOOS = {
    filter: { creditorNo: ['70001'], itemStatus: ['EIGENE', 'NORMAL', 'PRODUKTION'], and: [{}] },
    startDateDaysOffToday: 0,
    orderDateDaysOffToday: 0,
    maxPeriods: 18,
    monthsbeforOOSPriority1: 7,
    monthsbeforOOSPriority2: 3,
    monthsbeforOOSPriority3: 0,
};


export enum NotificationType {
    TotalOOS = 'TotalOOS',
    WHOOS = 'WHOOS',
    CRNF = 'CRNF', // CrawlerReportedNotFound
    IOI = 'IOI', // IncomingOrderInconsistency
    CR = 'CR', // CrawlerRating
    PC = 'PC', // PriceChanges
    PM = 'PM', // PriceMonitoring
    SP = 'SP', // SalesPerformance
    LBSPC = 'LBSPC', // Lichtblick Shop Price Compare
    FOA = 'FOA', // Forecast Overlooked Articles
}

export enum NotificationTypeLink {
    TotalOOS = 'forecast',
    WHOOS = 'distribution',
    CRNF = 'crawler',
    CRNF2 = 'reports',
    IOI = 'crawler',
    CR = 'crawler',
    PC = 'crawler',
    PM = 'crawler',
    SP = 'forecast',
    LBSPC = 'crawler',
    FOA = 'forecast',
}

export enum NotificationTypeText {
    TotalOOS = 'Gesamt-OOS',
    WHOOS = 'Lager-OOS',
    CRNF = 'Crawler-Status',
    IOI = 'Offene Bestellungen',
    CR = 'Neue Bewertungen',
    PC = 'Preisveränderung',
    PM = 'Preisabweichung gemeldet vs. gecrawled',
    SP = 'Abverkaufssprung',
    LBSPC = 'Preisabweichung Lichtblick',
    FOA = 'Übersehne Artikel', // TODO: TIM WORDING// TODO: TIM WORDING
    // TODO: TIM WORDING
    // und datenbank
    // TODO: TIM WORDING
}

//TODO/Notification: nochmal überdenken was für welche man haben will
export type Priority =
    1 | //high
    2 | //medium
    3 | //low
    4;  //good

export type DefaultNotificationConfig = {
    id?: number;
    version: number;
    name: string;
    type: NotificationType;
    confPriority: Priority;
    createdAt: Date;
    updatedAt: Date;
};

export type SpecificDataTotalOOS = {
    filter?: {},
    startDateDaysOffToday?: number,
    orderDateDaysOffToday?: number,
    maxPeriods?: number,
    monthsbeforOOSPriority1?: number,
    monthsbeforOOSPriority2?: number,
    monthsbeforOOSPriority3?: number,
};
export type SpecificDataWHOOS = {
    filter?: {},
    depth?: number
};
export type SpecificDataCRNF = {
    filter?: {},
};
export type SpecificDataIOI = {
    filter?: {},
};
export type SpecificDataCR = {
    filter?: {},
    rating: PossibleRatingStars[],
    amount: number,
    kindOfThreshold: ThresholdTypes,
    timeFrameInDays: number
};
export type SpecificDataPC = {
    filter?: {},
    amount: number,
    kindOfThreshold: ThresholdTypes,
    timeFrameInDays: number
    debitors: string[]
};
export type SpecificDataPM = {
    filter?: {},
    amount: number,
    kindOfThreshold: ThresholdTypes
};
export type SpecificDataLBSPC = {
    filter?: {},
};
export type SpecificDataFOA = {
    filter?: {},
    percentageOfStockToConsumptionLastYear: number
    startDateDaysOffToday?: number,
    orderDateDaysOffToday?: number,
    maxPeriods?: number,
    scorePriority1: number,
    scorePriority2: number,
    scorePriority3: number,
};

export type TimeInterval = 'week' | 'month' | 'quarter' | 'year';
export type SPTypes = 'debitor' | 'articleProductKey';
export type SpecificDataSP = {
    filter?: {},
    type: [SPTypes] | [SPTypes, SPTypes],
    amount: number,
    kindOfThreshold: ThresholdTypes
    timeIntervalAmount: number,
    timeInterval: TimeInterval
};
export type SpecifcData =
    SpecificDataTotalOOS |
    SpecificDataWHOOS |
    SpecificDataCRNF |
    SpecificDataIOI |
    SpecificDataCR |
    SpecificDataPC |
    SpecificDataPM |
    SpecificDataSP |
    SpecificDataLBSPC |
    SpecificDataFOA;

export type ThresholdTypes = 'percentage' | 'absolute';

export type TotalOOSNotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.TotalOOS,
    specifcData: SpecificDataTotalOOS
};
export type WHOOSNotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.WHOOS,
    specifcData: SpecificDataWHOOS
};
export type CRNFNotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.CRNF,
    specifcData: SpecificDataCRNF
};
export type IOINotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.IOI,
    specifcData: SpecificDataIOI
};
export type CRNotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.CR,
    specifcData: SpecificDataCR
};
export type PCNotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.PC,
    specifcData: SpecificDataPC
};
export type PMNotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.PM,
    specifcData: SpecificDataPM
};
export type SPNotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.SP,
    specifcData: SpecificDataSP
};
export type LBSPCNotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.LBSPC,
    specifcData: SpecificDataLBSPC
};
export type FOANotificationConfig = DefaultNotificationConfig & {
    type: NotificationType.FOA,
    specifcData: SpecificDataFOA
};

export type NotificationConfig =
    TotalOOSNotificationConfig |
    WHOOSNotificationConfig |
    CRNFNotificationConfig |
    IOINotificationConfig |
    CRNotificationConfig |
    PCNotificationConfig |
    PMNotificationConfig |
    SPNotificationConfig |
    LBSPCNotificationConfig |
    FOANotificationConfig;

export type NotificationConfigPick<T extends NotificationType | undefined = undefined> =
    T extends NotificationType.TotalOOS ? TotalOOSNotificationConfig :
    T extends NotificationType.WHOOS ? WHOOSNotificationConfig :
    T extends NotificationType.CRNF ? CRNFNotificationConfig :
    T extends NotificationType.IOI ? IOINotificationConfig :
    T extends NotificationType.CR ? CRNotificationConfig :
    T extends NotificationType.PC ? PCNotificationConfig :
    T extends NotificationType.PM ? PMNotificationConfig :
    T extends NotificationType.SP ? SPNotificationConfig :
    T extends NotificationType.LBSPC ? LBSPCNotificationConfig :
    T extends NotificationType.FOA ? FOANotificationConfig : NotificationConfig;

export type NotificationConfigMap = Map<number, NotificationConfig>;

export type NotificationEntityRequest = {
    entity: AllPossibleNotificationFilterFields,
    configIds: number[],
};
export type NotificationValueType = string | number | boolean | Date;
export type NotificationFilterType = NotificationValueType[] | NotificationValueType | { like: string } | { gte?: Date, lte?: Date };
export type NotificationFilter = Partial<Record<keyof PossibleNotificationFilterFields, NotificationFilterType>>;

export type WidgetRequestType = {
    client?: BiClient;
    configIds: number[];
    filter?: NotificationFilter,
    sortObjArray?: NotificationSort,
    group?: PossibleNotificationFields
    limit?: number,
    offset?: number
};

export interface DefaultNotification {
    confId: number;
    confVersion: number;
    confPriority: Priority;
    hash: string;
    id?: number;
    type: NotificationType;
    createdAt: Date;
    updatedAt: Date;
    updateCount: number;
    //TODO/Notification:
    // muted: boolean;
    // muted: boolean;
    // muted: boolean;
    muted: boolean;
    archived: boolean;
    archivedAt?: Date;
    priority: Priority;
}

export interface NotificationSpecific {
    confId: number;
    specificId?: number;
    notificationId?: number;
    hash: string;
}

export interface ArticleNrNotificationSpecific {
    articleNr: string;
    articleStatus: ItemStatus;
    articleReplenishmentSystem: ReplenishmentSystem | null;
    articleLocation: WarehouseNumeric;
    articleLegacyKey: string;
    articleProductKey: string;
    articleMounting: string;
    articleMaterial: string;
    articleBrand: string;
    articleColor: string;
    articleWidth: string;
    articleLength: string;
    articleProducedIn: string;
    articleParcelType: string;
}

export type TotalOOSNotificationSpecific = NotificationSpecific & {
    articleNr: string;
    totalOOS?: Date;
    lostOpportunity?: number;
    recommendedOrderAmount: number;
    predictedReach: Date;
};
export type NotificationTotalOOS = DefaultNotification & TotalOOSNotificationSpecific & ArticleNrNotificationSpecific & { type: NotificationType.TotalOOS; };

export type WHOOSNotificationSpecific = NotificationSpecific & {
    articleNr: string;
    warehouse: Warehouses;
    wHOOS?: Date;
    currentWHStock?: number;
    totalStock: number;
    relocation?: RelocationList;
};
export type NotificationWHOOS = DefaultNotification & WHOOSNotificationSpecific & ArticleNrNotificationSpecific & { type: NotificationType.WHOOS; };


export type CRNFNotificationSpecific = NotificationSpecific & {
    articleNr: string;
    debitor: string;
    reportedDate?: Date;
    gotReported: boolean;
    crawledDate?: Date;
    gotCrawled: boolean;
    foundArticleOnWebsite: boolean;
};
export type NotificationCRNF = DefaultNotification & CRNFNotificationSpecific & ArticleNrNotificationSpecific & { type: NotificationType.CRNF; };


export type IOINotificationSpecific = NotificationSpecific & {
    orderNr: string;
    articleNr: string;
    kreditor: string;
    quantityOrdered: number;
    quantityOutstanding: number;
    orderDate?: Date;
    expectedArrivalDate?: Date;
};
export type NotificationIOI = DefaultNotification & IOINotificationSpecific & ArticleNrNotificationSpecific & { type: NotificationType.IOI; };

export type PossibleRatingStars = 1 | 2 | 3 | 4 | 5;
export type CRNotificationSpecific = NotificationSpecific & {
    articleNr: string;
    debitor: string;
    format: FormatNo;
    rating: PossibleRatingStars;
    ratingCountNew: number;
    ratingDiff: number
    ratingCountOld: number;
    timeFrameInDays: number;
};
export type NotificationCR = DefaultNotification & CRNotificationSpecific & ArticleNrNotificationSpecific & { type: NotificationType.CR; };


export type PCNotificationSpecific = NotificationSpecific & {
    articleNr: string;
    debitor: string;
    format: FormatNo;
    priceOld: number;
    dateOfSearchOld: Date;
    priceDiff: number
    priceNew: number;
    dateOfSearchNew: Date;
    timeFrameInDays: number;
};
export type NotificationPC = DefaultNotification & PCNotificationSpecific & ArticleNrNotificationSpecific & { type: NotificationType.PC; };


export type PMNotificationSpecific = NotificationSpecific & {
    articleNr: string;
    debitor: string;
    format: FormatNo;
    dateOfSearch: Date;
    price: number;
    debitorPrice: number
    priceDiff: number;
};
export type NotificationPM = DefaultNotification & PMNotificationSpecific & ArticleNrNotificationSpecific & { type: NotificationType.PM; };


export type SPNotificationSpecific = NotificationSpecific & {
    debitor?: string;
    articleProductKey?: string;
    quantitySoldPast: number;
    quantitySoldNow: number;
    quantityDiff: number;
};
export type NotificationSP = DefaultNotification & SPNotificationSpecific & { type: NotificationType.SP; };


export type LBSPCNotificationSpecific = NotificationSpecific & {
    articleNr: string;
    debitor: string;
    format: FormatNo;
    debitorDateOfSearch: Date;
    debitorPrice: number;
    lBdateOfSearch?: Date;
    lBPrice: number
    priceDiff: number;
};
export type NotificationLBSPC = DefaultNotification & LBSPCNotificationSpecific & ArticleNrNotificationSpecific & { type: NotificationType.LBSPC; };



export type FOANotificationSpecific = NotificationSpecific & {
    articleNr: string;
    currentStock: number;
    last12MonthConsumption: number;
    score: number;
};
export type NotificationFOA = DefaultNotification & FOANotificationSpecific & ArticleNrNotificationSpecific & { type: NotificationType.FOA; };


export type NotificationCheckReturn = {
    notifications: DefaultNotification[]
    notificationsSpecific:
        TotalOOSNotificationSpecific[] |
        WHOOSNotificationSpecific[] |
        CRNFNotificationSpecific[] |
        IOINotificationSpecific[] |
        CRNotificationSpecific[] |
        PCNotificationSpecific[] |
        PMNotificationSpecific[] |
        SPNotificationSpecific[] |
        LBSPCNotificationSpecific[] |
        FOANotificationSpecific[]
};

export type Notifications =
    NotificationTotalOOS |
    NotificationWHOOS |
    NotificationCRNF |
    NotificationIOI |
    NotificationCR |
    NotificationPC |
    NotificationPM |
    NotificationSP |
    NotificationLBSPC |
    NotificationFOA;

export type NotificationApiRes = {
    isGrouped: false,
    notifications: Notifications[],
    totalCount: number
};

export type GroupedNotificationsApiRes = {
    isGrouped: true,
    notifications: GroupedNotifications[],
    totalCount: number,
    groupCount: number
};

export type NotificationApiResponses = NotificationApiRes | GroupedNotificationsApiRes;

export type BaseGroupedNotifications = {
    //TODO/Notification:
    // wiso funktioniert das Tim
    // wiso funktioniert das Tim
    // wiso funktioniert das Tim
    // wiso funktioniert das Tim
    // wiso funktioniert das Tim
    // wiso funktioniert das Tim
    // wiso funktioniert das Tim
    groupedField: typeof GroupableFields[number],
    groupedValue: string | number | boolean,
    totalCount: number,
    isFurtherGrouped: boolean,
};
export type GroupedNotificationsIsGrouped = BaseGroupedNotifications & {
    notifications: GroupedNotifications[],
    isFurtherGrouped: true,
};
export type GroupedNotificationsIsNotGrouped = BaseGroupedNotifications & {
    notifications: Notifications[],
    isFurtherGrouped: false,
};
export type GroupedNotifications = GroupedNotificationsIsNotGrouped | GroupedNotificationsIsGrouped;

export type PossibleNotificationFilterFields = Partial<
    DefaultNotification &
    ArticleNrNotificationSpecific &
    TotalOOSNotificationSpecific &
    WHOOSNotificationSpecific &
    CRNFNotificationSpecific &
    IOINotificationSpecific &
    CRNotificationSpecific &
    PCNotificationSpecific &
    PMNotificationSpecific &
    SPNotificationSpecific &
    LBSPCNotificationSpecific &
    FOANotificationSpecific>;
export type PossibleNotificationFields = AllPossibleNotificationFilterFields[];
export type SortDirection = 'ASC' | 'DESC';
export type NotificationSortEntity = { field: AllPossibleNotificationFilterFields, direction: SortDirection };
export type NotificationSort = NotificationSortEntity[];

export type PossibaleNotificationFieldsTypes = 'string' | 'number' | 'boolean' | 'date' | 'object'; // object is just difined or undifined
export const DefaultNotificationFieldsTypes: Record<keyof DefaultNotification, PossibaleNotificationFieldsTypes> = {
    // DefaultNotification
    confId: 'number',
    confVersion: 'number',
    confPriority: 'number',
    hash: 'string',
    id: 'number',
    type: 'string',
    createdAt: 'date',
    updatedAt: 'date',
    updateCount: 'number',
    muted: 'boolean',
    archived: 'boolean',
    archivedAt: 'date',
    priority: 'number',
};
export const NotificationSpecificFieldsTypes: Record<keyof (NotificationSpecific & DefaultNotification), PossibaleNotificationFieldsTypes> = {
    ...DefaultNotificationFieldsTypes,
    confId: 'number',
    specificId: 'number',
    notificationId: 'number',
    hash: 'string',
};
export const ArticleNrNotificationSpecificFieldsTypes: Record<keyof ArticleNrNotificationSpecific, PossibaleNotificationFieldsTypes> = {
    articleNr: 'string',
    articleStatus: 'string',
    articleReplenishmentSystem: 'string',
    articleLocation: 'number',
    articleLegacyKey: 'string',
    articleProductKey: 'string',
    articleMounting: 'string',
    articleMaterial: 'string',
    articleBrand: 'string',
    articleColor: 'string',
    articleWidth: 'string',
    articleLength: 'string',
    articleProducedIn: 'string',
    articleParcelType: 'string',
};
export const TotalOOSNotificationSpecificFieldsTypes: Record<keyof NotificationTotalOOS, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    ...ArticleNrNotificationSpecificFieldsTypes,
    totalOOS: 'date',
    lostOpportunity: 'number',
    recommendedOrderAmount: 'number',
    predictedReach: 'date',
};
export const WHOOSNotificationSpecificFieldsTypes: Record<keyof NotificationWHOOS, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    ...ArticleNrNotificationSpecificFieldsTypes,
    warehouse: 'string',
    wHOOS: 'date',
    currentWHStock: 'number',
    totalStock: 'number',
    relocation: 'object',
};
export const CRNFNotificationSpecificFieldsTypes: Record<keyof NotificationCRNF, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    ...ArticleNrNotificationSpecificFieldsTypes,
    debitor: 'string',
    reportedDate: 'date',
    gotReported: 'boolean',
    crawledDate: 'date',
    gotCrawled: 'boolean',
    foundArticleOnWebsite: 'boolean'
};
export const IOINotificationSpecificFieldsTypes: Record<keyof NotificationIOI, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    ...ArticleNrNotificationSpecificFieldsTypes,
    orderNr: 'string',
    kreditor: 'string',
    quantityOrdered: 'number',
    quantityOutstanding: 'number',
    orderDate: 'date',
    expectedArrivalDate: 'date',
};
export const CRNotificationSpecificFieldsTypes: Record<keyof NotificationCR, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    ...ArticleNrNotificationSpecificFieldsTypes,
    debitor: 'string',
    format: 'string',
    rating: 'number',
    ratingCountNew: 'number',
    ratingDiff: 'number',
    ratingCountOld: 'number',
    timeFrameInDays: 'number',
};
export const PCNotificationSpecificFieldsTypes: Record<keyof NotificationPC, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    ...ArticleNrNotificationSpecificFieldsTypes,
    articleNr: 'string',
    debitor: 'string',
    format: 'string',
    priceOld: 'number',
    dateOfSearchOld: 'date',
    priceDiff: 'number',
    priceNew: 'number',
    dateOfSearchNew: 'date',
    timeFrameInDays: 'number',
};
export const PMNotificationSpecificFieldsTypes: Record<keyof NotificationPM, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    ...ArticleNrNotificationSpecificFieldsTypes,
    articleNr: 'string',
    debitor: 'string',
    format: 'string',
    dateOfSearch: 'date',
    price: 'number',
    debitorPrice: 'number',
    priceDiff: 'number',
};
export const SPNotificationSpecificFieldsTypes: Record<keyof NotificationSP, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    debitor: 'string',
    articleProductKey: 'string',
    quantitySoldPast: 'number',
    quantitySoldNow: 'number',
    quantityDiff: 'number',
};
export const LBSPCNotificationSpecificFieldsTypes: Record<keyof NotificationLBSPC, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    ...ArticleNrNotificationSpecificFieldsTypes,
    articleNr: 'string',
    debitor: 'string',
    format: 'string',
    debitorDateOfSearch: 'date',
    debitorPrice: 'number',
    lBdateOfSearch: 'date',
    lBPrice: 'number',
    priceDiff: 'number',
};
export const FOANotificationSpecificFieldsTypes: Record<keyof NotificationFOA, PossibaleNotificationFieldsTypes> = {
    ...NotificationSpecificFieldsTypes,
    ...ArticleNrNotificationSpecificFieldsTypes,
    articleNr: 'string',
    currentStock: 'number',
    last12MonthConsumption: 'number',
    score: 'number',
};

export const FieldTypeObject: Record<NotificationType, Record<keyof Notifications, PossibaleNotificationFieldsTypes>> = {
    'TotalOOS': TotalOOSNotificationSpecificFieldsTypes,
    'WHOOS': WHOOSNotificationSpecificFieldsTypes,
    'CRNF': CRNFNotificationSpecificFieldsTypes,
    'IOI': IOINotificationSpecificFieldsTypes,
    'CR': CRNotificationSpecificFieldsTypes,
    'PC': PCNotificationSpecificFieldsTypes,
    'PM': PMNotificationSpecificFieldsTypes,
    'SP': SPNotificationSpecificFieldsTypes,
    'LBSPC': LBSPCNotificationSpecificFieldsTypes,
    'FOA': FOANotificationSpecificFieldsTypes,
};
//TODO/Notification:
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
// diesen Type kann ich noch besser machen
export type NotificationFieldsTypesType = Record<keyof PossibleNotificationFilterFields, PossibaleNotificationFieldsTypes>;
export const NotificationFieldsTypes: NotificationFieldsTypesType = {
    ...TotalOOSNotificationSpecificFieldsTypes,
    ...WHOOSNotificationSpecificFieldsTypes,
    ...CRNFNotificationSpecificFieldsTypes,
    ...IOINotificationSpecificFieldsTypes,
    ...CRNotificationSpecificFieldsTypes,
    ...PCNotificationSpecificFieldsTypes,
    ...PMNotificationSpecificFieldsTypes,
    ...SPNotificationSpecificFieldsTypes,
    ...LBSPCNotificationSpecificFieldsTypes,
    ...FOANotificationSpecificFieldsTypes,
};

export function getIntersectingProperties(fieldTypes: Record<string, PossibaleNotificationFieldsTypes>[]): Partial<NotificationFieldsTypesType> {
    let allKeys = fieldTypes.reduce((a, b) => { return [...a, ...Object.keys(b)]; }, []);
    let keyMap = new Map();
    for (let key of allKeys) {
        keyMap.set(key, (keyMap.get(key) ?? 0) + 1);
    }
    let finalProperties: Partial<NotificationFieldsTypesType> = {};
    Array.from(keyMap, ([key, value]) => {
        if (value === fieldTypes.length) {
            finalProperties[key] = NotificationFieldsTypes[key];
        }
    });
    return finalProperties;
}

export type AllPossibleNotificationFilterFields = keyof PossibleNotificationFilterFields;
//TODO/Notification:
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
// Bitte nochmal Überprüfen
export const NotificationFilterFieldTitle: Record<AllPossibleNotificationFilterFields, string> = {
    confId: 'Konfigurations-ID',
    confVersion: 'Konfigurationsversion',
    confPriority: 'Konfigurationspriorität',
    hash: 'Hash',
    id: 'Notification-ID',
    type: 'Notification-Typ',
    createdAt: 'Erstellungsdatum',
    updatedAt: 'Aktualisierungsdatum',
    updateCount: 'Anzahl Aktualisierungen',
    muted: 'Stumm',
    archived: 'Archiviert',
    archivedAt: 'Archivierungsdatum',
    priority: 'Priorität',
    specificId: 'Spezifische Notification-ID',
    notificationId: 'Notification-ID',
    totalOOS: 'Gesamt-OOS',
    articleNr: 'Artikelnummer',
    articleStatus: 'Status',
    articleReplenishmentSystem: 'Beschaffungsmethode',
    articleLocation: 'Lager',
    articleProductKey: 'Kürzel (Item Code)',
    articleLegacyKey: 'Kürzel (Artikelnummer)',
    articleMounting: 'Befestigungsart',
    articleMaterial: 'Material',
    articleBrand: 'Marke',
    articleColor: 'Farbe',
    articleWidth: 'Breite',
    articleLength: 'Länge',
    articleParcelType: 'Pakettyp',
    articleProducedIn: 'Produktionsland',
    lostOpportunity: 'Unerfüllbar',
    recommendedOrderAmount: 'Vorschlagene Bestellmenge',
    predictedReach: 'Neue voraussichtliche Reichweite',
    warehouse: 'Lager',
    wHOOS: 'Lager-OOS',
    currentWHStock: 'Lagerbestand',
    totalStock: 'Gesamtbestand',
    relocation: 'Umlagerungsempfehlung',
    debitor: 'Debitor',
    reportedDate: 'Meldedatum',
    gotReported: 'Wurde gemeldet',
    crawledDate: 'Crawling-Datum',
    gotCrawled: 'Wurde gecrawled',
    foundArticleOnWebsite: 'Artikel gefunden',
    orderNr: 'Bestellnummer',
    kreditor: 'Kreditor',
    quantityOrdered: 'Menge bestellt',
    quantityOutstanding: 'Menge ausstehend',
    orderDate: 'Bestelldatum',
    expectedArrivalDate: 'Erwartetes Ankunftsdatum',
    format: 'Format (Interface)',
    rating: 'Bewertung (Sterne)',
    ratingCountNew: 'Bewertungsanzahl neu',
    ratingDiff: 'Differenz Bewertungsanzahl',
    ratingCountOld: 'Bewertungsanzahl alt',
    timeFrameInDays: 'Zeitraum in Tagen',
    priceOld: 'Alter Preis',
    dateOfSearchOld: 'Crawling-Datum alter Preis',
    priceDiff: 'Preisdifferenz',
    priceNew: 'Neuer Preis',
    dateOfSearchNew: 'Crawling-Datum neuer Preis',
    dateOfSearch: 'Crawling-Datum',
    price: 'Preis auf Webseite',
    debitorPrice: 'Debitor-Preis (Brutto)',
    quantitySoldPast: 'Abverkauf Vergleichszeitraum',
    quantitySoldNow: 'Abverkauf aktuell',
    quantityDiff: 'Differenz',
    debitorDateOfSearch: 'Debitor Crawling-Datum',
    lBdateOfSearch: 'Lichtblick Crawling-Datum',
    lBPrice: 'Lichtblick-Preis (Brutto)',
    // TODO: TIM WORDING
    currentStock: 'Lagerbestand',
    // TODO: TIM WORDING
    last12MonthConsumption: 'Vorjahresverkauf (letzen 12 Monate)',
    // TODO: TIM WORDING
    score: 'Lagerbestand/Vorjahresverkauf',
};
