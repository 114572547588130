<div class="grid grid__details grid__user">
    <form *ngIf="form" [formGroup]="form" class="details__config" autocomplete="off" [ngClass]="{ 'closed': !showConfig }">
        <div class="details__config__body">
            <div class="form-row">
                <div class="col">
                    <h2 *ngIf="!newUserMode">User {{user.username}} bearbeiten</h2>
                    <h2 *ngIf="newUserMode">User anlegen</h2>
                </div>
            </div>
            <div class="form-row">
                <div class="col t__right f__xs">
                    * Pflichtfeld
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <label>Username *</label>
                    <input [formControl]="form.controls.username" required type="text" class="form-control">
                    <i class="las la-question-circle" placement="left-bottom" triggers="hover focus click:blur" role="button" ngbTooltip="Wird zum Login genutzt"></i>
                    <div class="invalid-feedback" controlName="username" appValidationErrors></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <label>Firstname *</label>
                    <input [formControl]="form.controls.firstname" required type="text" class="form-control">
                    <div class="invalid-feedback" controlName="firstname" appValidationErrors></div>
                </div>
                <div class="col">
                    <label>Lastname *</label>
                    <input [formControl]="form.controls.lastname" required type="text" class="form-control">
                    <div class="invalid-feedback" controlName="lastname" appValidationErrors></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <label>Mailadresse *</label>
                    <input [formControl]="form.controls.mailaddress" required type="text" class="form-control">
                    <div class="invalid-feedback" controlName="mailaddress" appValidationErrors></div>
                </div>
            </div>
            <div *ngIf="!newUserMode && adminMode" class="form-row">
                <div class="col">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" [checked]="this.calculatorOn" class="custom-control-input" id="customSwitchCalc" (click)="toggleCalculator()">
                        <label class="custom-control-label" for="customSwitchCalc">Kalkulatornutzer</label>
                      </div>
                </div>
            </div>
            <div *ngIf="!newUserMode && adminMode" class="form-row">
                <div class="col">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" [checked]="this.forecastOn" class="custom-control-input" id="customSwitchForecast" (click)="toggleForecast()">
                        <label class="custom-control-label" for="customSwitchForecast">Forecastnutzer</label>
                      </div>
                </div>
            </div>
            <div *ngIf="!newUserMode && adminMode" class="form-row">
                <div class="col">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" [checked]="this.stocksOn" class="custom-control-input" id="customSwitchStocks" (click)="toggleStocks()">
                        <label class="custom-control-label" for="customSwitchStocks">Lagerbestandsnutzer</label>
                      </div>
                </div>
            </div>
            <div *ngIf="!newUserMode && adminMode" class="form-row">
                <div class="col">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" [checked]="this.distOn" class="custom-control-input" id="customSwitchDist" (click)="toggleDist()">
                        <label class="custom-control-label" for="customSwitchDist">Umlagerungsnutzer</label>
                      </div>
                </div>
            </div>
            <div *ngIf="!newUserMode && adminMode" class="form-row">
                <div class="col">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" [checked]="this.reportsOn" class="custom-control-input" id="customSwitchReports" (click)="toggleReports()">
                        <label class="custom-control-label" for="customSwitchReports">Bestandsmeldungsnutzer</label>
                      </div>
                </div>
            </div>
            <div *ngIf="!newUserMode" class="form-row">
                <div class="col">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" [checked]="changePassword" class="custom-control-input" id="customSwitchPassword" (click)="togglePassword()">
                        <label class="custom-control-label" for="customSwitchPassword">Passwort ändern</label>
                      </div>
                </div>
            </div>
            <div *ngIf="changePassword || newUserMode" class="form-row">
                <div class="col">
                    <label>Passwort *</label>
                    <input [formControl]="form.controls.newPassword1" required type="password" autocomplete="new-password" class="form-control">
                    <div class="invalid-feedback" controlName="newPassword1" appValidationErrors></div>
                </div>
                <div class="col">
                    <label>Passwort wiederholen*</label>
                    <input [formControl]="form.controls.newPassword2" required type="password" autocomplete="new-password" class="form-control">
                    <div class="invalid-feedback" controlName="newPassword2" appValidationErrors></div>
                </div>
            </div>
            <div *ngIf="!userLoading; else loading">
                <div *ngFor="let message of messages" class="form-row">{{message}}</div>
            </div>
        </div>
        <div class="details__config__actions">
            <div class="d-flex" *ngIf="form.valid">
                <button [disabled]="userLoading" (click)="save()"><i class="las la-save"></i>Speichern</button>
            </div>
            <div class="d-flex" *ngIf="!form.valid" triggers="hover focus click:blur" role="button" [ngbTooltip]="getFormErrorTooltip()">
                <button [disabled]="true"><i class="las la-save"></i>Speichern</button>
            </div>
        </div>
    </form>
    <div class="details__table" *ngIf="adminMode && userList" [ngClass]="{ 'full-height': true, 'full-width': !showConfig }">
        <div class="table user-table">
            <div class="table__header" style="position: relative;">
                <button [routerLink]="['/user', 'new']" class="bg-dark csv" style="height: 100%;"><i
                    class="las la-plus-square"></i>Neuer Nutzer</button>
                <div class="table">
                    <div class="tr header cond bg-secondary text-white">
                        <div class="td text-white" style="width: 15%;">
                            Username
                        </div>
                        <div class="td text-white" style="width: 15%;">
                            Firstname
                        </div>
                        <div class="td text-white" style="width: 15%;">
                            Lastname
                        </div>
                        <div class="td text-white" style="width: 20%;">
                            Mailadresse
                        </div>
                        <div class="td text-white" style="width: 15%;">
                            Aktionen
                        </div>
                    </div>
                </div>
            </div>

            <div class="table__content">
                <div class="table">
                    <div *ngFor="let row of userList" class="tr reg__row">
                        <div class="td" style="width: 15%;">{{row.username}}</div>
                        <div class="td" style="width: 15%;">{{row.firstname}}</div>
                        <div class="td" style="width: 15%;">{{row.lastname}}</div>
                        <div class="td" style="width: 20%;">{{row.mailaddress}}</div>
                        <div class="td button-list" style="width: 15%;">
                            <a [routerLink]="['/user', row.id]" (click)="forceEditOpen()"><i class="las la-pen" triggers="hover focus click:blur" role="button" ngbTooltip="Bearbeiten"></i></a>
                            <a *ngIf="row.active" (click)="deactivate(row.id)" [ngClass]="{ disabled: row.id === 1 }"><i class="las la-toggle-on" triggers="hover focus click:blur" role="button" ngbTooltip="Deaktivieren"></i></a>
                            <a *ngIf="!row.active" (click)="activate(row.id)" [ngClass]="{ disabled: row.id === 1 }"><i class="las la-toggle-off" triggers="hover focus click:blur" role="button" ngbTooltip="Aktivieren"></i></a>
                            <a (click)="delete(row.id, row.username)" [ngClass]="{ disabled: row.id === 1 }"><i class="las la-trash" triggers="hover focus click:blur" role="button" ngbTooltip="Löschen"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="toggle-config" [ngClass]="{ 'closed': !showConfig }" (click)="showConfig = !showConfig"><i class="las" [ngClass]="showConfig ? 'la-caret-square-left': 'la-caret-square-right'" placement="top-left" role="button" ngbTooltip="Konfiguration {{ showConfig ? 'ausblenden' : 'einblenden' }}"></i></div>

        </div>
    </div>
</div>

<ng-template #loading>
    <loader></loader>
</ng-template>
